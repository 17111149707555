import { PickListItem } from "components/picklist/picklist";
import { getYear } from "date-fns";
import format from "date-fns/esm/fp/format/index.js";
import { formatToCPFOrCNPJ } from "brazilian-values";
import { useRef } from "react";
import { ChangeEvent, useEffect, useState, KeyboardEvent } from "react";
import ErrorPage from "@pages/error/errorPage";
import DatePicker from "react-date-picker";
import { toast } from "react-toastify";
import styled from "styled-components";
import PageFrame from "components/page-frame/pageFrame";
import PermissionControl from "@components/permission/PermissionControl";
import { PERMISSIONS } from "utils/permissions";
import { findByFilter, inutilize, InutilizarNumeracaoRequest, InutilizarNumeracaoResponse, exportNFCes } from '@services/NFCeInutilizacaoService';
import { NFCeInutilizacaoFilter } from "@models/nfce/NFCeInutilizacaoFilter";
import Table, { Column, OrderInfo } from "@components/table/table";
import Branches from "@components/branches/branches";
import { NFCeInutilizacao } from "@models/nfce/NFCeInutilizacao";
import { useSelectedRootCompany } from "@contexts/app/context";
import { CircularProgress } from "@mui/material";
import fileDownload from "js-file-download";
import { NFCe } from "@models/nfce/NFCe";
import NFCeInutilizacaoApi from "api/NFCeInutilizacaoApi";

const StyledFrame = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    .customize-columns-toggle {
        height: 32px;
        width: fit-content;
        align-self: flex-end;
        
        padding: 0 8px;

        margin-left: auto;
        
        border: 1px solid var(--grey-light);
        border-radius: 8px;
        
        display: flex; 
        align-items: center; 
        justify-content: center;
        
        user-select: none;
        font-family: 'TitilliumWeb';
        
        cursor: pointer;

        background-color: var(--white);

        :active {
            background-color: var(--grey-lightest);
        }

        :focus-visible {
            outline: none;
        }

        > span {
            text-transform: uppercase;
            font-size: .7rem;
            font-weight: bold;
            letter-spacing: .3px;
        }
    }

    .background {
        background-color: var(--white);
        border-radius: 12px;
        border: 1px solid var(--grey-light);
    }

    .header {
        height: 50px;
        line-height: 50px;
        font-size: 1.1rem;
        padding: 4px 16px;
    }
    
    .top-frame {
        height: 210px;
        width: 100%;
		
		transition: height 0.3s;

        margin-right: 22px;
        margin-top: 8px;

        color: var(--grey-medium);
		
		span {
			cursor: pointer;
		}

        i {
            margin-right: 12px;
            font-size: 1.3rem;
            line-height: 1.1rem;
        }

        .content {
            height: fit-content;
            max-width: 100%;
            padding: 10px 22px 16px 22px;
            
            display: flex;
            
            .action-button {
                margin-top: auto;
                margin-left: auto;
            }
        }
    }
	
	.top-frame.visible {
		height: 50px;
		transition: height 0.3s;
		
		i {
			display: inline-block;
			transform: rotate(90deg);
			transition: 0.3s;
		}
	}
	
	.top-frame.collapsed {
		i {
			display: inline-block;
			transform: rotate(45deg);
			transition: 0.3s;
		}
	}
	
	.bottom-frame.visible {
		max-height: 495px;
		transition: 0.3s;
	}
	
	.bottom-frame.collapsed {
		transition: 0.3s;
	}
    
    .bottom-frame {
        height: 70%;
        max-height: 340px;
        width: 100%;
        margin-right: 8px;
        margin-top: 18px;

        color: var(--grey-medium);

        > span {
            i {
                margin-left: 4px;
                margin-right: 16px;
                font-size: 1rem;
            }
        }

        .content {
            height: calc(100% - 60px);
            max-width: 100%;
            padding: 0px 22px 16px 22px;
            
            display: flex;
            flex-direction: column;

            color: var(--grey-regular);

            .list-header {
                height: fit-content;
                width: 100%;

                display: flex;

                margin-top: 18px;
                border-bottom: 1px solid var(--grey-regular);

                span {
                    width: 33%;
                    font-weight: bold;
                    font-size: .8rem;
                    color: var(--grey-medium);

                    &:not(:first-of-type) {
                        margin-left: 12px;
                    }
                }
            }

            .list-content {
                height: 100%;
                width: 100%;
                max-width: 100%;

                overflow-y: auto;

                display: flex;
                flex-direction: column;

                > div {
                    height: fit-content;
                    width: 100%;
                    display: flex;

                    padding: 2px 0;

                    > span {
                        width: 33%;
                        height: 30px;
                        line-height: 30px;
                        color: var(--grey-medium);

                        &:not(:first-of-type) {
                            margin-left: 12px;
                        }
                    }
                }

                .empty-list {
                    width: 100%;
                    height: 100%;

                    display: grid;
                    place-items: center;
                }
            }
        }
    }

    .item-label {
        color: var(--grey-regular);
        margin-bottom: 2px;
    }
`;

interface PageAndSort {
    sort: OrderInfo;
    page: number;
}

const DisableNumbersPage = () => {
    const columns: Column[] = [
        { header: "CNPJ", accessor: "company.cnpj", isActive: true, minWidth: 130, customView: (nfce: NFCeInutilizacao) => (<>{formatToCPFOrCNPJ(nfce.company.cnpj)}</>) },
        { header: 'Numeração Inicial', accessor: 'initialNum', isActive: true, minWidth: 85 },
        { header: 'Numeração Final', accessor: 'finalNum', isActive: true, minWidth: 80 },
        { header: 'Série', accessor: 'series', isActive: true, minWidth: 25 },
        { header: 'Ano', accessor: 'year', isActive: true, minWidth: 35 },
        { header: 'Data de Inutilização', accessor: 'skipDate', isActive: true, minWidth: 70 },
        { header: 'Protocolo', accessor: 'protocol', isActive: true, minWidth: 35 },
		{ header: 'Usuário', accessor: 'username', isActive: true, minWidth: 50 }
    ];

    const [selectedNFCes, setSelectedNFCes] = useState<NFCe[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const [isInutilizing, setIsInutilizing] = useState<boolean>(false);
    const [data, setData] = useState<NFCeInutilizacao[]>([]);
    const [pageAndSort, setPageAndSort] = useState<PageAndSort>({ sort: { columnOrdered: columns[5], direction: "DESC" }, page: 0 })
    const [selectedBranchId, setSelectedBranchId] = useState<number | null>(null);

    const serieRef = useRef<HTMLInputElement>(null);
    const initialNumberRef = useRef<HTMLInputElement>(null);
    const finalNumberRef = useRef<HTMLInputElement>(null);
    const reasonRef = useRef<HTMLInputElement>(null);
    const [exportingBils, setExportingBills] = useState<boolean>(false);
	
	const [collapsed, setIsCollapsed] = useState<boolean>(false);

    const [searchFilters, setSearchFilters] = useState<NFCeInutilizacaoFilter>(new NFCeInutilizacaoFilter());

    const [newSerie, setNewSerie] = useState<{
        year: string,
        serie: string,
        initialDate: string,
        finalDate: string,
        explanation: string,
    }>();

    const selectedRootCompany = useSelectedRootCompany();

    const onSelectedCompany = ([first]: PickListItem[]) => {
        setSelectedBranchId(first?.id || null);
    }

    const handleExportDisabledNumbersNFCes = () => {
        setExportingBills(true);
        const { finalDateObj, initialDateObj, series, initialNum, finalNum, year } = searchFilters;
        const filter: NFCeInutilizacaoFilter = {
            initialDate: initialDateObj ? format("dd/MM/yyyy", initialDateObj) : null,
            finalDate: finalDateObj ? format("dd/MM/yyyy", finalDateObj) : null,
            series,
            initialNum,
            finalNum,
            year,
            companyId: !!selectedRootCompany ? selectedRootCompany.id : null,
            branchId: !!selectedBranchId ? selectedBranchId : null
        };

        exportNFCes(filter)
            .then(() => toast.success('NFCe\'s exportadas com sucesso!'))
            .catch(error => toast.error('NFCe\'s exportadas com falha!'))
            .finally(() => setExportingBills(false));
    }

    const executeSearch = ({ concatResults }: { concatResults: boolean }) => {
        const { finalDateObj, initialDateObj, series, initialNum, finalNum, year } = searchFilters;
        const { sort: sortInfo, page: pageIndex } = pageAndSort;

        const filter: NFCeInutilizacaoFilter = {
            initialDate: initialDateObj ? format("dd/MM/yyyy", initialDateObj) : null,
            finalDate: finalDateObj ? format("dd/MM/yyyy", finalDateObj) : null,
            series,
            initialNum,
            finalNum,
            year,
            companyId: !!selectedRootCompany ? selectedRootCompany.id : null,
            branchId: !!selectedBranchId ? selectedBranchId : null
        };

        findByFilter(filter, sortInfo, concatResults ? pageIndex : 0).then(({ content, last }) => {
            setHasMore(!last);
            setData(concatResults ? [...data, ...content] : content);
        }).catch(error => {
            console.error(error);
            toast.error('Ocorreu um erro ao filtrar as numerações inutilizadas.');
        });
    }

    useEffect(() => executeSearch({ concatResults: pageAndSort.page !== 0 }), [pageAndSort]);

    useEffect(() =>
        setSelectedBranchId(null)
        , [selectedRootCompany]);

    useEffect(() => executeSearch({ concatResults: false }), [selectedRootCompany, selectedBranchId]);

    useEffect(() => {
        selectedNFCes.length && setData(data.map(item => {
            return {
                ...item,
                isSelected: (selectedNFCes.find(i => (i as any).id === item.id) !== undefined)
            }
        }));
    }, [selectedNFCes]);

    const handlePressEnterFilter = ({ code }: KeyboardEvent<HTMLInputElement>) => {
        if (code === "Enter" || code === "NumpadEnter") {
            executeSearch({ concatResults: false });
        }
    }

    const handleSortByColumn = (col: Column) => {
        const { sort: sortInfo } = pageAndSort;

        setPageAndSort({
            page: 0,
            sort: {
                columnOrdered: col,
                direction: (col.accessor !== sortInfo.columnOrdered.accessor) || sortInfo.direction === "DESC" ? "ASC" : "DESC"
            }
        });
    }

    const resetFields = () => {
        if (serieRef.current?.value) {
            serieRef.current.value = ''
        }
        if (initialNumberRef.current?.value) {
            initialNumberRef.current.value = ''
        }
        if (finalNumberRef.current?.value) {
            finalNumberRef.current.value = '';
        }
        if (reasonRef.current?.value) {
            reasonRef.current.value = '';
        }

    }

    const handleDisableNumbers = () => {
        if (!selectedBranchId) {
            toast.warning("Selecione um estabelecimento para inutilizar uma numeração.");
            return false;
        }

        if (!serieRef.current?.value) {
            toast.warning("Digite a série da numeração.");
            serieRef.current?.focus();
            return false;
        }

        if (!initialNumberRef.current?.value) {
            toast.warning("Digite a numeração inicial.");
            initialNumberRef.current?.focus();
            return false;
        }

        if (finalNumberRef.current?.value && (parseInt(finalNumberRef.current!.value) < parseInt(initialNumberRef.current!.value))) {
            toast.warning("Numeração final não pode ser menor do que a inicial.");
            finalNumberRef.current?.focus();
            return false;
        }

        if (!reasonRef.current?.value || reasonRef.current.value.length > 255 || reasonRef.current.value.length < 15) {
            toast.warning("A justificativa deve conter entre 15 e 255 caracteres.");
            reasonRef.current?.focus();
            return false;
        }

        setIsInutilizing(true);

        const req: InutilizarNumeracaoRequest = {
            companyId: selectedBranchId,
            initialNum: parseInt(initialNumberRef.current.value),
            finalNum: finalNumberRef.current?.value ? parseInt(finalNumberRef.current!.value) : parseInt(initialNumberRef.current.value),
            series: parseInt(serieRef.current.value),
            justification: reasonRef.current.value,
            year: new Date().getFullYear()
        }

        inutilize(req).then(({ status, message }: InutilizarNumeracaoResponse) => {
            if (status === "error") {
                toast.error(message);
                return;
            }
            toast.success(message);
            resetFields();
            executeSearch({ concatResults: false })
        }).finally(() => setIsInutilizing(false));
    }
	
	if (selectedRootCompany == null) {
        return <PageFrame><StyledFrame><ErrorPage message="" subMessage={"Antes de acessar essa página, selecione a empresa no menu superior"} hideBackButton /></StyledFrame></PageFrame>
    }

    const inutilizacaoApi = new NFCeInutilizacaoApi();

    const handleDownloadXmlBatch = (ids: number[]) => {
        if (!ids || !ids.length) {
            toast.warning('É necessário selecionar uma nota para realizar o download do XML.');
            return;
        }

        inutilizacaoApi.downloadXmlBatch(ids).then(res => {
            if (!res || !res.data || res.data.size === 0 || res.status !== 200) {
                toast.info("Não foi encontrado nenhum XML válido para as notas selecionadas.");
            } else {
                fileDownload(res.data, res.headers.filename)
            }
        });
    }

    return <PageFrame><StyledFrame>
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '6px', marginBottom: '6px' }}>
            <Branches showLabel onSelect={onSelectedCompany} showEndMessage={false} allowDeselection={true} />
        </div>
		
		{ selectedBranchId != null &&
			<div>		
				{/* NOVA INUTILIZAÇÃO */}			
				<PermissionControl requiredPermissions={[PERMISSIONS.DOOTAX.NFCE.INUTILIZAR_NUMERACAO.INUTILIZAR]}>
					<aside className={['top-frame', 'background', collapsed ? 'collapsed' : 'visible'].join(' ')}>
						<span onClick={() => setIsCollapsed(!collapsed)} className="header"><i className="bi bi-plus" />Inutilizar nova numeração</span>

						{ !!collapsed && 
						<div className="content">

							<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

								<div style={{ display: 'flex' }}>

									<div style={{ display: 'flex', width: '100%' }}>
										{/* ANO DE EMISSAO */}
										<div style={{ display: 'flex', flexDirection: 'column', marginRight: '22px' }}>
											<span className="input-label" style={{ whiteSpace: 'nowrap' }}>Ano de emissão</span>
											<input type="number" disabled={true} value={getYear(new Date())} style={{ maxWidth: '80px' }} />
										</div>

										{/* SERIE */}
										<div style={{ display: 'flex', flexDirection: 'column', width: 'fit-content' }}>
											<span className="input-label">Série</span>
											<input ref={serieRef} type="number" maxLength={3} value={newSerie?.serie} style={{ maxWidth: '50px' }}
												onChange={(event) => newSerie && setNewSerie({ ...newSerie, serie: event.target.value })} />
										</div>

										{/* NUMERACAO INICIAL */}
										<div style={{ display: 'flex', marginLeft: '22px', flexDirection: 'column', width: '100%' }}>
											<span className="input-label">Numeração inicial</span>
											<input ref={initialNumberRef} type="number" maxLength={16} />
										</div>

										{/* NUMERACAO FINAL */}
										<div style={{ display: 'flex', marginLeft: '22px', flexDirection: 'column', width: '100%' }}>
											<span className="input-label">Numeração final</span>
											<input ref={finalNumberRef} type="number" maxLength={16} />
										</div>
									</div>
								</div>

								{/* JUSTIFICATIVA */}
								<div style={{ display: 'flex', width: '100%' }}>
									<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: '16px', width: '100%' }}>
										<span className="input-label">Justificativa</span>
										<input ref={reasonRef} type="text" minLength={120} style={{ height: '30px', marginRight: '22px' }} />
									</div>
									<button
										className="icon-button action-button"
										style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
										onClick={() => handleDisableNumbers()}
										disabled={isInutilizing}
									>
										{isInutilizing ? (<CircularProgress size={14} style={{ 'color': '#FFFFFF' }} />) : "INUTILIZAR"}
									</button>
								</div>
							</div>

						</div>
						}
					</aside>
				</PermissionControl>

				{/* NUMERAÇÃO INUTILIZADA */}
				<PermissionControl requiredPermissions={[PERMISSIONS.DOOTAX.NFCE.INUTILIZAR_NUMERACAO.VISUALIZAR]}>
					<aside className={['bottom-frame', 'background', collapsed ? 'collapsed' : 'visible'].join(' ')}>
						<span className="header"><i className="bi bi-slash-circle" />Numeração inutilizada</span>

						<div className="content">

							{/* FILTERS */}
							<div style={{ display: 'flex', maxWidth: '100%' }}>
								{/* SERIE */}
								<div style={{ display: 'flex', flexDirection: 'column', width: 'fit-content', marginRight: '22px' }}>
									<span className="input-label">Série</span>
									<input type="text" maxLength={3}
										style={{ maxWidth: '50px' }}
										value={searchFilters.series}
										onKeyDown={handlePressEnterFilter}
										onChange={({ target: { value } }) => setSearchFilters({ ...searchFilters, series: !!value ? value : '' })} />
								</div>

								<div style={{ display: 'flex', flexDirection: 'column', width: 'fit-content', marginRight: '22px' }}>
									<span className="input-label">Ano</span>
									<input type="text" maxLength={4}
										style={{ maxWidth: '50px' }}
										value={searchFilters.year}
										onKeyDown={handlePressEnterFilter}
										onChange={({ target: { value } }) => setSearchFilters({ ...searchFilters, year: !!value ? value : '' })} />
								</div>

								{/* NUMERACAO INICIAL */}
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<span className="input-label">Numeração inicial</span>
									<input type="text"
										maxLength={9}
										value={searchFilters.initialNum}
										onKeyDown={handlePressEnterFilter}
										onChange={({ target: { value } }) => setSearchFilters({ ...searchFilters, initialNum: !!value ? value : '' })} />
								</div>

								{/* NUMERACAO FINAL */}
								<div style={{ display: 'flex', marginLeft: '22px', flexDirection: 'column' }}>
									<span className="input-label">Numeração final</span>
									<input type="text" maxLength={9}
										value={searchFilters.finalNum}
										onKeyDown={handlePressEnterFilter}
										onChange={({ target: { value } }) => setSearchFilters({ ...searchFilters, finalNum: !!value ? value : '' })} />
								</div>

								<div style={{ display: 'flex', flexDirection: 'column', width: 'fit-content', marginLeft: '22px' }}>
									{/* DATA DE EMISSAO */}
									<span>Data de inutilização</span>
									<div style={{ display: 'flex', height: 'fit-content' }}>
										<DatePicker
											value={searchFilters?.initialDateObj}
											locale="pt-BR"
											dayPlaceholder="dd"
											monthPlaceholder="mm"
											yearPlaceholder="aaaa"
											calendarIcon={<i className="bi bi-calendar"></i>}
                                            clearIcon={<i className="bi bi-trash"></i>}
											onChange={(value: Date, event: ChangeEvent) => setSearchFilters({ ...searchFilters, initialDateObj: value })}
										/>

										<small style={{ margin: 'auto 12px' }}>até</small>

										<DatePicker
											value={searchFilters?.finalDateObj}
											locale="pt-BR"
											dayPlaceholder="dd"
											monthPlaceholder="mm"
											yearPlaceholder="aaaa"
											calendarIcon={<i className="bi bi-calendar"></i>}
                                            clearIcon={<i className="bi bi-trash"></i>}
											onChange={(value: Date, event: ChangeEvent) => setSearchFilters({ ...searchFilters, finalDateObj: value })}
										/>
									</div>
								</div>
                                <div style={{ display: 'flex', marginLeft: '22px' }}>
                                    <button
                                        className="customize-columns-toggle"
                                        onClick={() => executeSearch({ concatResults: false })}>
                                        <span>Filtrar</span>
                                        <i className="bi bi-search" style={{ fontSize: '.75rem', marginLeft: '8px' }} />
                                    </button>
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button disabled={exportingBils} className="icon-button action-button" style={{ marginLeft: '20px', width: '120px' }} onClick={() => handleExportDisabledNumbersNFCes()}>
                                        {exportingBils ? (
                                            <CircularProgress size={14} style={{ 'color': '#FFFFFF' }} />
                                        ) : (
                                            <>
                                                <i className="bi bi-file-earmark-spreadsheet"></i>
                                                <span>Exportar</span>
                                            </>
                                        )}
                                </button>
                            </div>

							<Table data={data}
								columns={columns}
								orderInfo={pageAndSort.sort}
								onSortByColumn={(col: Column) => handleSortByColumn(col)}
                                disableSelection={false}
                                onSelectionChange={(nfes: any) => { setSelectedNFCes(nfes.filter((nf: any) => nf.isSelected)) }}
								handleFetchMore={() => setPageAndSort({ ...pageAndSort, page: (pageAndSort.page + 1) })}
								hasMore={hasMore}
								batchActions={[
                                    {
                                        label: 'Baixar XML',
                                        actionCallback: handleDownloadXmlBatch,
                                        isDisabled: selectedNFCes.length === 0
                                    }
                                ]}
							/>
						</div>
					</aside>
				</PermissionControl>
			</div>
		}
    </StyledFrame></PageFrame>
}

export default DisableNumbersPage;