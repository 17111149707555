export interface SATFilterStatus {
    label: string,
    value: string | null,
    refColor: string,
    code: string | null,
    active: boolean,
    visible: boolean,
    id: number
}

export const satFilterStatus: SATFilterStatus[] = [
    {
        label: 'Todos',
        value: null,
        refColor: '#000',
        code: null,
        active: true,
        visible: true,
        id: 1
    },
    {
        label: 'Em Processamento', 
        value: 'AGUARDANDO_ENVIO', 
        refColor: '#3149d1',
        code: 'AGUARDANDO_ENVIO',
        active: true,
        visible: true,
        id: 2
    },
    {
        label: 'Falha na emissão',
        value: 'ERRO_ENVIO',
        refColor: '#f00d0d',
        code: 'ERRO_ENVIO',
        active: true,
        visible: true,
        id: 3
    },
    {
        label: 'Emitido', 
        value: 'AUTORIZADO', 
        refColor: '#41c718',
        code: 'AUTORIZADO',
        active: true,
        visible: true,
        id: 4
    },
	{
        label: 'Cancelado', 
        value: 'CANCELADO', 
        refColor: '#f78d1b',
        code: 'CANCELADO',
        active: true,
        visible: true,
        id: 5
    }
];