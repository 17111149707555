import fileDownload from 'js-file-download';
import api from '../api/Api';

export interface TotalizerDashboardFilter {
  companyId: number | null;
  branchId: number | null;
  initialDate: string;
  finalDate: string;
  status: string;
  periodicity?: "MONTHLY" | "DAILY"; 
}

export interface TotalizerResponse {
  all: TotalizerItemResponse;
  authorized: TotalizerItemResponse;
  byStatus: TotalizerItemResponse;
}

export interface TotalizerItemResponse {
  totalCount: number;
  totalAmount: number;
}

export interface ExportGraph {
  title: string;
  base64SVGURI: string;
}

export interface GraphData {
  x: string;
  y: number;
}

export const findTotalByFilter = async (filter: TotalizerDashboardFilter): Promise<TotalizerResponse> => {
  try {
    return api.get<TotalizerResponse>('/nfe/dashboard/total', { params: filter }).then(({ data }) => data);
  } catch (err) {
    throw new Error("Ocorreu um erro ao tentar buscar os totalizadores.");
  }
}

export const exportGraph = async (requestData: ExportGraph) => {
  try {
    const res = await api.post('/nfe/dashboard/graph/export', requestData, { responseType: 'blob' });
    fileDownload(res.data, res.headers.filename)
  } catch (err) {
    throw new Error("Ocorreu um erro ao exportar o grafico");
  }
}

export const getCountGraphData = async (filter: TotalizerDashboardFilter): Promise<GraphData[]> => {
  try {
    return api.get<GraphData[]>('/nfe/dashboard/count-grouped-by-period', { params: filter }).then(({ data }) => data);
  } catch (err) {
    throw new Error("Ocorreu um erro ao tentar buscar os dados do gráfico de quantidade.");
  }
}

export const getTotalAmountGraphData = async (filter: TotalizerDashboardFilter): Promise<GraphData[]> => {
  try {
    return api.get<GraphData[]>('/nfe/dashboard/total-amount-grouped-by-period', { params: filter }).then(({ data }) => data);
  } catch (err) {
    throw new Error("Ocorreu um erro ao tentar buscar os dados do gráfico de total.");
  }
}