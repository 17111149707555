import api from '../api/Api';

export interface GraphData {
  consumo: number;
  contratado: number;
  mes: number;
}

export const getGraphData = async (year: string): Promise<GraphData[]> => {
  try {
    return api.get<GraphData[]>(`/consumo/franchise/${year}`).then(({ data }) => data);
  } catch (err) {
    throw new Error("Ocorreu um erro ao tentar buscar os dados do gráfico de consumo de franquia.");
  }
}