import { getItem, setItem, removeItem } from '@services/LocalStorageService';
import api from '../api/Api';
import { isExpired, decodeToken } from 'react-jwt';
import Cookies from 'js-cookie';
import { AxiosError } from 'axios';
import axios from 'axios';

export const getAuthInfo = (): AuthInfo | null => {
  try {
    const authInfo = getItem('authInfo');

    if (authInfo == null) {
      return null;
    }

    const authInfoDecoded = JSON.parse(authInfo) as AuthInfo;

    const decodedToken = decodeToken(authInfoDecoded.token) as any;

    authInfoDecoded.permissions = decodedToken.permissions;

    return authInfoDecoded;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const saveAuthInfo = (authInfo: AuthInfo): void => {
  setItem('authInfo', authInfo);
  api.defaults.headers.common['Authorization'] = `Bearer ${authInfo.token}`;
}

export const authenticate = async (tenantAlias: string, username: string, password: string): Promise<AuthInfo> => {
  try {
    const response =  await api.post<AuthInfo>('/authenticate', { username, password },
      {
        headers: { tenantAlias }
      });
      return response.data;
  } catch (err) {
    if(axios.isAxiosError(err)) {
        throw new Error((err as AxiosError).response?.data?.detail);
    }
    
    throw new Error("Ocorreu um erro ao tentar fazer a autenticação.");
  }
}

export const authenticateOneClick = async (token: string, tenantAlias: string): Promise<AuthInfo> => {
  try {
    const response =  await api.post<AuthInfo>('/authenticate/one-click', { token },
      {
        headers: { tenantAlias }
      });
      return response.data;
  } catch (err) {
    if(axios.isAxiosError(err)) {
        throw new Error((err as AxiosError).response?.data?.detail);
    }
    
    throw new Error("Ocorreu um erro ao tentar fazer a autenticação.");
  }
}

export const logout = (): void => {
  removeItem('authInfo');
  Cookies.remove("refresh-token");
}

export const shouldAuthenticateAgain = (authInfo: AuthInfo): boolean => {
  const refreshToken = Cookies.get('refresh-token');

  const isJwtExpired = isExpired(authInfo.token);
  const isRefreshTokenExpired = !refreshToken || isExpired(refreshToken);

  return isJwtExpired && isRefreshTokenExpired;
}

export interface AuthInfo {
  username: string;
  token: string;
  expiration: string;
  tenantAlias: string;
  permissions: string[];
  name: string;
}