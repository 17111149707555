import React, { createContext, Reducer, useReducer, useContext } from 'react';
import reducer, { Action } from './reducer';

export interface Company {
    id: number,
    codigoEmpresa: string,
    codigoEstabelecimento: string,
    cnpj: string,
    razaoSocial: string
}

export interface CompanyBranch {
    id: number,
    codigoEmpresa: string,
    codigoEstabelecimento: string,
    cnpj: string,
    razaoSocial: string,
    empresaPaiId: number
}
interface IAppContext {
    accessToken: string | null;
    selectedCompany: Company | null;
    rootCompaniesList: Company[];
    branchesList: CompanyBranch[];
};

interface ContextProps {
    state: IAppContext,
    dispatch: React.Dispatch<Action>
};

export const INITIAL_STATE: ContextProps = {
    state: {
        accessToken: null,
        selectedCompany: null,
        rootCompaniesList: [],
        branchesList: []
    },
    dispatch: () => { }
}

const AppContext = createContext<ContextProps>(INITIAL_STATE);

const AppContextProvider: React.FC = ({ children }) => {

    const [state, dispatch] = useReducer<Reducer<any, any>>(reducer, INITIAL_STATE.state);

    return (<AppContext.Provider
        value={{
            state,
            dispatch
        }}
    >
        {children}
    </AppContext.Provider>
    );
}

export const useSelectedRootCompany = (): Company | null => {
    const { state: { selectedCompany } } = useContext(AppContext);

    return selectedCompany;
}

export { AppContextProvider };
export default AppContext;