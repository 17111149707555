import React from 'react';
import { NFeHistoryDetail } from "@models/NFeHistory";

interface HistoryDetailsProps {
  items: NFeHistoryDetail[];
}

const HistoryDetails: React.FC<HistoryDetailsProps> = ({ items }) => {
  if (!items || !items.length) {
    return null;
  }

  return (
    <div style={{ fontSize: '80%' }}>
      {items.map(detail => (
        <div key={detail.id}>
          <span><strong>{detail.key.toUpperCase()}: </strong>{detail.description}</span>
        </div>))}
    </div>
  )
}

export default HistoryDetails;