import { useEffect, useRef, useState, KeyboardEvent } from "react";
import { StyledFrame } from './styles'
import { refreshTokenSSO } from "api/Api";
import { useHistory } from "react-router-dom";
import { useAuth } from "@contexts/auth";

const SsoPage = () => {

    const history = useHistory();
    const auth = useAuth();
    useEffect(() => {
        auth.signInSSO().then(({tenantAlias}) => history.replace(`/${tenantAlias}/home`));
    }, []);

    return <StyledFrame>
        <h1>Entrando...</h1>
    </StyledFrame>;
}

export default SsoPage;