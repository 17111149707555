import api from "./Api";

export default class NFCeInutilizacaoApi {

    downloadXmlBatch = async (ids: number[]) => {
        try {
            const response = await api.get(`/nfce/inutilizacao/download/batch`, {
                params: { ids: ids.join(',') },
                responseType: 'blob'
            });

            return response;
        } catch (err) {
            console.log("Failed to download the NFe xml batch file.", err);
            return null;
        }
    }

}