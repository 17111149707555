import GlobalContext from 'context';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import App from './App';
import { AuthProvider } from './context/auth';
import { pdfjs } from 'react-pdf';
import { CircularProgress, LinearProgress } from '@mui/material';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

ReactDOM.render(
  <React.StrictMode>
    <ToastContainer draggable={false} closeOnClick={false} autoClose={8000} />
    <div id="loading" style={{display: 'none', position: 'absolute', width: '100%', zIndex: 1000}}>
        <LinearProgress color="success" variant="determinate" id='bar' value={0}/>
        <CircularProgress color="success" style={{marginLeft: '50%', marginTop: '5px'}}/>
    </div>
    <GlobalContext>
      <BrowserRouter>
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>
    </GlobalContext>
  </React.StrictMode>,
  document.getElementById('root')
);