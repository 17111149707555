export const PERMISSIONS = {
    DOOTAX: {
        NFE: {
            DASHBOARD: "dootax-nfe-dashboard",
            GERENCIAR: {
                VISUALIZAR: "dootax-nfe-visualizar",
                CANCELAR: "dootax-nfe-cancelar",
                EMITIR_CARTA_CORRECAO: "dootax-nfe-emitir-carta-correcao"
            },
            INUTILIZAR_NUMERACAO: {
                VISUALIZAR: "dootax-nfe-inutilizar-numeracao-visualizar",
                INUTILIZAR: "dootax-nfe-inutilizar-numeracao-inutilizar"
            },
            CONFIGURACAO: {
                VISUALIZAR: "dootax-nfe-config-visualizar",
                EDITAR: "dootax-nfe-config-editar"
            }
        },
		NFCE: {
            DASHBOARD: "dootax-nfce-dashboard",
            GERENCIAR: {
                VISUALIZAR: "dootax-nfce-visualizar",
                CANCELAR: "dootax-nfce-cancelar"
            },
            INUTILIZAR_NUMERACAO: {
                VISUALIZAR: "dootax-nfce-inutilizar-numeracao-visualizar",
                INUTILIZAR: "dootax-nfce-inutilizar-numeracao-inutilizar"
            },
            CONFIGURACAO: {
                VISUALIZAR: "dootax-nfce-config-visualizar",
                EDITAR: "dootax-nfce-config-editar"
            }
        }
    }
}