import "bootstrap-icons/font/bootstrap-icons.css";
import LoginPage from "pages/login/loginPage";
import { Route, Switch, Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ProtectedRoute } from "routes";
import styled from 'styled-components';
import 'utils/commons.css';
import { PERMISSIONS } from "utils/permissions";
import ProfilePage from "pages/profilePage/profilePage";
import ErrorPage from "@pages/error/errorPage";
import DashboardPage from "pages/nfe/dashboardPage";
import FranquiaPage from "pages/franquia/dashboardPage";
import ListPage from "pages/nfe/listPage";
import DisableNumbersPage from "pages/nfe/disableNumbersPage";
import NFeConfigurationsPage from "pages/nfe/configurationsPage";
import NFCeDashboardPage from "pages/nfce/dashboardPage";
import NFCeListPage from "pages/nfce/listPage";
import NFCeDisableNumbersPage from "pages/nfce/disableNumbersPage";
import NFCeConfigurationsPage from "pages/nfce/configurationsPage";
import SatListPage from "pages/sat/listPage";
import { useAuth } from './context/auth';
import SplashPage from '@pages/splash/splashPage';
import IndexPage from '@pages/index/indexPage';
import SsoPage from "@pages/sso/sso";
import SsoErrorPage from "@pages/sso-error/ssoErrorPage";
import OneClickLoginPage from "@pages/one-click-login/oneClickLoginPage";

const StyledAppFrame = styled.div`
	height: 100vh;
	max-height: 100vh;
	width: 100vw;
	max-width: 100vw;

	display: flex;

	overflow: hidden;
`;

const App: React.FC = () => {
	const { loading, authenticated, authInfo, signOut } = useAuth();

	if (loading) {
		return <SplashPage />;
	}

	return (
		<StyledAppFrame>
			<Switch>
				<Route exact path="/" render={() => <SplashPage loading={false} message="É necessário informar o alias do seu ambiente na URL" />} />
				<Route exact path="/error" render={() => <ErrorPage message="É necessário informar o alias do seu ambiente na URL" hideBackButton />} />
				<Route
					exact
					path="/:tenant/login"
					component={LoginPage}
				/>
				<Route
					exact
					path="/:tenant/one-click-login"
					component={OneClickLoginPage}
				/>
				<Route
					exact
					path="/:tenant/sso"
					component={SsoPage}
				/>
				<ProtectedRoute
					exact
					path="/:tenant/home"
					component={IndexPage} />
				<ProtectedRoute
					exact
					path="/:tenant/franquia"
					component={FranquiaPage}
				/>
				<Route
					exact
					path="/:tenant/permissao-negada"
					render={(props) => <ErrorPage message="Você não tem permissão para acessar essa página" match={props.match}/>}
				/>
				<Route
					exact
					path="/:tenant/error-sso"
					render={(props) => <SsoErrorPage match={props.match}/>}
				/>
				<ProtectedRoute
					exact
					path="/:tenant/nfe/notas"
					component={ListPage}
					requiredPermissions={[PERMISSIONS.DOOTAX.NFE.GERENCIAR.VISUALIZAR]}
				/>
				<ProtectedRoute
					exact
					path="/:tenant/nfe/dashboard"
					component={DashboardPage}
					requiredPermissions={[PERMISSIONS.DOOTAX.NFE.DASHBOARD]}
				/>
				<ProtectedRoute
					exact
					path="/:tenant/nfe/inutilizar"
					component={DisableNumbersPage}
					requiredPermissions={[PERMISSIONS.DOOTAX.NFE.INUTILIZAR_NUMERACAO.VISUALIZAR, PERMISSIONS.DOOTAX.NFE.INUTILIZAR_NUMERACAO.INUTILIZAR]}
				/>
				<ProtectedRoute
					exact
					path="/:tenant/nfe/configuracoes"
					component={NFeConfigurationsPage}
					requiredPermissions={[PERMISSIONS.DOOTAX.NFE.CONFIGURACAO.VISUALIZAR, PERMISSIONS.DOOTAX.NFE.CONFIGURACAO.EDITAR]}
				/>
				<ProtectedRoute
					exact
					path="/:tenant/perfil"
					component={ProfilePage}
					requiredPermissions={[PERMISSIONS.DOOTAX.NFE.INUTILIZAR_NUMERACAO.VISUALIZAR]}
				/>

				<ProtectedRoute
					exact
					path="/:tenant/nfce/notas"
					component={NFCeListPage}
					requiredPermissions={[PERMISSIONS.DOOTAX.NFCE.GERENCIAR.VISUALIZAR]}
				/>
				<ProtectedRoute
					exact
					path="/:tenant/nfce/dashboard"
					component={NFCeDashboardPage}
					requiredPermissions={[PERMISSIONS.DOOTAX.NFCE.DASHBOARD]}
				/>
				<ProtectedRoute
					exact
					path="/:tenant/nfce/inutilizar"
					component={NFCeDisableNumbersPage}
					requiredPermissions={[PERMISSIONS.DOOTAX.NFCE.INUTILIZAR_NUMERACAO.VISUALIZAR, PERMISSIONS.DOOTAX.NFCE.INUTILIZAR_NUMERACAO.INUTILIZAR]}
				/>
				<ProtectedRoute
					exact
					path="/:tenant/nfce/configuracoes"
					component={NFCeConfigurationsPage}
					requiredPermissions={[PERMISSIONS.DOOTAX.NFCE.CONFIGURACAO.VISUALIZAR, PERMISSIONS.DOOTAX.NFCE.CONFIGURACAO.EDITAR]}
				/>
				<ProtectedRoute
					exact
					path="/:tenant/nfce/sat/notas"
					component={SatListPage}
					requiredPermissions={[PERMISSIONS.DOOTAX.NFCE.GERENCIAR.VISUALIZAR]}
				/>
				<Route
					exact
					path="/:tenant"
					render={(props) => {
						const tenantRoute = props.match.params.tenant;

						if (authInfo && authInfo.tenantAlias !== tenantRoute) {
							signOut();
							return <Redirect to={`/${tenantRoute}/login`} />;
						}

						return <Redirect to={`/${props.match.params.tenant}/${authenticated ? 'home' : 'login'}`} />;
					}}
				/>
				<Route path="/:tenant" render={(props) => <ErrorPage message="Não foi possível encontrar essa página" subMessage="Confira se a URL foi digitada corretamente" match={props.match}/>} />
			</Switch>
		</StyledAppFrame>
	);
}

export default App;