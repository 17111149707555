import api from '../api/Api';
import { Company, CompanyBranch } from '@contexts/app/context';
import { PickListItem } from '@components/picklist/picklist';
import { formatToCPFOrCNPJ } from 'brazilian-values';

interface BranchesPageResponse {
  content: CompanyBranch[]
  last: boolean
  totalElements: number
}

export const findAllRootCompanies = async (): Promise<Company[]> => {
  try {
    return api.get<Company[]>('/company/root').then(({ data }) => data);
  } catch (err) {
    throw new Error("Ocorreu um erro ao tentar fazer a autenticação.");
  }
}

export const searchAllRootCompanies = async (searchText: string | null): Promise<Company[]> => {

  const pageProps = { searchText }

  try {
    return api.get<Company[]>('/company/root', { params: pageProps }).then(({ data }) => data);
  } catch (err) {
    throw new Error("Ocorreu um erro ao tentar fazer a autenticação.");
  }
}


export const findBranchesByParentId = async (
  parentId: number,
  searchText: string | null,
  page: number,
  size: number,
  sort: "codigoEstabelecimento" | "codigoEmpresa" | "id" | "cnpj" | "razaoSocial",
  orderDirection: "ASC" | "DESC"): Promise<BranchesPageResponse> => {
  try {

    const pageProps = {
      searchText,
      page,
      size,
      sort: `${sort},${orderDirection}`
    }
    

    return api.get<BranchesPageResponse>(`/company/${parentId}/branches`, { params: pageProps }).then(({ data }) => data);
  } catch (err) {
    throw new Error("Ocorreu um erro ao tentar fazer a autenticação.");
  }
}


export const buildRootCompanyDescription = (company: Company): PickListItem => {
  return {
    id: company.id,
    codigo: company.codigoEmpresa,
    value: `${company.codigoEmpresa} - ${company.razaoSocial} - ${formatToCPFOrCNPJ(company.cnpj)}`
  }
}

export const buildBranchDescription = (branch: CompanyBranch): PickListItem => {
  return {
    id: branch.id,
    codigo: branch.codigoEstabelecimento,
    value: `${branch.codigoEstabelecimento} - ${branch.razaoSocial} - ${formatToCPFOrCNPJ(branch.cnpj)}`
  }
}
