import React from 'react';
import { useAuth } from '@contexts/auth';

interface PermissionControlProps {
  requiredPermissions: string[];
}

const PermissionControl: React.FC<PermissionControlProps | null> = ({ children, requiredPermissions }: React.PropsWithChildren<PermissionControlProps>) => {
  const { authenticated, authInfo } = useAuth();

  if (!requiredPermissions || !authenticated || !authInfo || !authInfo.permissions) {
    return null;
  }

  const { permissions: userPermissions } = authInfo;

  const userHasPermission = userPermissions.some((permissionName: string) => requiredPermissions.includes(permissionName));

  return <>{userHasPermission && children}</>;
}

interface PermissionControlOptionalProps {
  requiredPermissions?: string[];
}

export const PermissionControlOptional: React.FC<PermissionControlOptionalProps | null> = ({ children, requiredPermissions }: React.PropsWithChildren<PermissionControlOptionalProps>) => {
  if (!requiredPermissions) {
    return <>{children}</>;
  }

  return <PermissionControl requiredPermissions={requiredPermissions}>{children}</PermissionControl>;
}

export default PermissionControl;