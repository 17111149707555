export interface NFeStatus {
    label: string,
    value: string | null,
    refColor: string,
    code: string | null,
    active: boolean,
    visible: boolean,
}

export const nfeStatus: NFeStatus[] = [
    {
        label: 'Todos',
        value: null,
        refColor: '#000',
        code: null,
        active: true,
        visible: true
    },
    { 
        label: 'Aguardando Envio', 
        value: 'AGUARDANDO_ENVIO', 
        refColor: '#5a72f8',
        code: '0',
        active: true,
        visible: true
    },
    { 
        label: 'Processando Envio',
        value: 'PROCESSANDO_ENVIO',
        refColor: '#3149d1',
        code: '10',
        active: true,
        visible: true
    },
    { 
        label: 'Erro no Envio', 
        value: 'ERRO_ENVIO', 
        refColor: '#f00d0d',
        code: '20',
        active: true,
        visible: true
    },
    { 
        label: 'Enviado SEFAZ',
        value: 'ENVIADO_SEFAZ',
        refColor: '#ac24b1',
        code: '30',
        active: true,
        visible: true
    },
    { 
        label: 'Rejeitado SEFAZ',
        value: 'REJEITADO_SEFAZ',
        refColor: '#f00d0d',
        code: '50',
        active: true,
        visible: true
    },
    { 
        label: 'Erro SEFAZ',
        value: 'ERRO_SEFAZ',
        refColor: '#f00d0d',
        code: '40',
        active: true,
        visible: false
    },
    { 
        label: 'Erro Autorização Offline',
        value: 'ERRO_OFFLINE',
        refColor: '#41c718',
        code: '91',
        active: true,
        visible: true
    },
    { 
        label: 'Autorizado',
        value: 'AUTORIZADO',
        refColor: '#41c718',
        code: '100',
        active: true,
        visible: true
    },
    { 
        label: 'Cancelado',
        value: 'CANCELADO',
        refColor: '#4d4d4d',
        code: '101',
        active: true,
        visible: true
    },
    { 
        label: 'Denegado',
        value: 'DENEGADO',
        refColor: '#e98a1e',
        code: '110',
        active: true,
        visible: true
    },
];