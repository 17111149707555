import { useState } from 'react';
import styled from 'styled-components';
import DootaxLogo from '../../utils/images/marca_dootax.svg';
import { Link } from 'react-router-dom';
import { PERMISSIONS } from 'utils/permissions';
import { useAuth } from '@contexts/auth';
import PermissionControl from '@components/permission/PermissionControl';

const StyledSidebarFrame = styled.aside`

    /* height: 100vh; */
	height: 100%;

    min-width: 50px;
    max-width: 50px;

    display: flex;
    flex-direction: column;

    background-color: var(--grey-dark);
    overflow: hidden;
    
    transition: min-width 0.35s ease-in, max-width 0.35s ease-in;

    .header {
        height: 55px;
        min-height: 55px;
        max-width: 100%;

        position: relative;
        display: flex;

        border-bottom: 1px solid var(--grey-darkest);

        img {
            height: 22px;
            margin: auto;
            transform: translateY(-2px);
            opacity: 1;

            transition: opacity .35s ease-in;

            :hover {
                cursor: pointer;
            }
        }

        i {
            color: #fdfdfd;
            opacity: 0.85;
            font-size: 1rem;

            position: absolute;

            right: 0;
            top: 3px;
            text-align: center;
            line-height: 50px;
            width: 50px;

            transition: all .2s ease-in;

            :hover {
                cursor: pointer;
                opacity: 1;
            }
        }
    }

    .body {
        padding: 22px;
		padding-bottom: 0px;
        display: flex;
        flex-direction: column;
        
        h4 {
            margin: 0;
            margin-bottom: 12px;
            
            color: var(--white);
            font-weight: 600;
	        font-family: 'JetBrains Semi Bold';
            font-size: 1rem;
            
            opacity: 1;
            opacity: .9;
            transition: opacity 0.2s ease-out;
        }

        a {
            display: flex;
            text-decoration: none;
            opacity: .9;
            transition: all 0.2s ease-out;

            > span {
                min-width: 200px;
                margin: 6px;
                padding: 6px;
                font-size: 0.95rem;
                
                color: var(--white);
                white-space: nowrap;
                

                :hover {
                    opacity: 1;
                    color: var(--color-primary) !important;
                    cursor: pointer;
                }
            }
        }
            
        .is-selected {
            color: var(--color-primary) !important;
        }
    }

    .footer {
        min-height: 55px;
        max-height: 55px;
        max-width: 100%;
        margin-top: auto;

        position: relative;
        display: flex;
     
        border-top: 1px solid var(--grey-darkest);

        a {
            width: 220px;
            
            color: var(--white);
            opacity: .7;
            
            padding: 12px 22px;
            
            text-decoration: none;
            white-space: nowrap;
            
            display: flex;
            justify-content: space-between;
            
            transition: opacity 0.35s ease-in, width 0.35s ease-in;
            
            span {
                opacity: .7;
                transition: opacity 0.25s ease-out;
                margin-top: auto;
                margin-bottom: auto;
            }
            
            i {
                font-size: .9rem;
                margin-left: auto;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
    }

    &.opened { 
        min-width: 220px;
    }

    &.closed {
        
        .header {
            img {
                opacity: 0;
            }
        }

        .body {
            a, h4 {
                opacity: 0;
            }
        }

        .footer {
            a {
                width: 50px;
                padding: 0;
                margin: auto;
                
                span {
                    width: 0;
                    opacity: 0;
                }

                i {
                    transform: translateX(-18px);
                }
            }
        }
    }
`;

const SideBar = () => {
    const [isOpened, setIsOpened] = useState(true);
    const { tenantInfo } = useAuth();
    const displayVersionName = !tenantInfo?.deployVersion ? 'v1.0.0' : (tenantInfo.deployVersion === 'qa' ? 'Versão de QA' : `v${tenantInfo.deployVersion}`);

    return <StyledSidebarFrame className={!isOpened ? 'closed' : 'opened'}>
        {/* HEADER */}
        <section className="header" onClick={() => !isOpened && setIsOpened(true)}>
            <img src={DootaxLogo} onClick={() => window.open('http://www.dootax.com.br')}></img>
            <i className="bi bi-list" onClick={() => setIsOpened(!isOpened)}></i>
        </section>

        {/* BODY */}
        <section className="body">
            <h4 className="no-select">{'<NFe/>'}</h4>
            <PermissionControl requiredPermissions={[PERMISSIONS.DOOTAX.NFE.DASHBOARD]}>
                <Link to={`/${tenantInfo?.tenantAlias}/nfe/dashboard`}><span>Dashboard</span></Link>
            </PermissionControl>

            <PermissionControl requiredPermissions={[PERMISSIONS.DOOTAX.NFE.GERENCIAR.VISUALIZAR, PERMISSIONS.DOOTAX.NFE.GERENCIAR.EMITIR_CARTA_CORRECAO, PERMISSIONS.DOOTAX.NFE.GERENCIAR.CANCELAR]}>
                <Link to={`/${tenantInfo?.tenantAlias}/nfe/notas`}><span>Notas emitidas</span></Link>
            </PermissionControl>

            <PermissionControl requiredPermissions={[PERMISSIONS.DOOTAX.NFE.INUTILIZAR_NUMERACAO.VISUALIZAR, PERMISSIONS.DOOTAX.NFE.INUTILIZAR_NUMERACAO.INUTILIZAR]}>
                <Link to={`/${tenantInfo?.tenantAlias}/nfe/inutilizar`}><span>Inutilizar numeração</span></Link>
            </PermissionControl>

            <PermissionControl requiredPermissions={[PERMISSIONS.DOOTAX.NFE.CONFIGURACAO.VISUALIZAR, PERMISSIONS.DOOTAX.NFE.CONFIGURACAO.EDITAR]}>
                <Link to={`/${tenantInfo?.tenantAlias}/nfe/configuracoes`}><span>Configurações</span></Link>
            </PermissionControl>
        </section>
		
		<section className="body">
            <h4 className="no-select">{'<NFCe/>'}</h4>
            <PermissionControl requiredPermissions={[PERMISSIONS.DOOTAX.NFCE.DASHBOARD]}>
                <Link to={`/${tenantInfo?.tenantAlias}/nfce/dashboard`}><span>Dashboard</span></Link>
            </PermissionControl>

            <PermissionControl requiredPermissions={[PERMISSIONS.DOOTAX.NFCE.GERENCIAR.VISUALIZAR, PERMISSIONS.DOOTAX.NFCE.GERENCIAR.CANCELAR]}>
                <Link to={`/${tenantInfo?.tenantAlias}/nfce/notas`}><span>NFCe emitidas</span></Link>
            </PermissionControl>

            <PermissionControl requiredPermissions={[PERMISSIONS.DOOTAX.NFCE.GERENCIAR.VISUALIZAR]}>
                <Link to={`/${tenantInfo?.tenantAlias}/nfce/sat/notas`}><span>SAT emitidas</span></Link>
            </PermissionControl>

            <PermissionControl requiredPermissions={[PERMISSIONS.DOOTAX.NFCE.INUTILIZAR_NUMERACAO.VISUALIZAR, PERMISSIONS.DOOTAX.NFCE.INUTILIZAR_NUMERACAO.INUTILIZAR]}>
                <Link to={`/${tenantInfo?.tenantAlias}/nfce/inutilizar`}><span>Inutilizar numeração</span></Link>
            </PermissionControl>

            <PermissionControl requiredPermissions={[PERMISSIONS.DOOTAX.NFCE.CONFIGURACAO.VISUALIZAR, PERMISSIONS.DOOTAX.NFCE.CONFIGURACAO.EDITAR]}>
                <Link to={`/${tenantInfo?.tenantAlias}/nfce/configuracoes`}><span>Configurações</span></Link>
            </PermissionControl>
        </section>

        {/* FOOTER */}
        <section className="footer">
            <a>
                <span>© Dootax - {displayVersionName}</span>
            </a>
        </section >

    </StyledSidebarFrame>;
}

export default SideBar;