import { FunctionComponent } from 'react';
import { Redirect, Route } from "react-router";
import { RouteProps, RouteComponentProps } from 'react-router-dom';
import { useAuth } from '@contexts/auth';

interface ProtectedRouteProps extends RouteProps<string> {
    requiredPermissions?: string[];
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    computedMatch?: { params: { tenant: string } };
}

export const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({ component: Component, requiredPermissions, computedMatch, ...rest }) => {
    const { authenticated: isAuthenticated, tenantInfo, authInfo, signOut } = useAuth();

    const tenantRoute = computedMatch?.params.tenant;

    if (tenantInfo && (tenantInfo.tenantAlias !== tenantRoute)) {
        signOut();
        return <Redirect to={`/${tenantRoute}/login`} />;
    }

    if (!isAuthenticated) {
        return <Redirect to={`/${tenantRoute}/login`} />
    }

    const { permissions: userPermissions } = authInfo || { permissions: [] };

    const userHasPermission = requiredPermissions == null || userPermissions.some((permissionName: string) => requiredPermissions.includes(permissionName));

    if (!userHasPermission) {
        return <Redirect to={`/${tenantRoute}/permissao-negada`} />
    }

    return <Route {...rest} render={(props: any) => <Component {...props} />} />;
};

export const routes = [
    {
        path: "/login",
        breadcrumbText: 'Login'
    },
    {
        path: "/home",
        breadcrumbText: 'Home'
    },
    {
        path: "/perfil",
        breadcrumbText: 'Perfil'
    },
    {
        path: "/franquia",
        breadcrumbText: 'Franquia'
    },
    {
        path: "/permissao-negada",
        breadcrumbText: 'Permissão negada'
    },
    {
        path: "/nfe/dashboard",
        breadcrumbText: 'Dashboard',
        module: '<NFe/>'
    },
    {
        path: "/nfe/notas",
        breadcrumbText: 'Notas emitidas',
        module: '<NFe/>'
    },
    {
        path: "/nfe/inutilizar",
        breadcrumbText: 'Inutilizar numeração',
        module: '<NFe/>'
    },
    {
        path: "/nfe/configuracoes",
        breadcrumbText: 'Configurações',
        module: '<NFe/>'
    },
    {
        path: "/nfce/dashboard",
        breadcrumbText: 'Dashboard',
        module: '<NFCe/>'
    },
    {
        path: "/nfce/notas",
        breadcrumbText: 'NFCe emitidas',
        module: '<NFCe/>'
    },
    {
        path: "/nfce/inutilizar",
        breadcrumbText: 'Inutilizar numeração',
        module: '<NFCe/>'
    },
    {
        path: "/nfce/configuracoes",
        breadcrumbText: 'Configurações',
        module: '<NFCe/>'
    },
    {
        path: "/nfce/sat/notas",
        breadcrumbText: 'SAT Emitidas',
        module: '<NFCe/>'
    }
];