import api from '../api/Api';

import { NFeInutilizacao } from '@models/NFeInutilizacao';
import { PageResponse } from '@models/PageResponse';
import { NFeInutilizacaoFilter } from '@models/NFeInutilizacaoFilter';
import { Column } from '@components/table/table';
import fileDownload from 'js-file-download';

export const findByFilter = async (filter: NFeInutilizacaoFilter, { direction: orderDirection, columnOrdered: { accessor: orderBy } }: SortInfo, page: number = 0): Promise<PageResponse<NFeInutilizacao>> => {
  try {
    return api.get<PageResponse<NFeInutilizacao>>('/nfe/inutilizacao', { params: { ...filter, page, sort: `${orderBy},${orderDirection}` } }).then(({ data }) => data);
  } catch (err) {
    throw new Error("Ocorreu um erro ao tentar buscar a listagem de inutilizações.");
  }
}

export const inutilize = async (request: InutilizarNumeracaoRequest): Promise<InutilizarNumeracaoResponse> => {
  try {
    return api.post<InutilizarNumeracaoResponse>('/nfe/inutilizacao', request).then(({ data }) => data);
  } catch (err) {
    throw new Error("Ocorreu um erro inutilizar a numeração.");
  }
}

export interface SortInfo {
  columnOrdered: Column;
  direction: string;
}

export interface InutilizarNumeracaoRequest {
  companyId: number;
  year: number;
  series: number;
  initialNum: number;
  finalNum: number;
  justification: string;
}

export interface InutilizarNumeracaoResponse {
  status: string;
  message: string;
}

export const exportNFes = async (filters: NFeInutilizacaoFilter) => {
  try {
      const res = await api.get('/nfe/inutilizacao/export', { responseType: 'blob', params: filters });

      fileDownload(res.data, res.headers.filename)

  } catch (err) {
      console.log("Failed to export NFes. ", err);
      return null;
  }
};