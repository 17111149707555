const prependPrefix = (key: string): string => {
  return `@DootaxNFE:${key}`;
}

export const getItem = (key: string): string | null => {
  return localStorage.getItem(prependPrefix(key));
}

export const setItem = (key: string, value: any): void => {
  const finalValue: string = (typeof value === 'string') ? value : JSON.stringify(value);
  localStorage.setItem(prependPrefix(key), finalValue);
}

export const removeItem = (key: string): void => {
  localStorage.removeItem(prependPrefix(key));
}