import { NFe } from "model/NFe";
import { NFeFilter } from "model/NFeFilter";
import api from "./Api";
import format from "date-fns/esm/fp/format/index.js";
import { PageResponse } from '@models/PageResponse';
import fileDownload from 'js-file-download';

export default class NFeInutilizacaoApi {

    downloadXmlBatch = async (ids: number[]) => {
        try {
            const response = await api.get(`/nfe/inutilizacao/download/batch`, {
                params: { ids: ids.join(',') },
                responseType: 'blob'
            });

            return response;
        } catch (err) {
            console.log("Failed to download the NFe xml batch file.", err);
            return null;
        }
    }

}