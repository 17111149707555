import { useEffect, useState } from "react";
import styled from "styled-components";
import { VictoryChart, VictoryGroup, VictoryTheme, VictoryBar, VictoryTooltip, FlyoutProps } from "victory";
import { useMeasure } from 'react-use';
import DatePicker from "react-date-picker";
import { format } from "date-fns/esm";
import PageFrame from "components/page-frame/pageFrame";
import { getGraphData, GraphData } from '@services/FranchiseService';

const StyledFrame = styled.div<{ isStoreFocused: boolean }>`

    height: 100%;
    max-width: 100%;

    overflow: hidden;

    display: flex;
    flex-direction: column;

    .line-separator {
        background-color: rgba(125, 125, 125, 1);
        height: 10px;
        width: 100%;
    }

    .period-frame {
        max-height: 24px;
        margin-bottom: 12px;
        display: flex;
        justify-content: flex-end;
        text-align: end;
        position: relative;
        user-select: none;

        small {
            margin-top: auto;
            color: var(--grey-regular);
        }

        button {
            position: relative;
            min-height: fit-content;
            width: calc(100% - 24px);
            min-width: fit-content;

            > i {
                display: none;
            }
        }

        .react-date-picker__inputGroup {
            display: flex;
            justify-content: flex-end;
            min-width: fit-content;
            margin-top: 4px;
            line-height: 1.3rem;

            margin-right: 0;

            * {
                cursor: pointer;
            }
        }

        .react-date-picker__wrapper {
            background-color: transparent;
            width: fit-content;
            height: 24px;

            border: none;

            padding: 0;
        }

        .fixed-periods-frame {
            height: fit-content;
            display: flex;

            small {
                width: fit-content;
                padding: 0px 6px;
                margin-right: 8px;

                white-space: nowrap;

                background-color: var(--white);
                background-color: transparent;
                border: 1px solid var(--grey-light);
                border-radius: 6px;

                cursor: pointer;

                &.selected {
                    border: 2px solid var(--color-primary);
                    color: var(--grey-dark);
                }
            }
        }
    }

    .main-content {
        height: calc(100% - 18px);
        width: 100%;
        overflow: hidden;
        max-width: 100%;

        display: flex;
        flex-direction: column;

        .label {
            text-transform: uppercase;
            /* font-family: 'JetBrains Semi Bold'; */
            color: var(--grey-dark);
            font-weight: bold;
        }

        .info-frame {
            min-height: 120px;

            width: calc(100% - 26px); // 26px = padding (24px) + border top and bottom (2px)

            display: flex;

            margin-right: 12px;
            padding: 12px;

            border: 1px solid var(--grey-light);
            border-radius: 12px;

            background-color: var(--white);

            .general-info {
                height: calc(100% - 26px); // 26px = padding (24px) + border top and bottom (2px)
                min-width: 30%;

                .value-label {
                    margin-left: auto;
                    color: var(--grey-regular);
                    text-transform: uppercase;
                    line-height: 24px;
                }

                p {
                    margin: 0;

                    &.text {
                        text-transform: uppercase;
                    }

                    &.total-count {
                        width: 100%;
                        font-size: 3rem;
                    }

                    &.value {
                        margin-left: 12px;
                    }
                }
            }

            .separator {
                height: 70%;
                width: 1px;
                background-color: var(--grey-light);
                margin: auto 24px;
            }

            .status-frame {
                height: 100%;
                width: 100%;

                .status-top-frame {
                    height: 100%;
                    display: flex;
                    margin-bottom: 16px;

                    .status {
                        height: calc(100% - 16px);
                        width: 50%;
                        min-width: 230px;

                        display: flex;
                        flex-direction: column;
                    }

                    .status-info-header {
                        display: flex;
                        min-width: 100%;
                        min-height: 24px;
                        max-height: 24px;
                        margin-bottom: 12px;

                        a {
                            margin-left: auto;
                            color: var(--blue);
                            cursor: pointer;
                            text-decoration: underline;
                        }

                        button {
                            margin-left: auto;
                            color: var(--blue);
                            cursor: pointer;
                            text-decoration: underline;
                            border: 0px;
                            background-color: transparent;
                        }

                        #picklist {
                            line-height: 25px;
                            min-width: 170px;

                            .overlay {
                                .header-section {
                                    > span {
                                        font-family: 'JetBrains Regular' !important;
                                        text-transform: uppercase;
                                    }
                                }
                            }
                            .overlay.is-opened {
                                height: 160px;
                            }
                            .body-section.is-opened {
                                height: 110px;
                            }
                        }
                    }

                    .status-info {
                        width: 100%;

                        display: flex;
                        flex-direction: column;

                        margin-left: 12px;

                        .status-primary-label {
                            max-width: 100%;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        small {
                            color: var(--grey-regular);
                            margin-top: auto;
                            line-height: 12px;

                            &:first-of-type {
                                margin-left: auto;
                                margin-right: 12px;
                            }
                        }
                    }

                    .chart-frame {
                        height: 100%;
                        width: 50px;
                        min-width: 60px;
                        margin-top: 8px;

                        g {
                            transform: scale(1.3) translate(-42px, -42px);
                        }
                    }
                }
            }
        }

        .graphs {
            width: 100%;
            height: 100%;

            margin-top: 18px;

            display: flex;
            justify-content: space-between;
            overflow: hidden;

            .graph-frame {
                width: 100%;

                .graph-header {
                    display: flex;
                    flex-direction: column;

                    .title {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width:100%;

                        h1 {
                            margin: 0px;
                        }
                    }

                    .legend {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width:100%;

                        .boxContainer {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 15px;

                            h4 {
                                color: rgba(125, 125, 125, 1)
                            }

                            .box-consumo {
                                width: 50px;
                                height: 10px;
                                border: 2px solid rgba(167, 50, 47, 1);
                                background: rgba(167, 50, 47, 0.5);
                                margin-right: 5px;
                            }

                            .box-contratado {
                                width: 50px;
                                height: 10px;
                                border: 2px solid rgba(55, 112, 182, 1);
                                background: rgba(55, 112, 182, 0.5);
                                margin-right: 5px;
                            }
                        }
                    }
                }

                line {
                    stroke: var(--grey-light) !important;
                    opacity: .7;
                }

                > div {
                    height: calc(100% - 26px);
                    padding: 12px;

                    border: 1px solid var(--grey-light);
                    border-radius: 12px;

                    background-color: var(--white);
                }

                > span {
                    font-weight: bold;
                }

                svg {
                    transform: translateY(-15px) scaleY(1);
                }

                #picklist {
                    line-height: 25px;
                    min-width: 170px;

                    .overlay {
                        .header-section {
                            > span {
                                font-family: 'JetBrains Regular' !important;
                                text-transform: uppercase;
                            }
                        }

                    }
                    .overlay.is-opened {
                        height: 130px;
                    }

                }
            }
        }

        .loading {
            height: 40px;
            display: grid;
            place-items: center;
            color: var(--grey-regular);
        }

        .bottom-frame {
            height: 30%;
            max-height: 180px;

            display: flex;

            margin-top: 16px;

            .franchise-cover {
                height: calc(100% - 18px);
                min-width: 250px;

                position: relative;

                display: flex;
                flex-direction: column;

                overflow: hidden;

                padding: 8px 12px;

                border: 1px solid var(--grey-light);
                border-radius: 12px;

                background-color: var(--white);

                > i {
                    position: absolute;
                    right: 12px;
                    color: var(--grey-light);
                }

                .percent-bar-frame {
                    height: fit-content;

                    display: flex;
                    flex-direction: column;

                    margin-top: auto;
                    margin-bottom: auto;

                    small {
                        color: var(--grey-regular);
                    }

                    h4 {
                        margin: 0;

                        &.percentage {
                            color: var(--color-primary);
                        }

                        &.totalFranchise {
                            color: var(--grey-medium);
                        }
                    }
                }

                > a {
                    margin: auto 0 2px auto;

                    color: var(--blue);
                }
            }

            .list-frame {
                height: calc(100% - 18px);
                max-height: 200px;
                width: 100%;

                display: flex;
                flex-direction: column;

                overflow: hidden;

                padding: 8px 12px;

                border: 1px solid var(--grey-light);
                border-radius: 12px;

                background-color: var(--white);


                > div {
                    display: flex;
                    margin-bottom: 8px;

                    > small {
                        color: var(--blue);
                    }

                    small {
                        margin-left: auto;
                        cursor: pointer;
                        color: var(--grey-medium);
                    }
                }

                .list {
                    min-height: calc(100% - 24px);
                    width: 100%;

                    line-height: .9rem;

                    overflow-y: auto;

                    display: flex;
                    flex-direction: column;

                    .list-item {
                        max-width: 100%;
                        min-height: 24px;

                        border-bottom: 1px solid var(--grey-light);

                        margin-top: 8px;

                        display: flex;
                        justify-content: space-between;

                        span {
                            width: 100%;

                            color: var(--grey-medium-light);

                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;

                            :not(:first-child) {
                                margin-left: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
`;

const DashboardPage = () => {

    const [initDate, setInitDate] = useState(new Date());
    const [selectedPeriod, setSelectedPeriod] = useState('');
    const [isStoreFocused] = useState(false);

    const [ref, { width, height }] = useMeasure<HTMLDivElement>();
    const [dynamicGraphData, setDynamicGraphData] = useState<GraphData[]>([]);

    const months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

    useEffect(() => {
        updateGraphs();
    }, [initDate]);

    useEffect(() => {
        updateGraphs();
    }, []);

    const updateGraphs = () => {
        getGraphData(format(initDate, "yyyy")).then(data => {
            setDynamicGraphData(data);
        });
    }

    const FlyoutFranquia = (props: FlyoutProps) => {
        let { x, y, datum } = props;
        const {consumo, contratado, mes} = datum as GraphData;

        x = x ?? 0;
        y = y ?? 0;

        return (
            <g>
                <rect
                    x={x - 70}
                    y={y - 55}
                    width="140"
                    height="50"
                    rx="4.5"
                    fill="black"
                    stroke="#868C97"
                />
                <text
                    x={x - 55}
                    y={y - 40}
                    font-size="12"
                    font-weight="bold"
                    fill="white"
                    >
                    {`${months[mes-1]}`}
                    </text>
                <text
                    x={x - 40}
                    y={y - 25}
                    font-size="12"
                    font-weight="bold"
                    fill="white"
                    >
                    {`Contratado: ${contratado}`}
                    </text>
                <rect 
                    x={x - 55}
                    y={y - 20}
                    width="10" 
                    height="10"
                    fill="rgba(255, 255, 255)"  
                    fillOpacity="0.8"
                    stroke="rgba(167, 50, 47, 1)"
                    strokeWidth="2" />
                <text
                    x={x - 40}
                    y={y - 10}
                    font-size="12"
                    font-weight="bold"
                    fill="white"
                    >
                    {`Consumido: ${consumo}`}
                    </text>
                <rect 
                    x={x - 55}
                    y={y - 35}
                    fill="rgba(255, 255, 255)"  
                    fillOpacity="0.8"
                    stroke="rgba(55, 112, 182, 1)" 
                    strokeWidth="2"                
                    width="10" 
                    height="10" />
            </g>
        );
    };

    return <PageFrame><StyledFrame isStoreFocused={isStoreFocused}>

        {/* MAIN CONTENT */}
        <div className="main-content">
            <>
                <h3 style={{ margin: '0px' }}>Ano:</h3>
                <div style={{ zIndex: 6 }}>
                    <DatePicker
                        value={initDate}
                        locale="pt-BR"
                        maxDetail="decade"
                        yearPlaceholder="aaaa"
                        calendarIcon={<i className="bi bi-calendar"></i>}
                        clearIcon={null}
                        onChange={(value: Date) => {
                            setInitDate(value);
                            setSelectedPeriod('');
                        }}
                    />
                </div>
            </>

            {/* GRAFICOS */}
            <div className="graphs" ref={ref}>
                <div className="graph-frame">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="graph-header">
                            <div className="title"><h1>Franquia</h1></div>
                            <div className="legend">
                                <div className="boxContainer">
                                    <div className="box-contratado"></div><h4>Contratado</h4>
                                </div>
                                <div className="boxContainer">
                                    <div className="box-consumo"></div><h4>Consumido</h4>
                                </div>
                            </div>
                        </div>
                        <VictoryChart
                                  theme={VictoryTheme.material}
                                  width={width}
                                  height={height-100}
                                >
                                    <VictoryGroup
                                      offset={35}
                                      categories={{ x: months }}
                                    >
                                      <VictoryBar
                                      	alignment="middle"
                                        animate={{ duration: 500, onLoad: { duration: 500 } }}
                                        labels={() => [``, ``, ``]}
                                        labelComponent={ <VictoryTooltip flyoutComponent={<FlyoutFranquia/>}/> }
                                        style={{ data: { width: 30, fill: "rgba(55, 112, 182, 1)", stroke: "rgba(55, 112, 182, 1)", fillOpacity: 0.5, strokeWidth: 3 } }}
                                        data={dynamicGraphData}
                                        x={"mes"}
                                        y={"contratado"}
                                      />
                                      <VictoryBar
                                          alignment="middle"
                                          animate={{ duration: 500, onLoad: { duration: 500 } }}
                                          labels={() => [``, ``, ``]}
                                          labelComponent={ <VictoryTooltip flyoutComponent={<FlyoutFranquia/>}/> }
                                          style={{ data: { width: 30, fill: "rgba(167, 50, 47, 1)", stroke: "rgba(167, 50, 47, 0.8)", fillOpacity: 0.5, strokeWidth: 3 } }}
                                          data={dynamicGraphData}
                                          x={"mes"}
                                          y={"consumo"}
                                        />
                                  </VictoryGroup>
                                </VictoryChart>
                    </div>
                </div>
            </div>
        </div>

    </StyledFrame></PageFrame>;
}

export default DashboardPage;