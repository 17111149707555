import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { AuthInfo, saveAuthInfo, getAuthInfo } from '@services/AuthenticationService';
import { getConfigs } from '@config/environment';

const { apiURL } = getConfigs();

const DEFAULT_HEADERS = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0'
};

const api = axios.create({
    baseURL: apiURL,
    withCredentials: true,
    headers: DEFAULT_HEADERS
});

const unprotectedApiInstance = axios.create({
    baseURL: apiURL,
    withCredentials: true,
    headers: DEFAULT_HEADERS
});

export interface ApplicationError {
    status: number;
    title: string;
    detail: string;
    timestamp: string;
    objects: any[];
}

let reqsCompleted = 0;
let reqsTotal = 0;
let barProgress = 1;
let startTimeout;

api.interceptors.response.use(
    (res) => {
        completeLoading();        
        return Promise.resolve(res);
    },
    async (err) => {
        const { response, config: originalConfig } = err;

        if (originalConfig.url !== "/authenticate" && response) {
            if (response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;

                try {
                    const refreshTokenResponse = await unprotectedApiInstance.get<AuthInfo>("/refresh-token");
                    const authInfo = refreshTokenResponse.data;

                    saveAuthInfo(authInfo);
                    originalConfig.headers['Authorization'] = `Bearer ${authInfo.token}`;

                    return api(originalConfig);
                } catch (_error) {
                    console.error(_error);

                    const auth = getAuthInfo();

                    window.location.href = `/${auth?.tenantAlias}/login`;

                    completeLoading()
                    return Promise.reject(_error);
                }
            }

            const { detail } = (response.data as ApplicationError);

            toast.error(detail || 'Ocorreu um erro ao processar sua solicitação.');

            completeLoading()
            return Promise.reject(err);
        }

        completeLoading()
        return Promise.reject(err);
    }
);

api.interceptors.request.use((config) => {
    startLoading();
    return config;
});

export const refreshTokenSSO = async () : Promise<AxiosResponse<AuthInfo>> => {
    return unprotectedApiInstance.get<AuthInfo>("/refresh-token");
}

function startLoading() {
    if (reqsTotal === 0) {        
        changeLoadingVisibility('block');
    } 
    reqsTotal += 1;
    calculateProgress()
}

function completeLoading() {
    reqsCompleted += 1;
    if (reqsCompleted >= reqsTotal) {
        if(startTimeout) {
            clearTimeout(startTimeout);
        }

        startTimeout = setTimeout(function() {
            changeLoadingVisibility('none');
            reqsCompleted = 0;
            reqsTotal = 0;
        }, 300);
    } else {
        calculateProgress();
    }
}

function calculateProgress() {
    barProgress = reqsCompleted / reqsTotal * 100;
    document.querySelector('#loading #bar')?.setAttribute('aria-valuenow', barProgress.toString());
    document.querySelector('#loading #bar .MuiLinearProgress-bar')?.setAttribute('style', 'transform: translateX(-'+ (100 - barProgress)+'%)');
}

function changeLoadingVisibility(visibility) {
    let loading = document.getElementById('loading');
    if(loading !== null) {
        loading.style.display = visibility;
    }
}

export default api;