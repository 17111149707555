import { NFe } from "model/NFe";
import { NFeFilter } from "model/NFeFilter";
import api from "./Api";
import format from "date-fns/esm/fp/format/index.js";
import { PageResponse } from '@models/PageResponse';
import fileDownload from 'js-file-download';

export default class NFeListingApi {

    exportNFes = async (filters: NFeFilter) => {
        try {
            const requestData = {
                status: filters.status,
                initialDate: filters.initialDateObj ? format("dd/MM/yyyy", filters.initialDateObj) : null,
                finalDate: filters.finalDateObj ? format("dd/MM/yyyy", filters.finalDateObj) : null,
                minValue: filters.vlrMinNf,
                maxValue: filters.vlrMaxNf,
                docDestinatario: filters.docDestinatario,
                serie: filters.serie,
                numDoc: filters.numDoc,
                accessKeys: filters.chaveNf,
                branchesIds: filters.branchesIds,
                companyId: filters.companyId,
                inutilizada: filters.inutilizada
            }

            const res = await api.get('/nfe/export', { responseType: 'blob', params: requestData });

            fileDownload(res.data, res.headers.filename)

        } catch (err) {
            console.log("Failed to export NFes. ", err);
            return null;
        }
    };

    downloadXml = async (nfe: NFe) => {
        try {

            const response = await api.get(`/nfe/${nfe.id}/download`, {
                responseType: 'blob'
            });

            if (response.status === 200) {
                fileDownload(response.data, response.headers.filename)
            }

            return response;
        } catch (err) {
            console.log("Failed to download the NFe xml file.", err);
            return null;
        }
    }

    downloadXmlBatch = async (ids: number[]) => {
        try {
            const response = await api.get(`/nfe/download/batch`, {
                params: { ids: ids.join(',') },
                responseType: 'blob'
            });

            return response;
        } catch (err) {
            console.log("Failed to download the NFe xml batch file.", err);
            return null;
        }
    }

    downloadDanfe = async (nfe: NFe) => {
        try {
            const response = await api.get(`/nfe/${nfe.id}/danfe`, { responseType: 'blob' });

            return response;
        } catch (err) {
            console.log("Failed to download NFe. ", err);
            return null;
        }
    }

    downloadDanfeBatch = async (ids: number[]) => {
        try {
            const response = await api.get(`/nfe/danfe/batch`, {
                params: { ids: ids.join(',') },
                responseType: 'blob'
            });

            return response;
        } catch (err) {
            console.log("Failed to download the DANFe batch file.", err);
            return null;
        }
    }

    downloadDanfeMerged = async (ids: number[]) => {
        try {
            const response = await api.get(`/nfe/danfe/merged`, {
                params: { ids: ids.join(',') },
                responseType: 'blob'
            });

            return response;
        } catch (err) {
            console.log("Failed to download the DANFe merged file.", err);
            return null;
        }
    }

    cancelNFe = async ({ id }: NFe, justification: string): Promise<any> => {
        try {
            return api.post('/nfe/cancel', { id, justification });
        } catch (err) {
            throw new Error("Ocorreu um erro ao tentar cancelar a NFe.");
        }
    }

    amendNFe = async ({ id }: NFe, amendment: string): Promise<any> => {
        try {
            return api.post('/nfe/amend', { id, amendment });
        } catch (err) {
            throw new Error("Ocorreu um erro ao tentar enviar a carta de correção.");
        }
    }

    fetchNFeHistory = async (nfeId: Number): Promise<any> => {
        try {
            return api.get(`/nfe/${nfeId}/history`);
        } catch (err) {
            throw new Error("Ocorreu um erro ao buscar o histórico da NFe.");
        }
    }

    fetchFilteredNFes = (filters: NFeFilter, pageIndex: Number, pageSize: Number, orderBy: string, orderDirection: "ASC" | "DESC"): Promise<PageResponse<NFe>> => {
        try {
            const requestData = {
                status: filters.status,
                initialDate: filters.initialDateObj ? format("dd/MM/yyyy", filters.initialDateObj) : null,
                finalDate: filters.finalDateObj ? format("dd/MM/yyyy", filters.finalDateObj) : null,
                minValue: filters.vlrMinNf,
                maxValue: filters.vlrMaxNf,
                docDestinatario: filters.docDestinatario,
                serie: filters.serie,
                numDoc: filters.numDoc,
                accessKeys: filters.chaveNf,
                branchesIds: filters.branchesIds,
                companyId: filters.companyId,
                transactionsIds: filters.ids,
                page: pageIndex,
                size: pageSize,
                sort: `${orderBy},${orderDirection}`,
                inutilizada: filters.inutilizada
            }

            return api.get('/nfe', { params: requestData }).then(({ data }) => data);
        } catch (err) {
            throw new Error("Ocorreu um erro ao tentar buscar a listagem de NFe.");
        }
    };

    fetchConfig = async (rootId: number | undefined, branchId: number): Promise<any> => {
        const requestData = {
            rootId,
            branchId
        }
        try {
            return api.get('/nfe/config', { params: requestData } );
        } catch (err) {
            throw new Error("Ocorreu um erro ao tentar buscar as configurações.");
        }
    }

    saveConfig = async (payload : any): Promise<any> => {
        try {
            return api.post('/nfe/config', payload);
        } catch (err) {
            throw new Error("Ocorreu um erro ao tentar salvar as configurações.");
        }
    }

    canSync = async (rootId: number | undefined): Promise<any> => {
        const requestData = {
            rootId
        }
        try {
            return api.get('/nfe/config/canSync', { params: requestData } );
        } catch (err) {
            throw new Error("Ocorreu um erro ao tentar validar o sync.");
        }
    }

    fetchNFeRecipientInfo = async (transactionId?: string): Promise<any> => {
        try {
            const requestData = {
                transactionId
            }
            return api.get(`/nfe/recipient-info`, { params: requestData });
        } catch (err) {
            throw new Error("Ocorreu um erro ao buscar o destinatário da NFe.");
        }
    }

    reissueNFe = async (id: number | undefined, payload: any): Promise<any> => {
        try {
            return api.put(`/nfe/${id}/reissue`, payload);
        } catch (err) {
            throw new Error("Ocorreu um erro ao tentar enviar a NFe para reemissão.");
        }
    }

    inutilizeBatch = async (ids: number[]): Promise<any> => {
        try {
          return api.post('/nfe/skip-numbers', ids).then(({ data }) => data);
        } catch (err) {
          throw new Error("Ocorreu um erro inutilizar a numeração em massa.");
        }
    }

}