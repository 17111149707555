import { CircularProgress, ClickAwayListener } from "@mui/material";
import { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";

const StyledFrame = styled.section`
    height: 100vh;
    width: calc(100% + 28px);

    position: absolute;
    z-index: 999;

    border-bottom-left-radius: 22px;
    border-top-left-radius: 22px;

    display: grid;
    place-items: center;

    background-color: #00000092;

    .modal-content {
        min-width: 350px;
        width: ${(props: { width?: string }) => props.width || 'fit-content'};
        max-width: 85%;

        height: fit-content;
        background-color: var(--white);
        border-radius: 8px;

        -webkit-box-shadow: -3px 3px 8px 1px #20202084;
        -moz-box-shadow: -3px 3px 8px 1px #20202084;
        box-shadow: -3px 3px 8px 1px #20202084;
    }

    header {
        width: calc(100% - 24px);
        height: 40px;

        display: flex;

        padding: 6px 12px;

        align-items: center;

        border-bottom: 1px solid var(--grey-light);

        span {
            font-weight: bold;
            font-size: 1rem;
            margin-top: 0;
        }

        i {
            padding: 6px;
            font-size: 1.2rem;
            color: var(--grey-regular);
            cursor: pointer;
            transition: color .15s ease-in;

            :hover {
                color: var(--grey-dark);
            }
        }

        .header-actions-container {
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                border: none;
                background: transparent;
                margin: 0;
                padding: 0;
            }
        }
    }

    .modal-body {
        padding: 20px;
        max-height: 80vh;
        overflow-y: auto;
    }

    footer {
        display: flex;
        margin: 12px;
        justify-content: space-around;
    }

    .loader {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100px;
    }
`;

interface ModalProps {
    headerTitle: string,
    loading: boolean,
    handleClose: Function,
    closeOnClickAway: boolean,
    width?: string,
    actionButtons?: {
        text: string,
        actionCallback: Function,
        positiveAction?: boolean,
        icon?: ReactNode | HTMLElement,
    }[],
    headerActions?: {
        icon?: ReactNode | HTMLElement,
        actionCallback: Function
    }[]
}

const Modal: FunctionComponent<ModalProps> = (props) => {
    const handleClickAway = () => {
        props.handleClose();
    }

    const handleClick = (action: any, event: any) => {
        action.actionCallback(event);
        event.preventDefault();
        event.stopPropagation();
    }

    return <StyledFrame width={props.width}>
        <ClickAwayListener onClickAway={props.closeOnClickAway === true ? handleClickAway : () => { }}>
            <div className="modal-content">
                <header>
                    <span>{props.headerTitle}</span>

                    <div className="header-actions-container">
                        {props.headerActions && props.headerActions.map((action, index) => (
                            <button key={index} onClick={() => action.actionCallback()}>{action.icon}</button>
                        ))}
                        <i className="bi bi-x" title="Fechar" onClick={() => props.handleClose()} />
                    </div>
                </header>

                {props.loading ? <div className="loader"><CircularProgress style={{ 'color': '#19ea81' }} /></div> : <>
                    <div className="modal-body">{props.children}</div>
                    {
                        props.actionButtons ? <footer>
                            {
                                props.actionButtons.map(action => <div style={{ display: 'flex', zIndex: 1001 }} key={action.text}>
                                    <button className={`icon-button ${!action.positiveAction ? 'negative-action-btn' : ''}`}
                                        style={{ alignItems: 'center' }}
                                        onClick={(event) => { handleClick(action, event) }}>
                                        {action.icon}
                                        {action.text}
                                    </button>
                                </div>)
                            }
                        </footer> : ''
                    }
                </>}
            </div>
        </ClickAwayListener>
    </StyledFrame>;
}

export default Modal;