import { CircularProgress, Popover, Tooltip } from "@mui/material";
import NFeListingApi from "api/NFeListingApi";
import { formatToBRL, formatToCPFOrCNPJ } from "brazilian-values";
import Filter from "components/filter/filter";
import Modal from "components/modal/modal";
import Table, { Column, OrderInfo, TableAction } from "components/table/table";
import { NFeFilter } from "model/NFeFilter";
import { nfeFilterStatus } from "model/NFeFilterStatus";
import { nfeStatus } from "model/NFeStatus";
import { NFe } from "model/NFe";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useRef } from "react";
import PageFrame from "components/page-frame/pageFrame";
import { getItem, setItem } from '@services/LocalStorageService';
import PDFViewer from "@components/pdf/pdfViewer";
import HistoryDetails from "@components/historyDetails/historyDetails";
import fileDownload from "js-file-download";
import { useAuth } from '@contexts/auth';
import { PERMISSIONS } from "@utils/permissions";
import PickList, { PickListItem } from "components/picklist/picklist";

const StyledFrame = styled.div`
    width: 100%;
    height: 100%;

    position: relative;

    display: flex;
    flex-direction: column;

    .edit-border {
        border-bottom: 1px solid var(--grey-light);
    }

    .centered {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .body {
        width: 100%;
        height: 100%;
        max-height: 100%;

        display: flex;
        flex-direction: column;
        position: relative;

        .customize-columns-toggle {
            height: 32px;
            width: fit-content;
            
            padding: 0 8px;

            margin-left: auto;
            
            border: 1px solid var(--grey-light);
            border-radius: 8px;
            
            display: flex; 
            align-items: center; 
            justify-content: center;
            
            user-select: none;
            font-family: 'TitilliumWeb';
            
            cursor: pointer;

            background-color: var(--white);

            :active {
                background-color: var(--grey-lightest);
            }

            :focus-visible {
                outline: none;
            }

            > span {
                text-transform: uppercase;
                font-size: .7rem;
                font-weight: bold;
                letter-spacing: .3px;
            }
        }
    }
    
    .correcao-frame {
        display: flex;
        flex-direction: column;

        > span {
            margin-bottom: 12px;
        }

        > textarea {
            min-width: 550px;
            min-height: 70px;
        }
    }

    .confirm-edit-frame {
        display: flex;
        flex-direction: column;
        text-align: center;

        > span {
            margin-bottom: 12px;
        }
    }

    .edit-frame {
        display: flex;
        flex-direction: column;

        #picklist {
            line-height: 25px;
            min-width: 250px;

            .overlay {
                .header-section {
                    > span {
                        font-family: 'JetBrains Regular' !important;
                        text-transform: uppercase;
                    }
                }
            }
            .overlay.is-opened {
                height: 160px;
                z-index: 2000;
            }
            .body-section.is-opened {
                height: 110px;
            }
        }

        > span {
            margin-bottom: 12px;
        }

        .input-label {
            color: var(--grey-regular);
            font-weight: bold;
        }

        .header {
            font-size: 1rem
        }
    }

    .hist-item-frame {
        height: fit-content;
        display: flex;
        align-items: top;

        .data-text {
            white-space: nowrap;
            margin-right: 16px;
        } 
        
        .status-indicator {
            height: 6px;
            width: 6px;
            border-radius: 50%;
            margin-top: 6px;
            margin-left: 8px;
            margin-right: 8px;
            margin-bottom: 6px;
            border: 3px solid var(--color-success);
        }

        .status-link-bar {
            width: 3px;
            min-height: 100%;
            background-color: var(--color-success);
            border-radius: 6px;
            margin-top: 7px;
            margin-left: 12px;
            margin-right: 12px;
        }
    }

    .div-table-cell {
        display: table-cell;
        vertical-align: top;
        padding-left: 10px;
        padding-bottom: 10px;
        padding-top: 15px;
    }

    .line-separator {
        display: table-cell;
        border-left: none;
        border-top: var(--grey-light);
    }
`;

const ModalFrame = styled.div`
        top: 6px;
        left: -14px;
        width: 100%;
        position: absolute;
        transform: translateY(-96px);
        z-index: 1000;
`;

const EditModalFrame = styled.div`
        top: 6px;
        left: -14px;
        width: 100%;
        position: absolute;
        transform: translateY(-96px);
        z-index: 1000;

        .modal-body {
            padding: 15px;
            overflow-y: visible;
        }
`;

const PAGE_SIZE = 30;

interface PageAndSort {
    sort: OrderInfo;
    page: number;
}

const ieTypes: PickListItem[] = [
    {
        id: 1,
        value: "Contribuinte ICMS",
        codigo: "Contribuinte ICMS"
    },
    {
        id: 2,
        value: "Contribuinte Isento",
        codigo: "Contribuinte Isento"
    },
    {
        id: 9,
        value: "Não Contribuinte",
        codigo: "Não Contribuinte"
    }
]

const ufList: PickListItem[] = [
    { id: 1, value: "AC", codigo: "AC" },
    { id: 2, value: "AL", codigo: "AL" },
    { id: 3, value: "AM", codigo: "AM" },
    { id: 4, value: "AP", codigo: "AP" },
    { id: 5, value: "BA", codigo: "BA" },
    { id: 6, value: "CE", codigo: "CE" },
    { id: 7, value: "DF", codigo: "DF" },
    { id: 8, value: "ES", codigo: "ES" },
    { id: 9, value: "GO", codigo: "GO" },
    { id: 10, value: "MA", codigo: "MA" },
    { id: 11, value: "MG", codigo: "MG" },
    { id: 12, value: "MS", codigo: "MS" },
    { id: 13, value: "MT", codigo: "MT" },
    { id: 14, value: "PA", codigo: "PA" },
    { id: 15, value: "PB", codigo: "PB" },
    { id: 16, value: "PE", codigo: "PE" },
    { id: 17, value: "PI", codigo: "PI" },
    { id: 18, value: "PR", codigo: "PR" },
    { id: 19, value: "RJ", codigo: "RJ" },
    { id: 20, value: "RN", codigo: "RN" },
    { id: 21, value: "RO", codigo: "RO" },
    { id: 22, value: "RS", codigo: "RS" },
    { id: 23, value: "SE", codigo: "SE" },
    { id: 24, value: "SC", codigo: "SC" },
    { id: 25, value: "RR", codigo: "RR" },
    { id: 26, value: "SP", codigo: "SP" },
    { id: 27, value: "TO", codigo: "TO" },
    { id: 28, value: "EX", codigo: "EX" }
]

const getFilterStatus = (statusValue: string) => {
    const auxStatus = nfeFilterStatus.filter(s => s.value?.toUpperCase() === statusValue.toUpperCase())[0];
    if (auxStatus) {
        return auxStatus;
    }
}

const getStatusColor = (statusValue: string) => {
    const auxStatus = nfeStatus.filter(s => s.value?.toUpperCase() === statusValue.toUpperCase())[0];
    if (auxStatus) {
        return auxStatus.refColor;
    } else {
        if (statusValue === "ERRO_SEFAZ") {
            return nfeStatus.find(st => st.value === "REJEITADO_SEFAZ")?.refColor;
        }
    }
}

/**
    * Builds the status layout for each individual NFe item on the list with the apropriate color
    * @param nfeItem represents the NFe being render on screen
    * @returns a custom JSX element for a NFe's status
    */
const getStatusLayout = (nfeItem: NFe) => {

    if (nfeItem.reportStatus) {

        let status = getFilterStatus(nfeItem.reportStatus);

        return <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{
                borderRadius: '50%',
                width: '6px',
                height: '6px',
                marginRight: '6px',
                backgroundColor: status?.refColor
            }}></div>
            <span>{status?.label}</span>
        </div>;
    }

    return <div>Status Error: {nfeItem.status}</div>;
}

const ListPage = () => {

    /**
         * @param nfeItem NFe to get the recipient's name from
         * @returns A custom view including a tooltip for recipients names in the list
         */
    const recipientNameToolTip = (nfeItem: NFe) => <Tooltip title={nfeItem.recipientName || "N/A"} placement="top">
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{nfeItem.recipientName}</div>
    </Tooltip>

    const [columns, setColumns] = useState<Column[]>([
        { header: 'CNPJ Emitente', accessor: 'company.cnpj', isActive: true, minWidth: 125, customView: (value: any) => value.issuerCnpj },
        { header: 'ID Transação', accessor: 'transactionId', isActive: false, minWidth: 210 },
        { header: 'Data de emissão', accessor: 'issueDate', isActive: true, minWidth: 130 },
        { header: 'Nº Documento', accessor: 'docNum', isActive: true, minWidth: 80 },
        { header: 'Série', accessor: 'series', isActive: true, minWidth: 35 },
        { header: 'Valor', accessor: 'nfAmount', isActive: true, minWidth: 85 },
        { header: 'Status', accessor: 'reportStatus', orderAccessor: "status", isActive: true, minWidth: 140, customView: getStatusLayout },
        { header: 'Inutilizada', accessor: 'inutialized', isActive: true, minWidth: 45 },
        { header: 'Chave de Acesso', accessor: 'accessKey', orderAccessor: "accessKey", isActive: false, minWidth: 340 },
        { header: 'Nome Destinatário', accessor: 'recipientName', isActive: true, minWidth: 160, customView: recipientNameToolTip },
        { header: 'Doc. Destinatário', accessor: 'recipientTaxId', isActive: true, minWidth: 125 },
    ]);

    const listingApi = new NFeListingApi();

    const initialRender = useRef(true);

    const [ieType, setIeType] = useState<PickListItem[]>([]);

    const [selectedUf, setUf] = useState<PickListItem[]>([]);

    const [hasMore, setHasMore] = useState(true);

    const [filters, setFilters] = useState(new NFeFilter());

    const [data, setData] = useState<NFe[]>([]);

    const [pageAndSort, setPageAndSort] = useState<PageAndSort>({ sort: { columnOrdered: columns[2], direction: "DESC" }, page: 0 })

    const [totalAmountNFes, setTotalAmountNFes] = useState(0);

    const [selectedNFes, setSelectedNFes] = useState<NFe[]>([]);

    const [activeNFe, setActiveNFe] = useState<NFe>();

    const [downloadOptionsOpen, setDownloadOptionsOpen] = useState(false);

    const [visualizarDanfeOpen, setVisualizarDanfeOpen] = useState(false);

    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

    const [correctionDialogOpen, setCorrectionDialogOpen] = useState(false);

    const [nfeHistoryDialogOpen, setNfeHistoryDialogOpen] = useState(false);

    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editConfirmDialogOpen, setEditConfirmDialogOpen] = useState(false);
    const [skipNumbersConfirmDialogOpen, setskipNumbersConfirmDialogOpen] = useState(false);

    const [loadingCartaCorrecao, setLoadingCartaCorrecao] = useState<boolean>(false);
    const [loadingDownload] = useState<boolean>(false);
    const [loadingCancel, setLoadingCancel] = useState<boolean>(false);
    const [loadingHistory, setLoadingHistory] = useState<boolean>(false);
    const [exportingBils, setExportingBills] = useState<boolean>(false);
    const [downloadingXml, setDownloadingXml] = useState<boolean>(false);
    const [loadingEdit, setLoadingEdit] = useState<boolean>(false);

    const { authInfo } = useAuth();
    const [pdf, setPdf] = useState<any>();
    const [pdfFilename, setPdfFilename] = useState<any>();

    const ccRef = useRef<HTMLTextAreaElement>(null);

    const justificationRef = useRef(null);

    const nfeActiveColumn: string = `nfe-columns-active-${authInfo?.username}`

    const validDownloadStatuses: string[] = ['AUTORIZADO', 'CANCELADO', 'AUTORIZADO_EPEC', 'ERRO_ENVIO', 'ERRO_SEFAZ', 'REJEITADO_SEFAZ'];

    const actions = useMemo<TableAction[]>(() => [
        {
            html: <i className="bi bi-download table-action action-positive"></i>,
            callback: (event: Event, nfe: NFe) => { return handleDownloadClick(event, nfe); },
            disabled: (actionTooltipText: string, statusLabel: string) => disableAction(actionTooltipText, statusLabel),
            tooltipText: 'Baixar arquivos',
            requiredPermissions: [PERMISSIONS.DOOTAX.NFE.GERENCIAR.VISUALIZAR]
        },
        {
            html: <i className="bi bi-clock-history table-action action-positive" />,
            callback: (event: Event, nfe: NFe) => handleShowNFeHistory(event, nfe),
            disabled: (actionTooltipText: string, statusLabel: string) => disableAction(actionTooltipText, statusLabel),
            tooltipText: 'Visualizar histórico',
            requiredPermissions: [PERMISSIONS.DOOTAX.NFE.GERENCIAR.VISUALIZAR]
        },
        {
            html: <i className="bi bi-x-lg table-action action-negative" />,
            callback: (event: Event, nfe: NFe) => handleShowCancelDialog(event, nfe),
            disabled: (actionTooltipText: string, statusLabel: string) => disableAction(actionTooltipText, statusLabel),
            tooltipText: 'Cancelar nota',
            requiredPermissions: [PERMISSIONS.DOOTAX.NFE.GERENCIAR.CANCELAR]
        },
        {
            html: <i className="bi bi-card-text table-action action-positive" />,
            callback: (event: Event, nfe: NFe) => handleShowNfeAmendForm(event, nfe),
            disabled: (actionTooltipText: string, statusLabel: string) => disableAction(actionTooltipText, statusLabel),
            tooltipText: 'Carta de correção',
            requiredPermissions: [PERMISSIONS.DOOTAX.NFE.GERENCIAR.EMITIR_CARTA_CORRECAO]
        },
        {
            html: <i className="bi bi-pencil table-action action-positive" />,
            callback: (event: Event, nfe: NFe) => handleShowNfeEditForm(event, nfe),
            disabled: (actionTooltipText: string, statusLabel: string) => disableAction(actionTooltipText, statusLabel),
            tooltipText: 'Editar',
            requiredPermissions: [PERMISSIONS.DOOTAX.NFE.GERENCIAR.EMITIR_CARTA_CORRECAO]
        },
    ], []);

    useEffect(() => {
        const savedColumnsConfig = getItem(nfeActiveColumn);
        if (savedColumnsConfig) {
            setColumns(columns.map(col => ({ ...col, isActive: savedColumnsConfig.includes(col.accessor) })));
        } else {
            setItem(nfeActiveColumn, columns.map(col => col.accessor).join(','));
        }
    }, []);

    const setFiltersAndResetPageNum = (updatedFilter: NFeFilter) => {
        setFilters(updatedFilter);
        setPageAndSort({ ...pageAndSort, page: 0 });
    }


    const validateEditNFe = () => {
        const recipient = activeNFe?.recipient;
        if (ieType.length == 0) {
            toast.warn("Indicador de IE deve ser informado");
            return;
        }
        if (recipient) {
            if (!recipient.email) {
                toast.warn("Email deve ser informado");
                return;
            }
            if (ieType[0].id == 1 && !recipient.ie) {
                toast.warn("IE deve ser informado");
                return;
            }
            if (!recipient.taxId) {
                toast.warn("CNPJ deve ser informado");
                return;
            }
            if (!recipient.name) {
                toast.warn("Nome/Razão Social deve ser informado");
                return;
            }
        } else {
            toast.warn("Destinatário deve ser informado");
            return;
        }

        setEditConfirmDialogOpen(true);

    }

    const reissueNFe = () => {
        const recipient = activeNFe?.recipient;
        if (!recipient) {
            toast.warn("Destinatário deve ser informado");
            return;
        }

        const requestPayload = {
            recipientTaxId: recipient.taxId,
            recipientTaxIdType: recipient.taxIdType,
            recipientName: recipient.name,
            recipientIeType: ieType[0].id,
            recipientEmail: recipient.email,
            recipientIe: recipient.ie || null,
            recipientAddress: recipient.address
        }

        listingApi.reissueNFe(activeNFe?.id, requestPayload).then(() => {
            toast.success("NFe enviada para a reemissão com sucesso!");
            setEditDialogOpen(false);
            handleFilter();
        }).finally(() => setEditConfirmDialogOpen(false))
    }

    const executeSearch = ({ concatResults }: { concatResults: boolean }) => {

        const { sort: sortInfo, page: pageIndex } = pageAndSort;
        listingApi.fetchFilteredNFes(filters, pageIndex, PAGE_SIZE, sortInfo.columnOrdered.orderAccessor || sortInfo.columnOrdered.accessor, sortInfo.direction).then(({ content, totalElements, last }) => {
            setTotalAmountNFes(totalElements);
            setHasMore(!last);
            
            const newData = content.map(nfe => ({
                ...nfe,
                companyId: (nfe as any).company.id,
                issuerCnpj: formatToCPFOrCNPJ((nfe as any).company.cnpj),
                recipientTaxId: formatToCPFOrCNPJ(nfe.recipientTaxId || ''),
                nfAmount: formatToBRL(nfe.nfAmount || '0'),
                isSelected: false,
                disableSelection: !validDownloadStatuses.includes(nfe.status!),
                inutialized: Boolean(nfe.inutialized) ? "Sim" : "Não"
            }));

            setData(concatResults ? [...data, ...newData] : newData);
        });
    }

    const handleFilter = useCallback(() => executeSearch({ concatResults: false }), [filters, pageAndSort]);

    useEffect(() => {

        if (initialRender.current) {
            initialRender.current = false;
        } else {
            executeSearch({ concatResults: pageAndSort.page !== 0 });
        }
    }, [pageAndSort]);

    useEffect(() => {
        selectedNFes.length && setData(data.map(item => {
            return {
                ...item,
                isSelected: (selectedNFes.find(i => (i as any).id === item.id) !== undefined)
            }
        }));
    }, [selectedNFes]);

    /**
     * Checks if an action should be disabled for a specific NFe based on it's status
     * @param tooltipText
     * @param statusLabel
     * @returns true if the action should be enabled for a specific NFe
     */
    const disableAction = (tooltipText: string, statusLabel: string) => {
        const statusLower = statusLabel.toLowerCase();
        switch (tooltipText.toLowerCase()) {
            case "cancelar nota":
                return statusLower !== "autorizado";

            case "baixar arquivos":
                return statusLower !== "autorizado" && statusLower !== "cancelado";

            case "carta de correção":
                return statusLower !== "autorizado";

            case "editar":
                return statusLower !== "erro sefaz" && statusLower !== "rejeitado sefaz" && statusLower !== "erro no envio"

            default:
                return false;
        }
    }

    const handleExportNFes = () => {
        setExportingBills(true);

        listingApi.exportNFes(filters)
            .then(() => toast.success('NFe\'s exportadas com sucesso!'))
            .finally(() => setExportingBills(false));
    }

    const handleDownloadClick = (event: Event, nfe: NFe) => {
        setActiveNFe(nfe);
        setDownloadOptionsOpen(true);
        event.stopPropagation();
    }

    const handlePrint = (pdfBytes: Blob) => {
        var pdfUrl = URL.createObjectURL(pdfBytes);
        var printwWindow = window.open(pdfUrl);
        printwWindow!.print();
    }

    const handleVisualizarDanfeClick = (nfe: NFe) => {
        setActiveNFe(nfe);

        listingApi.downloadDanfe(nfe).then(res => {
            if (res) {
                setPdf(res.data);
                setPdfFilename(res.headers.filename);
            } else {
                setPdf(undefined);
                setPdfFilename(undefined);
            }
        });

        setVisualizarDanfeOpen(true);
        setDownloadOptionsOpen(false);
    }

    const handleShowNFeHistory = (event: Event, nfe: NFe) => {
        if (!nfe || !nfe.id) {
            toast.error('É necessário selecionar uma NFe para visualizar seu histórico.');
            return;
        }

        setNfeHistoryDialogOpen(true);
        setLoadingHistory(true);

        listingApi.fetchNFeHistory(nfe.id)
            .then(res => {
                setLoadingHistory(false);
                setActiveNFe({ ...nfe, history: res.data });
            }).catch(() => setLoadingHistory(false));

        event.stopPropagation();
    }

    const handleShowCancelDialog = (event: Event, nfe: NFe) => {
        setActiveNFe(nfe);
        nfe && setCancelDialogOpen(true);
        event.stopPropagation();
    }

    const handleShowNfeAmendForm = (event: Event, nfe: NFe) => {
        setActiveNFe(nfe);
        nfe && setCorrectionDialogOpen(true);
        event.stopPropagation();
    }

    const handleShowNfeEditForm = (event: Event, nfe: NFe) => {
        if (!nfe || !nfe.id) {
            toast.error('É necessário selecionar uma NFe para editar.');
            return;
        }

        setEditDialogOpen(true);
        setLoadingEdit(true);

        listingApi.fetchNFeRecipientInfo(nfe.transactionId).then(recipient => {
            setActiveNFe({ ...nfe, recipient: recipient.data });
            setIeType(ieTypes.filter(value => value.id == recipient.data.ieType));
            setUf(ufList.filter(uf => uf.value == recipient.data.address.uf));
            event.stopPropagation();
        }).catch(() => setEditDialogOpen(false))
            .finally(() => setLoadingEdit(false));

    }

    const onSelectedIeType = (type: PickListItem[]) => {
        setIeType(type);
        if(activeNFe && type[0].id > 1) {
            setActiveNFe({...activeNFe, recipient: {...activeNFe.recipient, ie: ''}})
        }
    }

    const onSelectedUf = (uf: PickListItem[]) => {
        setUf(uf);
        if(activeNFe && activeNFe.recipient && activeNFe.recipient.address) {
            setActiveNFe({...activeNFe, recipient: {...activeNFe.recipient, address: {...activeNFe.recipient?.address, uf: uf[0].value }}})
        }
    }

    /**
     * Amends a "correction letter" to a specified NFe
     * @param nfe NFe to be updated
     */
    const handleAmendNfe = (nfe: NFe) => {
        const textValue = (ccRef.current as any).value as string;

        if (!textValue || textValue.length < 15 || textValue.length > 1000) {
            toast.warning(`A carta de correção deve conter entre 15 e 1000 caracteres. Quantidade atual: ${textValue.length}`);
            return;
        }

        setLoadingCartaCorrecao(true);

        listingApi.amendNFe(nfe, textValue)
            .then(() => {
                setLoadingCartaCorrecao(false);
                toast.success("Carta de correção enviada com sucesso!");
                setCorrectionDialogOpen(false);
                handleFilter();
            })
            .catch(() => {
                setLoadingCartaCorrecao(false);
                if (ccRef && ccRef.current) {
                    ccRef.current.value = textValue;
                }
            });
    }

    const handleCancelNfe = (nfe: NFe) => {
        const textValue = (justificationRef.current as any).value as string;

        if (!textValue || textValue.length < 15 || textValue.length > 255) {
            toast.warning(`A justificativa deve conter entre 15 e 255 caracteres. Quantidade atual: ${textValue.length}`, { autoClose: 3500 });
            return;
        }

        setLoadingCancel(true);

        listingApi.cancelNFe(nfe, textValue)
            .then(() => {
                setLoadingCancel(false);
                toast.success("NFe cancelada com sucesso!");
                setCancelDialogOpen(false);
                handleFilter()
            }).catch(() => {
                setLoadingCancel(false);
                if (ccRef && ccRef.current) {
                    ccRef.current.value = textValue;
                }
            });
    }

    const handleToggleColumn = (column: Column) => {
        const savedColumnsConfig = getItem(nfeActiveColumn)?.split(',');

        if (!savedColumnsConfig || !savedColumnsConfig.length) {
            setItem(nfeActiveColumn, column.accessor);
        } else {
            if (savedColumnsConfig.includes(column.accessor)) {
                savedColumnsConfig.splice(savedColumnsConfig.indexOf(column.accessor), 1);
            } else {
                savedColumnsConfig.unshift(column.accessor);
            }

            setItem(nfeActiveColumn, savedColumnsConfig ? savedColumnsConfig.join(',') : '');
        }
    }

    /**
     * Shows/hides a table column selected by the user
     * @param column selected/unselected column
     */
    const shouldActivateColumn = (column: Column) => {
        setColumns(columns.map(col => {
            if (column.accessor === col.accessor) {
                col.isActive = !column.isActive
                handleToggleColumn(col);
            }
            return { ...col };
        }))
    }

    const [actionsEl, setActionsEl] = useState<HTMLButtonElement | null>(null);

    const handleActionsBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setActionsEl(event.currentTarget);
    };

    const handleClose = () => {
        setActionsEl(null);
    };

    const open = Boolean(actionsEl);
    const id = open ? 'simple-popover' : undefined;

    const customizeColumnsBtn = <>
        <button
            className="customize-columns-toggle"
            aria-describedby={id}
            onClick={(event) => handleActionsBtnClick(event)}>
            <span>Personalizar colunas</span>
            <i className="bi bi-caret-down-fill" style={{ fontSize: '.75rem', marginLeft: '8px' }} />
        </button>
        <Popover
            id={id}
            open={open}
            anchorEl={actionsEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}>
            <div className='customize-columns-menu'>
                <div className="menu-header">
                    <span>Selecione as colunas que deseja exibir na lista:</span>
                </div>
                <div className="menu-items-frame">
                    {
                        columns.slice().sort((a, b) => {
                            if (a.header === b.header) return 0;
                            if (a.header > b.header) return 1;
                            if (a.header < b.header) return -1;
                            return 0;
                        }).map((col, index) => {
                            return <div key={index} style={{ width: 'fit-content', display: 'flex', padding: '10px', alignItems: 'center' }}>
                                <input type="checkbox"
                                    checked={col.isActive}
                                    onChange={() => shouldActivateColumn(col)}
                                />
                                <span>{col.header}</span>
                            </div>
                        })
                    }
                </div>
            </div>
        </Popover>
    </>;

    const handleSortByColumn = (col: Column) => {
        const { sort: sortInfo } = pageAndSort;

        setPageAndSort({
            page: 0,
            sort: {
                columnOrdered: col,
                direction: (col.accessor !== sortInfo.columnOrdered.accessor) || sortInfo.direction === "DESC" ? "ASC" : "DESC"
            }
        });
    }

    const handleDownloadXml = (nfe: NFe) => {
        if (!nfe) {
            toast.error('É necessário selecionar uma nota para realizar o download do XML.');
            return;
        }

        setDownloadingXml(true);

        listingApi.downloadXml(nfe).then(res => {
            toast.info(res)

            if (res) {
                if (res.status === 204) {
                    toast.info("Não foi encontrado nenhum xml para esta NFe.");
                }
            }
        }).finally(() => setDownloadingXml(false));
    }

    const handleSkipNumbersConfirm = (ids: number[]) => {
        if (!ids || !ids.length) {
            toast.warning('É necessário selecionar uma nota para realizar a inutilização.');
            return;
        }
        setskipNumbersConfirmDialogOpen(true);
    }

    const handleSkipNumbersBatch = () => {
        const ids = selectedNFes.filter(nfe => !!nfe.id).map(nfe => nfe.id) as number[];

        setskipNumbersConfirmDialogOpen(false);

        const loading = toast.loading('Processando inutilização em massa das notas.');
        listingApi.inutilizeBatch(ids).then(res => {
            toast.success('Inutilização em massa realizada com sucesso.');
            handleFilter();
        }).catch(error => {
            handleFilter();
        }).finally(() => {
            toast.dismiss(loading);
            setSelectedNFes([]);
        });
    }

    const handleDownloadXmlBatch = (ids: number[]) => {
        if (!ids || !ids.length) {
            toast.warning('É necessário selecionar uma nota para realizar o download do XML.');
            return;
        }

        listingApi.downloadXmlBatch(ids).then(res => {
            if (!res || !res.data || res.data.size === 0 || res.status !== 200) {
                toast.info("Não foi encontrado nenhum XML válido para as notas selecionadas.");
            } else {
                fileDownload(res.data, res.headers.filename)
            }
        });
    }

    const handleDownloadDanfeBatch = (ids: number[]) => {
        if (!ids || !ids.length) {
            toast.warning('É necessário selecionar uma nota para realizar o download do DANFe.');
            return;
        }

        listingApi.downloadDanfeBatch(ids).then(res => {
            if (!res || !res.data || res.data.size === 0 || res.status !== 200) {
                toast.info("Não foi possível gerar nenhum DANFe para as notas selecionadas.");
            } else {
                fileDownload(res.data, res.headers.filename)
            }
        });
    }

    const handleDownloadDanfeMerged = (ids: number[]) => {
        if (!ids || !ids.length) {
            toast.warning('É necessário selecionar uma nota para realizar a impressão do DANFe.');
            return;
        }

        listingApi.downloadDanfeMerged(ids).then(res => {
            if (!res || !res.data || res.data.size === 0 || res.status !== 200) {
                toast.info("Não foi possível gerar nenhum DANFe para as notas selecionadas.");
            } else {
                handlePrint(res?.data);
            }
        });
    }

    // ----------------------------
    // RETURN
    // ----------------------------
    return <PageFrame><StyledFrame>
        <section className="body">
            <div style={{ width: '100%', display: "flex", marginBottom: '12px' }}>
                <Filter model={filters} onFiltersChange={(updatedFilter: NFeFilter) => setFiltersAndResetPageNum(updatedFilter)} reset={() => setFiltersAndResetPageNum(new NFeFilter())} />
                <button
                    className="customize-columns-toggle"
                    onClick={() => setPageAndSort({ ...pageAndSort, page: 0 })}
                    style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
                    <i className="bi bi-arrow-clockwise" />
                </button>

                <button disabled={exportingBils} className="icon-button action-button centered" style={{ marginLeft: '20px', width: '188px' }} onClick={() => handleExportNFes()}>
                    {exportingBils ? (
                        <CircularProgress size={14} style={{ 'color': '#FFFFFF' }} />
                    ) : (
                        <>
                            <i className="bi bi-file-earmark-spreadsheet"></i>
                            <span>Exportar notas</span>
                        </>
                    )}
                </button>
            </div>
            <div style={{ display: 'flex', marginLeft: '6px', alignItems: 'center' }}>
                <span><b style={{ marginLeft: '4px', marginRight: '4px' }}>{totalAmountNFes}</b> notas encontradas.</span>
                <div style={{ height: '25px', width: '1px', backgroundColor: 'var(--grey-light)', margin: '0 8px', opacity: '.5' }}></div>
                <span><b style={{ marginLeft: '4px', marginRight: '4px' }}>{selectedNFes.length}</b> notas selecionadas.</span>
                {customizeColumnsBtn}
            </div>
            <Table data={data}
                columns={columns}
                orderInfo={pageAndSort.sort}
                onSortByColumn={(col: Column) => handleSortByColumn(col)}
                actionsConfig={{ actions: actions, minWidth: 130 }}
                disableSelection={false}
                onSelectionChange={(nfes: any) => { setSelectedNFes(nfes.filter((nf: any) => nf.isSelected)) }}
                handleFetchMore={() => setPageAndSort({ ...pageAndSort, page: (pageAndSort.page + 1) })}
                hasMore={hasMore}
                batchActions={[
                    {
                        label: 'Baixar XML',
                        actionCallback: handleDownloadXmlBatch,
                        isDisabled: selectedNFes.length === 0
                    },
                    {
                        label: 'Baixar DANFe',
                        actionCallback: handleDownloadDanfeBatch,
                        isDisabled: selectedNFes.length === 0
                    },
                    {
                        label: 'Imprimir DANFe',
                        actionCallback: handleDownloadDanfeMerged,
                        isDisabled: selectedNFes.length === 0
                    },
                    {
                        label: 'Inutilizar Rejeitadas',
                        actionCallback: handleSkipNumbersConfirm,
                        isDisabled: selectedNFes.length === 0
                    }
                ]}
            />
        </section>

        {/* DIALOG - DOWNLOAD ARQUIVOS NFE */}
        {
            !!activeNFe && !!downloadOptionsOpen && <ModalFrame>
                <Modal
                    headerTitle="Baixar arquivos"
                    closeOnClickAway={true}
                    handleClose={() => setDownloadOptionsOpen(false)}
                    loading={loadingDownload}
                >
                    <span style={{ width: '50%' }}>Selecione a opção desejada:</span>

                    <div style={{ width: '100%', display: 'flex', marginTop: '14px', justifyContent: 'space-around' }}>
                        <button disabled={downloadingXml} className="icon-button centered" style={{ marginRight: '12px' }}
                            onClick={() => handleDownloadXml(activeNFe)}
                        >
                            {downloadingXml ? (<CircularProgress size={14} style={{ 'color': '#19ea81' }} />) : 'Baixar XML'}
                        </button>
                        <button
                            className="icon-button centered"
                            onClick={() => handleVisualizarDanfeClick(activeNFe)}
                        >
                            Visualizar DANFE
                        </button>
                    </div>
                </Modal>
            </ModalFrame>
        }

        {/* DIALOG - VISUALIZAR DANFE */}

        {visualizarDanfeOpen && (
            <ModalFrame>
                <Modal
                    headerTitle="Visualizar DANFe"
                    closeOnClickAway={true}
                    handleClose={() => {
                        setVisualizarDanfeOpen(false);
                        setPdf(undefined);
                        setPdfFilename(undefined);
                    }}
                    headerActions={[
                        {
                            icon: (<i title="Imprimir" className="bi bi-printer" style={{ fontSize: '1rem' }} />),
                            actionCallback: () => handlePrint(pdf)
                        },
                        {
                            icon: (<i title="Download" className="bi bi-download" style={{ fontSize: '1rem' }} />),
                            actionCallback: () => fileDownload(pdf, pdfFilename)
                        }
                    ]}
                    loading={false}>
                    <PDFViewer pdf={pdf} />
                </Modal>
            </ModalFrame>
        )}

        {/* DIALOG - CANCELAR NFE */}
        {
            !!activeNFe && !!cancelDialogOpen && <ModalFrame>
                <Modal
                    headerTitle="Cancelar nota"
                    closeOnClickAway={true}
                    handleClose={() => setCancelDialogOpen(false)}
                    loading={loadingCancel}
                    actionButtons={[{
                        text: 'Cancelar',
                        actionCallback: () => setCancelDialogOpen(false),
                        positiveAction: false
                    },
                    {
                        text: 'Confirmar',
                        actionCallback: () => handleCancelNfe(activeNFe),
                        positiveAction: true
                    }]}
                >
                    <div className="correcao-frame" key={"cancel"}>
                        <span>Digite a justificativa para o cancelamento da nota <b>{activeNFe && activeNFe.docNum}</b> :</span>
                        <textarea ref={justificationRef} placeholder="(Texto deve conter entre 15 e 255 caracteres)" />
                    </div>
                </Modal>
            </ModalFrame>
        }

        {/* DIALOG - CARTA DE CORREÇÃO */}
        {
            !!activeNFe && !!correctionDialogOpen && <ModalFrame>
                <Modal
                    headerTitle="Criar carta de correção"
                    closeOnClickAway={false}
                    handleClose={() => setCorrectionDialogOpen(false)}
                    loading={loadingCartaCorrecao}
                    actionButtons={[{
                        text: 'Cancelar',
                        actionCallback: () => setCorrectionDialogOpen(false),
                        positiveAction: false
                    },
                    {
                        text: 'Enviar carta',
                        actionCallback: () => handleAmendNfe(activeNFe),
                        positiveAction: true
                    }]}
                >
                    <div className="correcao-frame">
                        <span>Digite abaixo a carta de correção para a nota: <b>{activeNFe && activeNFe.docNum}</b></span>
                        <textarea ref={ccRef} placeholder="(Texto deve conter entre 15 e 255 caracteres)" />
                    </div>
                </Modal>
            </ModalFrame>
        }

        {/* DIALOG - HISTÓRICO NFE */}
        {
            nfeHistoryDialogOpen && <ModalFrame>
                <Modal
                    headerTitle="Histórico da NFe"
                    closeOnClickAway={false}
                    handleClose={() => setNfeHistoryDialogOpen(false)}
                    loading={loadingHistory}
                >
                    <div style={{ display: 'table-table', border: `1px solid ${'var(--grey-light)'}`, borderRadius: '10px' }}>
                        <div style={{ display: 'table-row', fontWeight: 'bold', textAlign: "center" }}>
                            <div style={{ width: '150px', display: 'table-cell', padding: '10px' }}>Data</div>
                            <div style={{ display: 'table-cell' }}>Status</div>
                            <div style={{ minWidth: '100px', display: 'table-cell' }}>Usuário</div>
                        </div>
                        <div style={{ display: 'table-row' }}>
                            <hr className="line-separator" />
                            <hr className="line-separator" />
                            <hr className="line-separator" />
                        </div>
                        {
                            !!activeNFe && !!activeNFe.history && activeNFe.history.map((histItem, index) => <div key={index} style={{ width: '100%', display: 'table-row', padding: '5px' }} >
                                <div className="div-table-cell" style={{ width: '150px' }}>
                                    {histItem.createdAt}
                                </div>
                                <div className="div-table-cell">
                                    <div className="hist-item-frame">
                                        <div>
                                            <Tooltip title={histItem.status}
                                                placement='top' >
                                                <div className="status-indicator" />
                                            </Tooltip>
                                            {
                                                (!!activeNFe.history && index < activeNFe.history.length - 1) ?
                                                    <p className="status-link-bar" ></p>
                                                    : ''
                                            }
                                        </div>
                                        <div style={{ paddingLeft: '15px' }}>
                                            <span style={{
                                                color: `${(index === 0 && histItem.status) ? 'var(--color-success)' : ''}`,
                                                fontSize: '100%',
                                                fontWeight: `${(index === 0 && histItem.status) ? 'bold' : ''}`
                                            }}>{histItem.message}</span>
                                            {histItem.details && !!histItem.details.length && (<HistoryDetails items={histItem.details} />)}
                                        </div>
                                    </div>
                                </div>
                                <div className="div-table-cell" style={{ paddingRight: '10px', textAlign: 'center' }}>
                                    {histItem.username}
                                </div>
                            </div>)
                        }
                    </div>
                </Modal>
            </ModalFrame>
        }

        {/* DIALOG - EDITAR DOCUMENTO */}
        {
            !!activeNFe && !!editDialogOpen && <EditModalFrame>
                <Modal
                    headerTitle="Editar Documento"
                    closeOnClickAway={false}
                    handleClose={() => setEditDialogOpen(false)}
                    loading={loadingEdit}
                    actionButtons={[{
                        text: 'Cancelar',
                        actionCallback: () => setEditDialogOpen(false),
                        positiveAction: false
                    },
                    {
                        text: 'Salvar',
                        actionCallback: () => validateEditNFe(),
                        positiveAction: true
                    }]}
                >
                    <div className="edit-frame">
                        <aside className={['bottom-frame', 'edit-border', 'visible'].join(' ')}>
                            <span className="header">Dados da NFe</span>

                            <div className="content">
                                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Número</span>
                                        <input type="number" disabled={true} value={activeNFe?.docNum} />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Série</span>
                                        <input type="number" disabled={true} value={activeNFe?.series} />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Data/Hora Emissão</span>
                                        <input type="text" disabled={true} value={activeNFe?.issueDate} />
                                    </div>
                                </div>
                            </div>
                        </aside>
                        <aside className={['bottom-frame', 'edit-border', 'visible'].join(' ')} style={{ marginTop: 10 }}>
                            <span className="header">Emitente</span>

                            <div className="content">
                                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>CNPJ</span>
                                        <input type="text" disabled={true} value={activeNFe?.issuerCnpj} />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>IE</span>
                                        <input type="number" disabled={true} value={activeNFe?.company.ie} />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Nome/Razão Social</span>
                                        <input type="text" style={{ minWidth: 300 }} disabled={true} value={activeNFe?.company.razaoSocial} />
                                    </div>
                                </div>
                            </div>
                        </aside>
                        <aside className={['bottom-frame', 'edit-border', 'visible'].join(' ')} style={{ marginTop: 10 }}>
                            <span className="header">Destinatário</span>

                            <div className="content">
                                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>{activeNFe?.recipient?.taxIdType}</span>
                                        <input type="text" value={activeNFe?.recipient?.taxId} style={{ minWidth: 180 }} onChange={event => setActiveNFe({ ...activeNFe, recipient: { ...activeNFe.recipient, taxId: event.target.value } })} />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>IE</span>
                                        <input type="number" value={activeNFe?.recipient?.ie} disabled={ieType.length > 0 && ieType[0].id > 1} onChange={event => setActiveNFe({ ...activeNFe, recipient: { ...activeNFe.recipient, ie: event.target.value } })} />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Indicador IE</span>

                                        <PickList
                                            items={ieTypes}
                                            selected={ieType}
                                            placeholder="Selecione"
                                            isAsync
                                            showEndMessage={false}
                                            allowDeselection={false}
                                            showSearch={false}
                                            itemKey={"ieType"}
                                            handleApplySelection={onSelectedIeType} />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px' , width: '100%' }}>
                                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Email</span>
                                        <input type="text" value={activeNFe?.recipient?.email} style={{ minWidth: 180 }} onChange={event => setActiveNFe({ ...activeNFe, recipient: { ...activeNFe.recipient, email: event.target.value } })} />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Nome/Razão Social</span>
                                        <input type="text" style={{ minWidth: 420 }} value={activeNFe?.recipient?.name} onChange={event => setActiveNFe({ ...activeNFe, recipient: { ...activeNFe.recipient, name: event.target.value } })} />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px' , width: '100%' }}>
                                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Código do Pais</span>
                                        <input type="number" value={activeNFe?.recipient?.address?.cpais} style={{ minWidth: 180 }} onChange={event => setActiveNFe({ ...activeNFe, recipient: { ...activeNFe.recipient, address: { ...activeNFe.recipient?.address, cpais: Number(event.target.value) } } })} />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px' , width: '100%' }}>
                                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Nome do Pais</span>
                                        <input type="text" value={activeNFe?.recipient?.address?.xpais} style={{ minWidth: 420 }} onChange={event => setActiveNFe({ ...activeNFe, recipient: { ...activeNFe.recipient, address: { ...activeNFe.recipient?.address, xpais: event.target.value } } })} />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>UF</span>
                                        <PickList
                                            items={ufList}
                                            selected={selectedUf}
                                            placeholder="Selecione"
                                            isAsync
                                            showEndMessage={false}
                                            allowDeselection={false}
                                            showSearch={false}
                                            itemKey={"address.uf"}
                                            handleApplySelection={onSelectedUf} />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px' , width: '100%' }}>
                                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Código do Município</span>
                                        <input type="number" value={activeNFe?.recipient?.address?.cmun} style={{ minWidth: 180 }} onChange={event => setActiveNFe({ ...activeNFe, recipient: { ...activeNFe.recipient, address: { ...activeNFe.recipient?.address, cmun: Number(event.target.value) } } })} />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px' , width: '100%' }}>
                                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Nome do Município</span>
                                        <input type="text" value={activeNFe?.recipient?.address?.xmun} style={{ minWidth: 420 }} onChange={event => setActiveNFe({ ...activeNFe, recipient: { ...activeNFe.recipient, address: { ...activeNFe.recipient?.address, xmun: event.target.value } } })} />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px' , width: '100%' }}>
                                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>CEP</span>
                                        <input type="text" value={activeNFe?.recipient?.address?.cep} style={{ minWidth: 180 }} onChange={event => setActiveNFe({ ...activeNFe, recipient: { ...activeNFe.recipient, address: { ...activeNFe.recipient?.address, cep: event.target.value } } })} />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px' , width: '100%' }}>
                                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Rua</span>
                                        <input type="text" value={activeNFe?.recipient?.address?.xlgr} style={{ minWidth: 300 }} onChange={event => setActiveNFe({ ...activeNFe, recipient: { ...activeNFe.recipient, address: { ...activeNFe.recipient?.address, xlgr: event.target.value } } })} />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px' , width: '100%' }}>
                                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Bairro</span>
                                        <input type="text" value={activeNFe?.recipient?.address?.xbairro} style={{ minWidth: 300 }} onChange={event => setActiveNFe({ ...activeNFe, recipient: { ...activeNFe.recipient, address: { ...activeNFe.recipient?.address, xbairro: event.target.value } } })} />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px' , width: '100%' }}>
                                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Telefone</span>
                                        <input type="text" value={activeNFe?.recipient?.address?.fone} style={{ minWidth: 100 }} onChange={event => setActiveNFe({ ...activeNFe, recipient: { ...activeNFe.recipient, address: { ...activeNFe.recipient?.address, fone: event.target.value } } })} />
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                </Modal>
                {!!editConfirmDialogOpen &&
                    <Modal
                        headerTitle="Confirmar edição do documento"
                        closeOnClickAway={false}
                        handleClose={() => setEditConfirmDialogOpen(false)}
                        loading={false}
                        actionButtons={[{
                            text: 'Cancelar',
                            actionCallback: () => setEditConfirmDialogOpen(false),
                            positiveAction: false
                        },
                        {
                            text: 'Confirmar',
                            actionCallback: () => reissueNFe(),
                            positiveAction: true
                        }]}>
                        <div className="confirm-edit-frame">
                            <span>Ao salvar a alteração dos dados do destinatário a NFe será reenviada para autorização na SEFAZ.</span>
                            <span>Deseja realmente reenviar a solicitação de autorização da NFe?</span>
                        </div>
                    </Modal>
                }
            </EditModalFrame>
        }

        {/* DIALOG - CONFIRM */}
        {!!skipNumbersConfirmDialogOpen && <Modal
            headerTitle="Atenção!"
            closeOnClickAway={false}
            handleClose={() => setskipNumbersConfirmDialogOpen(false)}
            loading={false}
            actionButtons={[{
                text: 'Cancelar',
                actionCallback: () => setskipNumbersConfirmDialogOpen(false),
                positiveAction: false
            },
            {
                text: 'Confirmar',
                actionCallback: () => handleSkipNumbersBatch(),
                positiveAction: true
            }]}>
            <div className="confirm-edit-frame">
                <span>Ao confirmar essa operação, as NFes selecionadas serão enviadas para inutilização de sua numeração na SEFAZ.</span>
                <br/>
                <span>Deseja realmente inutilizar essas numerações de NFes?</span>
            </div>
        </Modal>
        }

    </StyledFrame ></PageFrame >;
}

export default ListPage;