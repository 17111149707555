import React, { useRef } from 'react';
import styled from "styled-components";
import PickList, { PickListItem } from "components/picklist/picklist";
import { useContext, useState } from "react";
import { ClickAwayListener } from "@mui/material";
import { routes } from "routes";

import AppContext from "context/app/context";
import SideBar from "components/sidebar/sideBar";

import { useHistory, useLocation } from 'react-router-dom';

import { buildRootCompanyDescription, findAllRootCompanies, searchAllRootCompanies } from "@services/CompanyService";
import { useEffect } from "react";
import { useAuth } from '@contexts/auth';
import { getItem, setItem, removeItem } from '@services/LocalStorageService';
import { Company } from '@models/Company';

import { Link } from 'react-router-dom';

const StyledFrame = styled.div`
    height: 100%;
    width: 100%;
    max-width: 100%;
    
    background-color: var(--grey-dark);

    position: relative;

    display: flex;
    flex-direction: column;

    overflow: hidden;

    .frame-background {
        height: calc(100% - 28px);
        width: calc(100% - 28px);
        padding: 14px;
        
        background-color: var(--color-app-background);
        
        border-top-left-radius: 22px;
        border-bottom-left-radius: 22px;

        > #header {
            width: calc(100% - 28px);
            max-width: calc(100% - 28px);
            
            display: flex;
            flex-direction: column;
            
            position: absolute;
            z-index: 9;

            h3 {
                white-space: nowrap;
                margin: 8px 0;
            }

            .breadcrumbs {
                display: flex;
            }

            #searchBarFrame {
                margin-left: auto;
                width: 50%;
                float: right;
            }

            .buttons-frame {
                min-width: 340px;
                display: flex;

                margin-left: 12px;

                .icon-button {
                    min-width: calc(50% - 32px);
                }
            }

            #picklist {
                width: 500px;
            }

            #profileLink {
                margin-left: auto;
                align-self: center;
                border: 0px;
                background-color: transparent;
                display: flex;

                :hover {
                    cursor: pointer;
                }

                .profile-graph-icon {
                    font-size: 1.6rem;
                    line-height: .1rem;
                    color: var(--grey-dark);

                    display: block;
                    
                    background-color: var(--white);

                    border-radius: 50%; 
                }
            }
        }

        > #body {
            width: 100%;
            height: calc(100% - 75px);
            max-height: calc(100% - 75px);

            display: flex;
            flex-direction: column;
            position: relative;

            margin-top: 76px;
        }

        .rotated-180 {
            transform: rotate(180deg);
        }


        .logout {

            padding: 4px 0;
            margin-top: 2.5rem;
            margin-left: 3rem;
            background-color: white;
            text-align: center;
            border: 1px solid var(--grey-light);
            border-radius: 8px;
            position: absolute;
            width: 70%;
            z-index: 10;
            max-width: 90%;

            button {
                margin-left: auto;
                width: 100%;
                align-self: center;
                border: 0px;
                background-color: transparent;
                display: flex;
                justify-content: center;
    
                :hover {
                    cursor: pointer;
                    background-color: var(--grey-lightest);
                }
    
                :active {
                    color: var(--color-primary);
                }
            }
        }
}`;

const PageFrame = ({ children, ...props }: React.PropsWithChildren<any>) => {

    const { state, dispatch } = useContext(AppContext);
    const [company] = useState<any>(state.selectedCompany);
    const [companiesList, setCompaniesList] = useState<PickListItem[]>([]);
    const [isOpened, setIsOpened] = useState(false);
    const { signOut, tenantInfo, authInfo } = useAuth();
    const history = useHistory();
    const location = useLocation();
    const { rootCompaniesList } = state;

    const picklistRef = useRef(null);

    const onSearchTextChanged = (searchTerm: string) => {
        searchAllRootCompanies(searchTerm).then(response => {
            setCompaniesList(mapToPickListItem(response))
        })
    }

    const mapToPickListItem = (companiesList: Company[]): PickListItem[] => {
        return companiesList.map(root => buildRootCompanyDescription(root));
    }

    const onSelectedCompany = (selectedList: PickListItem[]) => {
        if (selectedList.length) {

            const selectedCompany = rootCompaniesList.find(comp => comp.id === selectedList[0].id)

            if (selectedCompany) {
                dispatch({
                    type: "SET_SELECTED_COMPANY",
                    payload: selectedCompany
                });
                setItem("selectedCompany", {codigoEmpresa: selectedCompany.codigoEmpresa, codigoEstabelecimento: selectedCompany.codigoEstabelecimento})
            }
        } else {
            dispatch({
                type: "SET_SELECTED_COMPANY",
                payload: null
            });
        }
    }

    useEffect(() => {
        findAllRootCompanies().then(companies => {
            dispatch({
                type: "SET_ROOT_COMPANIES",
                payload: companies
            })
        })
    }, [dispatch]);

    useEffect(() => {
        setCompaniesList(mapToPickListItem(rootCompaniesList));
        const localStorageSelectedCompany = getItem("selectedCompany")
        if(localStorageSelectedCompany != null) {
            const selectedStorageCompany: Company = JSON.parse(localStorageSelectedCompany)
            const rootCompany = rootCompaniesList.find(comp => comp.codigoEmpresa === selectedStorageCompany.codigoEmpresa && comp.codigoEstabelecimento === selectedStorageCompany.codigoEstabelecimento);
            if (rootCompany) {
                dispatch({
                    type: "SET_SELECTED_COMPANY",
                    payload: rootCompany
                });
                if (picklistRef.current) {
                    (picklistRef.current as any).setSelectedOption([rootCompany].map(selectedCompany => buildRootCompanyDescription(selectedCompany)));
                }
            }
        }
    }, [rootCompaniesList, dispatch])


    const handleSignOut = () => {
        signOut();
        history.replace({ pathname: `/${tenantInfo?.tenantAlias}/login`, state: { error: "asdasdasd" } });
    }
	
	const getRoute = () => {
		return routes.find(route => route.path === location.pathname.replace(`/${tenantInfo?.tenantAlias}`, ''));
	}


    return <>
        <SideBar />
        <StyledFrame>
            <div className="frame-background">
                <div id="header">
                    <div style={{ display: 'flex', position: 'relative', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', width: '600px' }}>
                            <PickList
                                items={companiesList}
                                selected={company ? [company].map(selectedCompany => buildRootCompanyDescription(selectedCompany)) : []}
                                placeholder="Selecione a empresa"
                                handleApplySelection={onSelectedCompany}
                                showEndMessage={false}
                                allowDeselection={true}
                                itemKey={"company"}
                                ref={picklistRef}
                                handleSearchTextChange={onSearchTextChanged}
                                handleReset={() => removeItem("selectedCompany")}
                            />
                        </div>
                        <ClickAwayListener onClickAway={() => setIsOpened(false)}>
                            <div style={{ position: 'relative', display: 'flex'}}>
                                <Link to={`/${tenantInfo?.tenantAlias}/franquia`} style={{ marginRight:'10px', marginTop: 'auto'}}>
                                    <button id="profileLink">
                                        <i className="bi bi-graph-up-arrow profile-graph-icon" />
                                    </button>
                                </Link>
                                <button id="profileLink" onClick={() => setIsOpened(!isOpened)}>
                                    <i className="bi bi-person-circle profile-graph-icon" />
                                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft:'10px', marginTop: 'auto'}}>
                                        <b>{authInfo?.name}</b>
                                        <small>{authInfo?.username}</small>
                                    </div>
                                    <i className={`bi bi-caret-down-fill ${isOpened && 'rotated-180'}`} style={{fontSize: ".7rem", margin: 'auto 12px', transition: 'transform .2s ease-in'}}></i>
                                </button>
                                {isOpened && <div className='logout'>
                                                <button onClick={handleSignOut}>Sair</button>
                                            </div>
                                }
                            </div>
                        </ClickAwayListener>
                    </div>

                    <div style={{ width: '100%', display: 'flex', marginTop: '6px'}}>
                        <div className="breadcrumbs">
                            <h3 style={{ color: 'var(--grey-regular)' }}>{getRoute()?.module }</h3>
                            <h3 style={{ color: 'var(--grey-regular)', marginLeft: '8px' }}>/</h3>
                            <h3 style={{ color: 'var(--grey-darkest)', marginLeft: '8px' }}>{ getRoute()?.breadcrumbText }</h3>
                        </div>

                        <span style={{
                            width: '100%', height: '1px',
                            backgroundColor: 'var(--grey-light)',
                            marginLeft: '16px',
                            opacity: '.5',
                            alignSelf: 'center'
                        }}
                        />
                    </div>

                </div>
                <div id="body">
                    {children}
                </div>
            </div>
        </StyledFrame>
    </>
}

export default PageFrame;