import api from '../api/Api'

export interface TenantInfo {
  tenantName: string;
  tenantAlias: string;
  idp: string | null;
  deployVersion: string | null;
}

export const getTenantLoginInfo = async (alias: string): Promise<TenantInfo> => {
  try {
    return api.get<TenantInfo>(`/tenant/${alias}`).then(res => res.data);
  } catch (err) {
    throw new Error("Tenant inválido.");
  }
}