import styled from "styled-components";

export const StyledFrame = styled.div`
    width: 100%;
    height: 100vh;

    display: grid;
    justify-content: center;

    align-items: center;

    .login-frame {
        width: 260px;
        height: fit-content;
        max-height: 400px;
        
        margin: auto;

        display: flex;
        flex-direction: column;
        
        background-color: var(--white);

        padding: 20px;

        border: .2px solid #ebebeb;
        border-radius: 6px;
        box-shadow: 0 8px 12px rgb(0 0 0 / 10%);

        z-index: 1;

        .login-header {
            text-align: center;
            margin-bottom: 10px;

            > img {
                max-width: 70%;
                height: 36px;
                margin: 0 auto;
            }
        }

        .login-body {

            .label-frame {
                display: flex;
                justify-content: space-between;
                align-items: center;

                small {
                    color: var(--grey-regular);
                }
            }

            .input-text {
                width: calc(100% - 24px);
                margin-bottom: 12px;
            }

            .links-frame {
                display: flex;
                flex-direction: column;
                text-align: center;

                > a {
                  margin-bottom: 8px;
                  color: var(--grey-dark);
                  text-decoration: none;
                  
                  :hover {
                      color: var(--color-primary);
                  }
                }
            }

            .login-button {
                height: 30px;
                width: 100%;
                padding: 0;
                background: transparent;
                display: flex;
                align-items:center;
                justify-content: center;

                border: 1px solid var(--grey-medium);
                border-radius: 8px;
                
                text-align: center;
                line-height: 30px;
                font-size: .9rem;
                text-transform: uppercase;

                margin-top: 12px;

                user-select: none;
	            transition: color .15s ease-in, opacity .15s ease-in, background-color .2s ease-in;

                cursor: pointer;

                :hover {
                    background-color: var(--color-primary);
                    border-color: var(--color-primary);
                    color: var(--white);
                }

                :disabled {
                    background-color: transparent;
                }
            }
        }
    }

    .background-gradient {
        min-width: 100%;
        height: 100%;
        position: absolute;

        z-index: 0;
    
        background-size: 100% 100%;

        background: radial-gradient(ellipse at 0 148vh,#14E5CB,#14E5CB,transparent, transparent),
                    radial-gradient(ellipse FARTHEST-SIDE at 17vw -44vh,#058137,#058137,transparent, transparent),
                    radial-gradient(ellipse at 76vw 0,#32c732,transparent),
                    radial-gradient(ellipse at 100vw 100vh,#09ea81,transparent,transparent);

                    background-color: aquamarine;
    }
`;