import { sub } from "date-fns";

export class NFCeInutilizacaoFilter {
    companyId: number | null = null;
    initialNum: string = '';
    finalNum: string = '';
    series: string = '';
    year: string = '';
    initialDate: string | null = '';
    finalDate: string | null = '';
    branchId: number | null = null;

    initialDateObj?: Date = sub(new Date(), { months: 1 });
    finalDateObj?: Date = new Date();
}