import { NFe } from "model/NFe";
import { SATFilter } from "model/sat/SATFilter";
import api from "./Api";
import format from "date-fns/esm/fp/format/index.js";
import { PageResponse } from '@models/PageResponse';
import fileDownload from 'js-file-download';
import { SAT } from "@models/sat/SAT";

export default class SATListingApi {

    fetchSATHistory = async (satId: Number): Promise<any> => {
        try {
            return api.get(`/sat/${satId}/history`);
        } catch (err) {
            throw new Error("Ocorreu um erro ao buscar o histórico do CFe SAT.");
        }
    }

    fetchFilteredSATs = (filters: SATFilter, pageIndex: Number, pageSize: Number, orderBy: string, orderDirection: "ASC" | "DESC"): Promise<PageResponse<SAT>> => {
        try {
            const requestData = {
                status: filters.status,
                initialDate: filters.initialDateObj ? format("dd/MM/yyyy", filters.initialDateObj) : null,
                finalDate: filters.finalDateObj ? format("dd/MM/yyyy", filters.finalDateObj) : null,
                minValue: filters.vlrMinNf,
                maxValue: filters.vlrMaxNf,
                docDestinatario: filters.docDestinatario,
                serie: filters.serie,
                numDoc: filters.numDoc,
                accessKeys: filters.chaveSat,
                branchesIds: filters.branchesIds,
                companyId: filters.companyId,
                transactionsIds: filters.ids,
                page: pageIndex,
                size: pageSize,
                sort: `${orderBy},${orderDirection}`
            }

            return api.get('/sat', { params: requestData }).then(({ data }) => data);
        } catch (err) {
            throw new Error("Ocorreu um erro ao tentar buscar a listagem de CFe SAT.");
        }
    };

    downloadXml = async (sat: SAT) => {
        try {

            const response = await api.get(`/sat/${sat.id}/download`, {
                responseType: 'blob'
            });

            if (response.status === 200) {
                fileDownload(response.data, response.headers.filename)
            }

            return response;
        } catch (err) {
            console.log("Failed to download the CFe SAT xml file.", err);
            return null;
        }
    }

    downloadXmlBatch = async (ids: number[]) => {
        try {
            const response = await api.get(`/sat/download/batch`, {
                params: { ids: ids.join(',') },
                responseType: 'blob'
            });

            return response;
        } catch (err) {
            console.log("Failed to download the CFe SAT xml batch file.", err);
            return null;
        }
    }

    downloadExtrato = async (sat: SAT) => {
        try {
            const response = await api.get(`/sat/${sat.id}/extrato`, { responseType: 'blob' });

            return response;
        } catch (err) {
            console.log("Failed to download CFe SAT receipt. ", err);
            return null;
        }
    }

    downloadExtratoBatch = async (ids: number[]) => {
        try {
            const response = await api.get(`/sat/extrato/batch`, {
                params: { ids: ids.join(',') },
                responseType: 'blob'
            });

            return response;
        } catch (err) {
            console.log("Failed to download the receipt batch file.", err);
            return null;
        }
    }

    downloadExtratoMerged = async (ids: number[]) => {
        try {
            const response = await api.get(`/sat/extrato/merged`, {
                params: { ids: ids.join(',') },
                responseType: 'blob'
            });

            return response;
        } catch (err) {
            console.log("Failed to download the receipt merged file.", err);
            return null;
        }
    }

    exportSATs = async (filters: SATFilter) => {
        try {
            const requestData = {
                status: filters.status,
                initialDate: filters.initialDateObj ? format("dd/MM/yyyy", filters.initialDateObj) : null,
                finalDate: filters.finalDateObj ? format("dd/MM/yyyy", filters.finalDateObj) : null,
                minValue: filters.vlrMinNf,
                maxValue: filters.vlrMaxNf,
                docDestinatario: filters.docDestinatario,
                serie: filters.serie,
                numDoc: filters.numDoc,
                accessKeys: filters.chaveSat,
                branchesIds: filters.branchesIds,
                companyId: filters.companyId
            }

            const res = await api.get('/sat/export', { responseType: 'blob', params: requestData });

            fileDownload(res.data, res.headers.filename)

        } catch (err) {
            console.log("Failed to export CFe SAT list. ", err);
            return null;
        }
    };

}