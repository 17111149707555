import styled from "styled-components";

const StyledFrame = styled.div`
    height: 100%;
    width: 100%;

    background-color: var(--grey-dark);

    .frame-background {
        height: calc(100% - 28px);
        width: calc(100% - 28px);
        padding: 14px;
        
        background-color: var(--color-app-background);
        
        border-top-left-radius: 22px;
        border-bottom-left-radius: 22px;
    }

`;

const ProfilePage = () => {
    return <StyledFrame>
        <div className="frame-background">
            

        </div>
        
    </StyledFrame>;
}

export default ProfilePage;