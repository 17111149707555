import styled from "styled-components";
import PageFrame from "components/page-frame/pageFrame";

const StyledFrame = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    .background {
        background-color: var(--white);
        border-radius: 12px;
        border: 1px solid var(--grey-light);
    }

    .header {
        height: 50px;
        line-height: 50px;
        font-size: 1.1rem;
        padding: 4px 16px;
    }
    
    .top-frame {
        height: 210px;
        width: 100%;

        margin-right: 22px;
        margin-top: 8px;

        color: var(--grey-medium);

        i {
            margin-right: 12px;
            font-size: 1.3rem;
            line-height: 1.1rem;
        }

        .content {
            height: fit-content;
            max-width: 100%;
            padding: 10px 22px 16px 22px;
            
            display: flex;
            
            .action-button {
                margin-top: auto;
                margin-left: auto;
            }
        }
    }
    
    .bottom-frame {
        height: calc(100% - 280px);
        width: 100%;
        margin-right: 8px;
        margin-top: 18px;

        color: var(--grey-medium);

        > span {
            i {
                margin-left: 4px;
                margin-right: 16px;
                font-size: 1rem;
            }
        }

        .content {
            height: calc(100% - 66px);
            max-width: 100%;
            overflow: hidden;
            padding: 0px 22px 16px 22px;
            
            display: flex;
            flex-direction: column;

            color: var(--grey-regular);

            .list-header {
                height: fit-content;
                width: 100%;

                display: flex;

                margin-top: 18px;
                border-bottom: 1px solid var(--grey-regular);

                span {
                    width: 33%;
                    font-weight: bold;
                    font-size: .8rem;
                    color: var(--grey-medium);

                    &:not(:first-of-type) {
                        margin-left: 12px;
                    }
                }
            }

            .list-content {
                height: 100%;
                width: 100%;
                max-width: 100%;

                overflow-y: auto;

                display: flex;
                flex-direction: column;

                > div {
                    height: fit-content;
                    width: 100%;
                    display: flex;

                    padding: 2px 0;

                    > span {
                        width: 33%;
                        height: 30px;
                        line-height: 30px;
                        color: var(--grey-medium);

                        &:not(:first-of-type) {
                            margin-left: 12px;
                        }
                    }
                }

                .empty-list {
                    width: 100%;
                    height: 100%;

                    display: grid;
                    place-items: center;
                }
            }
        }
    }

    .item-label {
        color: var(--grey-regular);
        margin-bottom: 2px;
    }
`;

const IndexPage = () => {
    return <PageFrame>
        <StyledFrame>
            <div>Bem vindo ao Dootax!</div>
        </StyledFrame>
    </PageFrame>
}

export default IndexPage;