import { useHistory, useLocation } from 'react-router-dom';
import styled from "styled-components";
import DooErrorImage from '../../utils/images/dudu_erro_404.svg';

const StyledFrame = styled.div`
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    place-items: center;

    background-color: var(--grey-regular-light);
	background: transparent;

    .error-message {
        text-align: left;
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 35%;
        padding: 30px;
    }

    h1 {
        color: #414141;
        font-size: 40px;
        font-weight: 200;
        text-transform: uppercase;
        line-height: 1.1em;
    }
	
	.elementor-heading-title {
		font-family: "Gotham",Sans-serif;
		font-size: 18px;
		font-weight: 300;
		line-height: 1.2em;
		margin-bottom: 15px;
	}
	
	.footer-link {
		
		margin-top: 30px;
		font-family: "Gotham",Sans-serif;
		font-size: 13px;
		font-weight: 300;
		line-height: 1.2em;
	
		a {
			text-decoration: none;
		}
	
	}

    .button-content {
        display: flex;
        justify-content: left;
    }

    .login-button {
        height: 30px;
        padding: 20px;
        background: transparent;
        display: flex;
        align-items:center;
        justify-content: center;

        border: 1px solid var(--grey-medium);
        border-radius: 8px;
        
        text-align: center;
        line-height: 30px;
        font-size: .9rem;
        text-transform: uppercase;

        margin-top: 12px;

        user-select: none;
        transition: color .15s ease-in, opacity .15s ease-in, background-color .2s ease-in;

        cursor: pointer;

        :hover {
            background-color: var(--color-primary);
            border-color: var(--color-primary);
            color: var(--white);
        }

        :disabled {
            background-color: transparent;
        }
    }
    
`;

export interface SsoErrorPageProps {
    match?: { params: { tenant: string } }
}

const SsoErrorPage = ({match}: SsoErrorPageProps) => {
    const history = useHistory();

    const query = new URLSearchParams(useLocation().search);
    const errorMessages: any = {
        "user-not-found": "Não foi possivel encontrar o usuário, por favor tente novamente mais tarde. Caso o erro persista entre em contato com o adminstrador do sistema",
        "user-disabled-for-sso":"Usuário não habilitado para autenticação via SSO.",
        "user-disabled": "Usuário inativo. Entre em contato com o administrador do sistema.",
        "default": "Não foi possivel logar via SSO, por favor tente novamente mais tarde. Caso o erro persista entre em contato com o adminstrador do sistema",
        "credentials-expired": "Credenciais expiradas, favor realizar login novamente no seu provedor de SSO. Caso o erro persista entre em contato com o adminstrador do sistema"
    }
    const error = query.get("error") ?? "default";

    return <StyledFrame>
        <div className="error-message">
            <h1><strong>OOPS! ALGO DEU ERRADO</strong></h1>
            <div className="elementor-heading-title"> {errorMessages[error]} </div>
            <div className='button-content'>
                <button className="login-button" onClick={() => history.push(`/${match?.params.tenant}`)}>Voltar para o início</button>
            </div>
			<div className="footer-link">
				<i className="bi bi-info-circle" /> <a target="_blank" href="https://suporte.dootax.com.br/hc/pt-br">Contatar o suporte</a>
			</div>
        </div>
        <div>
            <img src={DooErrorImage} alt="logo" height={500} width={480}/>
        </div>        
    </StyledFrame>;
}

export default SsoErrorPage;