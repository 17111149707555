import { getAuthInfo } from "./AuthenticationService";

export function hasPermission(permissionName: string) :boolean {

    const authInfo = getAuthInfo();

    if (!permissionName || !authInfo || !authInfo.permissions) {
        return false;
    }

    return authInfo.permissions.includes(permissionName);

}