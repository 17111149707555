import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import { RenderFunction } from "react-pdf/dist/Document";
import styled from "styled-components";

interface PDFViewerProps {
  pdf: any;
  showControls?: boolean;
  pageWidth?: number;
  loading?: string | React.ReactElement | RenderFunction | undefined;
}

const CenteredDivFlex = styled.div`
  width: 900px; 
  min-height: 500px; 
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DefaultLoader = () => {
  return (
    <CenteredDivFlex>
      <CircularProgress style={{ 'color': '#19ea81' }} />
    </CenteredDivFlex>
  );
}

const ErrorMessage = () => (<CenteredDivFlex><p>Erro ao carregar o PDF.</p></CenteredDivFlex>)

const PDFViewer: React.FC<PDFViewerProps> = ({ pdf, showControls = false, pageWidth = 920, loading = <DefaultLoader /> }) => {
  const [numPages, setNumPages] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const changePage = (offset: number) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  const previousPage = () => {
    changePage(-1);
  }

  const nextPage = () => {
    changePage(1);
  }

  return (
    <>
      <Document
        noData={loading}
        loading={loading}
        file={pdf}
        error={<ErrorMessage />}
        onLoadSuccess={onDocumentLoadSuccess}>
        {Array.apply(null, Array(numPages))
          .map((x, i)=>i+1)
          .map(page => <Page pageNumber={page} width={pageWidth}/>)}
      </Document>

      {showControls && (
        <div>
          <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
            Anterior
          </button>
          <button
            type="button"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            Próxima
          </button>
        </div>
      )}
    </>
  );
}

export default PDFViewer;