import styled from "styled-components";

export const StyledFrame = styled.div`
    width: 100%;
    height: 100vh;

    display: grid;
    justify-content: center;
    align-items: center;

    .app-frame {
        width: 260px;
        height: fit-content;
        max-height: 400px;
        
        margin: auto;

        display: flex;
        flex-direction: column;
        
        background-color: var(--white);

        padding: 20px;

        border: .2px solid #ebebeb;
        border-radius: 6px;
        box-shadow: 0 8px 12px rgb(0 0 0 / 10%);

        z-index: 1;

        .app-header {
            text-align: center;
            margin-bottom: 10px;

            > img {
                max-width: 70%;
                height: 36px;
                margin: 0 auto;
            }
        }

        .app-loader-container {
            display: flex;
            justify-content: center;
            margin: 30px 0;
        }
    }

    .loader {
        border: 8px solid #414141;
        border-top: 8px solid #19ea81;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 2s linear infinite;
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }

    .background-gradient {
        min-width: 100%;
        height: 100%;
        position: absolute;

        z-index: 0;
    
        background-size: 100% 100%;

        background: radial-gradient(ellipse at 0 148vh,#14E5CB,#14E5CB,transparent, transparent),
                    radial-gradient(ellipse FARTHEST-SIDE at 17vw -44vh,#058137,#058137,transparent, transparent),
                    radial-gradient(ellipse at 76vw 0,#32c732,transparent),
                    radial-gradient(ellipse at 100vw 100vh,#09ea81,transparent,transparent);

                    background-color: aquamarine;
    }
`;