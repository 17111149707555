import Branches from "@components/branches/branches";
import PermissionControl from "@components/permission/PermissionControl";
import { useSelectedRootCompany } from "@contexts/app/context";
import { CircularProgress, Tooltip } from "@mui/material";
import ErrorPage from "@pages/error/errorPage";
import NFeListingApi from "api/NFeListingApi";
import PageFrame from "components/page-frame/pageFrame";
import PickList, { PickListItem } from "components/picklist/picklist";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { PERMISSIONS } from "utils/permissions";

const StyledFrame = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    .background {
        background-color: var(--white);
        border-radius: 12px;
        border: 1px solid var(--grey-light);
    }

    .header {
        height: 50px;
        line-height: 50px;
        font-size: 1.1rem;
        padding: 4px 16px;
        border-bottom-color: var(--grey-light);
        border-bottom-style: solid;
        border-bottom-width: 1px;
        display: flex;
    }

    .right-top-frame {
        height: 210px;
        width: 50%;

        margin-top: 8px;

        color: var(--grey-medium);

        #picklist {
            line-height: 25px;
            min-width: 170px;
            z-index: 2;

            .overlay {
                .header-section {
                    > span {
                        font-family: 'JetBrains Regular' !important;
                        text-transform: uppercase;
                    }
                }
            }
            .overlay.is-opened {
                height: 160px;
            }
            .body-section.is-opened {
                height: 110px;
            }
        }

        .header {
            justify-content: center;
        }

        i {
            margin-right: 12px;
            font-size: 1.3rem;
        }

        .content {
            height: 50%;
            max-width: 100%;
            padding: 10px 22px 16px 22px;
            
            display: flex;
            justify-content: center;
            flex-direction: column;

            > span {
                color: var(--grey-regular);
                font-weight: bold;
            }

            .action-button {
                margin-top: auto;
                margin-left: auto;
            }
        }
    }
    
    .top-frame {
        height: 210px;
        width: 100%;
        margin-top: 8px;

        color: var(--grey-medium);

        i {
            margin-right: 12px;
            font-size: 1.3rem;
        }

        > div {
            i {
                margin-left: 4px;
                margin-right: 16px;
                font-size: 1rem;
            }
        }

        .content {
            height: 50%;
            max-width: 100%;
            padding: 10px 22px 16px 22px;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;

            > div > div > span {
                color: var(--grey-regular);
                font-weight: bold;
            }

            .action-button {
                margin-top: auto;
                margin-left: auto;
            }
        }
    }
    
    .bottom-frame {
        width: 100%;
        margin-right: 8px;
        margin-top: 18px;

        color: var(--grey-medium);

        #picklist {
            line-height: 25px;
            min-width: 170px;

            .overlay {
                .header-section {
                    > span {
                        font-family: 'JetBrains Regular' !important;
                        text-transform: uppercase;
                    }
                }
            }
            .overlay.is-opened {
                height: 160px;
            }
            .body-section.is-opened {
                height: 110px;
            }
        }

        > span {
            i {
                margin-left: 4px;
                margin-right: 16px;
                font-size: 1rem;
            }
        }

        > div {
            i {
                margin-left: 4px;
                margin-right: 16px;
                font-size: 1rem;
            }
        }

        .content {
            max-width: 100%;
            overflow: hidden;
            padding: 0px 22px 16px 22px;
            
            display: flex;
            flex-direction: row;
        }
    }

    .input-label {
        color: var(--grey-regular);
        font-weight: bold;
    }

    .icon {
        color: var(--grey-regular);
    }
`;

enum LimitNNF {
    RESET,
    SERIE
}

const ConfigutationsPage = () => {

    const [selectedBranchId, setSelectedBranchId] = useState<number | null>(null);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [canSync, setCanSync] = useState<boolean>(false);

    const [config, setConfig] = useState<{
        nextNNF: string,
        serieNNF: string
    }>({
        nextNNF: '',
        serieNNF: ''
    });

    const listingApi = new NFeListingApi();

    const selectedRootCompany = useSelectedRootCompany();

    const emailTypeRef = useRef(null);

    const syncDadosRef = useRef(null);

    const limitRef = useRef(null);
    const nextNNFRef = useRef<HTMLInputElement>(null);
    const serieNNFRef = useRef<HTMLInputElement>(null);

    const [selectedEmailType, setSelectedEmailType] = useState<number | null>(null);
    const [selectedSyncDados, setSelectedsyncDados] = useState<string | null>(null);
    const [selectedLimit, setSelectedLimit] = useState<string | null>(null);

    const limits: PickListItem[] = [
        {
            id: LimitNNF.RESET,
            value: "Zerar contagem e manter série",
            codigo: "RESET"
        },
        {
            id: LimitNNF.SERIE,
            value: "Zerar contagem e incrementar série",
            codigo: "SERIE"
        }
    ]

    const emailType: PickListItem[] = [
        {
            id: 0,
            value: "Não enviar e-mail",
            codigo: "Não enviar e-mai"
        },
        {
            id: 1,
            value: "Enviar XML",
            codigo: "Enviar XML"
        },
        {
            id: 2,
            value: "Enviar PDF",
            codigo: "Enviar PDF"
        },
        {
            id: 3,
            value: "Enviar XML/PDF",
            codigo: "Enviar XML/PDF"
        }
    ]

    const syncDados: PickListItem[] = [
        {
            id: 0,
            value: "Não Sincronizar",
            codigo: "NAO_SINCRONIZAR"
        },
        {
            id: 1,
            value: "Sincronizar",
            codigo: "SINCRONIZAR"
        }
    ]

    useEffect(() =>
        setSelectedBranchId(null)
        , [selectedRootCompany]);

    useEffect(() => {
        reset();
        if (selectedBranchId == null) {
            return;
        }
        listingApi.fetchConfig(selectedRootCompany?.id, selectedBranchId).then((response) => {
            if (response.data) {
                setConfig(response.data);
                if (emailTypeRef.current) {
                    (emailTypeRef.current as any).setSelectedOption(emailType.filter(value => value.id === response.data.emailType));
                }
                if (syncDadosRef.current) {
                    (syncDadosRef.current as any).setSelectedOption(syncDados.filter(value => value.codigo === response.data.syncDados));
                }
                if (limitRef.current) {
                    (limitRef.current as any).setSelectedOption(limits.filter(value => value.codigo == response.data.limitNNF));
                }
            }
        })
    }, [selectedBranchId])

    useEffect(() => {
        reset();
        if (selectedBranchId == null) {
            return;
        }
        listingApi.canSync(selectedRootCompany?.id).then((response) => {
            if (response.data) {
                setCanSync(response.data);                
            }
        })
    }, [selectedBranchId])

    const onSelectedCompany = ([first]: PickListItem[]) => {
        setSelectedBranchId(first?.id || null);
    }

    const onSelectedEmailType = ([first]: PickListItem[]) => {
        setSelectedEmailType(first?.id);
    }

    const onSelectedSyncDados = ([first]: PickListItem[]) => {
        setSelectedsyncDados(first?.codigo);
    }

    const onSelectedLimit = ([first]: PickListItem[]) => {
        setSelectedLimit(first?.codigo);
    }

    const reset = () => {
        setConfig({
            nextNNF: '',
            serieNNF: ''
        })
        if (emailTypeRef.current) {
            (emailTypeRef.current as any).setSelectedOption([]);
        }
        if (syncDadosRef.current) {
            (syncDadosRef.current as any).setSelectedOption([]);
        }
        if (limitRef.current) {
            (limitRef.current as any).setSelectedOption([]);
        }
    }

    const handleConfig = () => {
        if (!selectedBranchId) {
            toast.warning("Selecione um estabelecimento para a configuração");
            return false;
        }

        if (selectedEmailType == null) {
            toast.warning("Enviar e-mail deve ser selecionado");
            return false;
        }

        if (selectedSyncDados == null && canSync) {
            toast.warning("Sincronizar Dados deve ser selecionado");
            return false;
        }

        if (serieNNFRef.current && serieNNFRef.current.value.length > 3) {
            toast.warning("Serie NFe deve conter no máximo 3 digitos");
            serieNNFRef.current?.focus();
            return false;
        }

        setIsSaving(true);

        const configRequest = {
            rootId: selectedRootCompany?.id,
            branchId: selectedBranchId,
            emailType: selectedEmailType,
            syncDados: selectedSyncDados,
            nextNNF: nextNNFRef.current ? parseInt(nextNNFRef.current.value) : null,
            serieNNF: serieNNFRef.current ? parseInt(serieNNFRef.current.value) : null,
            limitNNF: selectedLimit || null
        }

        listingApi.saveConfig(configRequest).then(() => {
            toast.success("Configuração salva com sucesso")
        }).finally(() => {
            setIsSaving(false);
        })
    }

    if (selectedRootCompany == null) {
        return <PageFrame><StyledFrame><ErrorPage message="" subMessage={"Antes de acessar essa página, selecione a empresa no menu superior"} hideBackButton /></StyledFrame></PageFrame>
    }

    return <PageFrame><StyledFrame>

        <div style={{ display: 'flex', marginTop: '6px', marginBottom: '6px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '6px', marginBottom: '6px', width: '100%' }}>
                <Branches showLabel onSelect={onSelectedCompany} showEndMessage={false} allowDeselection={false} />
            </div>
            <PermissionControl requiredPermissions={[PERMISSIONS.DOOTAX.NFE.CONFIGURACAO.EDITAR]}>
                <div style={{ marginTop: '32px', display: 'flex', flexDirection: 'row-reverse', marginLeft: '40px' }}>
                    <button
                        className="icon-button action-button"
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        onClick={() => handleConfig()}
                        disabled={isSaving || selectedBranchId == null}
                    >
                        {isSaving ? (<CircularProgress size={14} style={{ 'color': '#FFFFFF' }} />) : "SALVAR"}
                    </button>
                </div>
            </PermissionControl>
        </div>

        { selectedBranchId != null && 
			<div>
				<div style={{ display: 'flex' }}>
					<aside className="top-frame background">
						<span className="header"><i className="bi bi-gear" />Configurações gerais</span>
						<div className="content">
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 15, marginRight: 15, width:'100%' }}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <span style={{ marginBottom: '4px' }}>Enviar e-mail</span>
                                    <Tooltip title="• Indica se um email deve ser enviado ao destinatário da nota fiscal quando o documento for autorizado, cancelado ou corrigido">
                                        <i className="bi bi-info-circle icon" />
                                    </Tooltip>
                                </div>
                                <PickList style={{zIndex: 2}}
                                    items={emailType}
                                    selected={[]}
                                    placeholder="Selecione"
                                    isAsync
                                    showEndMessage={false}
                                    allowDeselection={false}
                                    showSearch={false}
                                    ref={emailTypeRef}
                                    itemKey={"email"}
                                    handleApplySelection={onSelectedEmailType} />
                            </div>    
                            { canSync &&                        
                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 15, marginLeft: 15, width:'100%' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <span style={{ marginBottom: '4px' }}>Sincronizar Dados</span>
                                        <Tooltip title="• Indica se as notas fiscais emitidas devem ser sincronizadas com os demais módulos do Dootax">
                                            <i className="bi bi-info-circle icon" />
                                        </Tooltip>
                                    </div>
                                    <PickList style={{zIndex: 2}}
                                        items={syncDados}
                                        selected={[]}
                                        placeholder="Selecione"
                                        isAsync
                                        showEndMessage={false}
                                        allowDeselection={false}
                                        showSearch={false}
                                        ref={syncDadosRef}
                                        itemKey={"sync"}
                                        handleApplySelection={onSelectedSyncDados} />
                                </div>
                            }
						</div>
					</aside>
				</div>
                <div>
					<aside className="bottom-frame background">
						<span className="header"><i className="bi bi-file-earmark-binary" />Controle de numeração NFe</span>

						<div className="content">
							<div style={{ display: 'flex', width: '100%', marginTop: 15, flexDirection: 'column' }}>
								{/* Numeracao NFe */}
								<div style={{ display: 'flex', flexDirection: 'column', marginRight: '45px' }}>
									<span className="input-label" style={{ whiteSpace: 'nowrap' }}>Próximo número NFe</span>
									<input type="number" ref={nextNNFRef} value={config.nextNNF != null ? config.nextNNF : ''} onChange={event => setConfig({ ...config, nextNNF: event.target.value })} />
								</div>
								<div style={{ display: 'flex', flexDirection: 'column', marginRight: '45px', marginTop: 15 }}>
									<span className="input-label" style={{ whiteSpace: 'nowrap' }}>Série NFe</span>
									<input type="number" ref={serieNNFRef} value={config.serieNNF != null ? config.serieNNF : ''} onChange={event => setConfig({ ...config, serieNNF: event.target.value })} />
								</div>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column', marginTop: 15, width:'100%' }}>
									<div style={{ display: 'flex' }}>
										<span className="input-label" style={{ whiteSpace: 'nowrap' }}>Limite</span>
										<Tooltip title="• Estratégia a ser utilizada quando a numeração da NFe atingir o limite de 9 dígitos">
											<i className="bi bi-info-circle icon" />
										</Tooltip>
									</div>
									<PickList style={{zIndex: 1}}
										items={limits}
										selected={[]}
										placeholder="Selecione"
										isAsync
										showEndMessage={false}
										allowDeselection={false}
										showSearch={false}
										ref={limitRef}
										itemKey={"limite"}
										handleApplySelection={onSelectedLimit} />
								</div>
						</div>
					</aside>
				</div>
			</div>
		}
    </StyledFrame></PageFrame>
}

export default ConfigutationsPage;