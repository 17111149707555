import { formatToBRL, formatToCPFOrCNPJ, formatToNumber } from "brazilian-values";
import { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { VictoryPie, VictoryAnimation, VictoryLabel, VictoryChart, VictoryLine, VictoryScatter, VictoryTheme, VictoryBar, VictoryTooltip, VictoryContainer, VictoryVoronoiContainer } from "victory";
import { useMeasure } from 'react-use';
import PickList, { PickListItem } from "components/picklist/picklist";
import DatePicker from "react-date-picker";
import { format, startOfMonth, lastDayOfMonth } from "date-fns/esm";
import { differenceInDays, startOfYear, sub } from "date-fns";
import PageFrame from "components/page-frame/pageFrame";
import { exportGraph, findTotalByFilter, getCountGraphData, getTotalAmountGraphData, GraphData, TotalizerDashboardFilter, TotalizerResponse } from '@services/NFCeDashboardService';
import Branches from "@components/branches/branches";
import { NFCeFilterStatus, nfceFilterStatus } from "@models/nfce/NFCeFilterStatus";
import { useSelectedRootCompany } from "@contexts/app/context";
import { NFCeFilter } from "@models/nfce/NFCeFilter";
import NFCeListingApi from "api/NFCeListingApi";
import { NFCe } from "@models/nfce/NFCe";
import PermissionControl from "@components/permission/PermissionControl";
import { PERMISSIONS } from "@utils/permissions";
import { hasPermission } from "@services/PermissionService";
const svg = require("save-svg-as-png");

const StyledFrame = styled.div<{ isStoreFocused: boolean }>`
            
    height: 100%;
    max-width: 100%;

    overflow: hidden;

    display: flex;
    flex-direction: column;

    .period-frame {
        max-height: 24px;
        margin-bottom: 12px;
        display: flex;
        justify-content: flex-end;
        text-align: end;
        position: relative;
        user-select: none;

        small {
            margin-top: auto;
            color: var(--grey-regular);
        }

        button {
            position: relative;
            min-height: fit-content;
            width: calc(100% - 24px);
            min-width: fit-content;

            > i {
                display: none;
            }
        }

        .react-date-picker__inputGroup {
            display: flex;
            justify-content: flex-end;
            min-width: fit-content;
            margin-top: 4px;
            line-height: 1.3rem;
            
            margin-right: 0;

            * {
                cursor: pointer;
            }
        }

        .react-date-picker__wrapper {
            background-color: transparent;
            width: fit-content;
            height: 24px;

            border: none;

            padding: 0;
        }

        .fixed-periods-frame {
            height: fit-content;
            display: flex;

            small {
                width: fit-content;
                padding: 0px 6px;
                margin-right: 8px;

                white-space: nowrap;
                
                background-color: var(--white);
                background-color: transparent;
                border: 1px solid var(--grey-light);
                border-radius: 6px;

                cursor: pointer;

                &.selected {
                    border: 2px solid var(--color-primary);
                    color: var(--grey-dark);
                }
            }
        }
    }

    .main-content {
        height: calc(100% - 18px);
        width: 100%;
        overflow: hidden;
        max-width: 100%;

        display: flex;
        flex-direction: column;
        
        .label {
            text-transform: uppercase;
            /* font-family: 'JetBrains Semi Bold'; */
            color: var(--grey-dark);
            font-weight: bold;
        }

        .info-frame {
            min-height: 120px;
            
            width: calc(100% - 26px); // 26px = padding (24px) + border top and bottom (2px)
            
            display: flex;

            margin-right: 12px;
            padding: 12px;
            
            border: 1px solid var(--grey-light);
            border-radius: 12px;
            
            background-color: var(--white);
            
            .general-info {
                height: calc(100% - 26px); // 26px = padding (24px) + border top and bottom (2px)
                min-width: 30%;

                .value-label {
                    margin-left: auto;
                    color: var(--grey-regular);
                    text-transform: uppercase;
                    line-height: 24px;
                }

                p {
                    margin: 0;

                    &.text {
                        text-transform: uppercase;
                    }

                    &.total-count {
                        width: 100%;
                        font-size: 3rem;
                    }

                    &.value {
                        margin-left: 12px;
                    }
                }
            }

            .separator {
                height: 70%;
                width: 1px;
                background-color: var(--grey-light);
                margin: auto 24px;
            }

            .status-frame {
                height: 100%;
                width: 100%;
                
                .status-top-frame {
                    height: 100%;
                    display: flex;
                    margin-bottom: 16px;
                    
                    .status {
                        height: calc(100% - 16px);
                        width: 50%;
                        min-width: 230px;

                        display: flex;
                        flex-direction: column;
                    }

                    .status-info-header {
                        display: flex;
                        min-width: 100%;
                        min-height: 24px;
                        max-height: 24px;
                        margin-bottom: 12px;

                        a {
                            margin-left: auto;
                            color: var(--blue);
                            cursor: pointer;
                            text-decoration: underline;
                        }

                        button {
                            margin-left: auto;
                            color: var(--blue);
                            cursor: pointer;
                            text-decoration: underline;
                            border: 0px;
                            background-color: transparent;
                        }

                        #picklist {
                            line-height: 25px;
                            min-width: 170px;
 
                            .overlay {
                                .header-section {
                                    > span {
                                        font-family: 'JetBrains Regular' !important;
                                        text-transform: uppercase;
                                    }
                                }
                            }
                            .overlay.is-opened {
                                height: 160px;
                            }
                            .body-section.is-opened {
                                height: 110px;
                            }
                        }
                    }

                    .status-info {
                        width: 100%;

                        display: flex;
                        flex-direction: column;

                        margin-left: 12px;

                        .status-primary-label {
                            max-width: 100%;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        small {
                            color: var(--grey-regular);
                            margin-top: auto;
                            line-height: 12px;
                            
                            &:first-of-type {
                                margin-left: auto;
                                margin-right: 12px;
                            }
                        }
                    }

                    .chart-frame {
                        height: 100%;
                        width: 50px;
                        min-width: 60px;
                        margin-top: 8px;

                        g {
                            transform: scale(1.3) translate(-42px, -42px);
                        }
                    }
                }
            }
        }

        .graphs {
            width: 100%;
            height: 100%;
            
            margin-top: 18px;
            
            display: flex;
            justify-content: space-between;
            overflow: hidden;
            
            .graph-frame {
                width: 100%;

                .graph-header {
                    display: flex;
                    justify-content: space-between;

                    small {
                        color: var(--blue);
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }

                line {
                    stroke: var(--grey-light) !important;
                    opacity: .7;
                }

                > div {
                    height: calc(100% - 26px);
                    padding: 12px;
                
                    border: 1px solid var(--grey-light);
                    border-radius: 12px;
                    
                    background-color: var(--white);
                }

                > span {
                    font-weight: bold;
                }

                svg {
                    transform: translateY(-15px) scaleY(1);
                }

                #picklist {
                    line-height: 25px;
                    min-width: 170px;

                    .overlay {
                        .header-section {
                            > span {
                                font-family: 'JetBrains Regular' !important;
                                text-transform: uppercase;
                            }
                        }
                        
                    }
                    .overlay.is-opened {
                        height: 130px;
                    }
                    
                }
            }
        }

        .bottom-frame {
            height: 30%;
            max-height: 180px;

            display: flex;

            margin-top: 16px;

            .franchise-cover {
                height: calc(100% - 18px);
                min-width: 250px;
                
                position: relative;

                display: flex;
                flex-direction: column;

                overflow: hidden;
                
                padding: 8px 12px;
                
                border: 1px solid var(--grey-light);
                border-radius: 12px;
                
                background-color: var(--white);

                > i {
                    position: absolute;
                    right: 12px;
                    color: var(--grey-light);
                }

                .percent-bar-frame {
                    height: fit-content;

                    display: flex;
                    flex-direction: column;

                    margin-top: auto;
                    margin-bottom: auto;

                    small {
                        color: var(--grey-regular);
                    }

                    h4 {
                        margin: 0;

                        &.percentage {
                            color: var(--color-primary);
                        }
                        
                        &.totalFranchise {
                            color: var(--grey-medium);
                        }
                    }
                }

                > a {
                    margin: auto 0 2px auto;

                    color: var(--blue);
                }
            }
            
            .list-frame {
                height: calc(100% - 18px);
                max-height: 200px;
                width: 100%;
                
                display: flex;
                flex-direction: column;

                overflow: hidden;

                padding: 8px 12px;
                
                border: 1px solid var(--grey-light);
                border-radius: 12px;
                
                background-color: var(--white);
                
                
                > div {
                    display: flex;
                    margin-bottom: 8px;
                    
                    > small {
                        color: var(--blue);
                    }

                    small {
                        margin-left: auto;
                        cursor: pointer;
                        color: var(--grey-medium);
                    } 
                }

                .list {
                    min-height: calc(100% - 24px);
                    width: 100%;

                    line-height: .9rem;

                    overflow-y: auto;

                    display: flex;
                    flex-direction: column;

                    .list-item {
                        max-width: 100%;
                        min-height: 24px;

                        border-bottom: 1px solid var(--grey-light);

                        margin-top: 8px;

                        display: flex;
                        justify-content: space-between;

                        span {
                            width: 100%;

                            color: var(--grey-medium-light);

                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;

                            :not(:first-child) {
                                margin-left: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
`;

enum GraphType {
    QTDE_NOTAS_TEMPO,
    VALOR_TOTAL_TEMPO
}

const DashboardPage = () => {

    const [authorizedTotalizer, setAuthorizedTotalizer] = useState({ percent: 5, data: [{ x: 1, y: 5 }, { x: 2, y: 95 }] });
    const [dynamicStatusTotalizer, setDynamicStatusTotalizer] = useState({ percent: 5, data: [{ x: 1, y: 5 }, { x: 2, y: 95 }] });
    const [dynamicStatus, setDynamicStatus] = useState<NFCeFilterStatus>(nfceFilterStatus.filter(status => status.value === 'CANCELADO')[0]);
    const [initDate, setInitDate] = useState(new Date());
    const [finalDate, setFinalDate] = useState(new Date());
    const [selectedPeriod, setSelectedPeriod] = useState('');
    const [selectedBranch, setSelectedBranch] = useState<PickListItem>();
    const [selectedGraphType, setSelectedGraphType] = useState<PickListItem>({ id: GraphType.QTDE_NOTAS_TEMPO, value: "Quantidade de Notas por Período", codigo: "Quantidade de Notas por Período" });
	const [nfces, setNfces] = useState<NFCe[]>([]);

    const [totalizers, setTotalizers] = useState<TotalizerResponse>({ all: { totalAmount: 0, totalCount: 0 }, authorized: { totalAmount: 0, totalCount: 0 }, byStatus: { totalAmount: 0, totalCount: 0 } });

    const [isStoreFocused] = useState(false);

    const [refGraficoQuantidadeNotasTempo, setRefGraficoQuantidadeNotasTempo] = useState<ChildNode | null | undefined>();
    const [refGraficoSomatorioNotasTempo, setRefGraficoSomatorioNotasTempo] = useState<ChildNode | null | undefined>();

    const [ref, { width, height }] = useMeasure<HTMLDivElement>();

    const selectedRootCompany = useSelectedRootCompany();

    const [dynamicTotalAmountGraphData, setDynamicTotalAmountGraphData] = useState<GraphData[]>([]);

    const [fixedTotalAmountGraphData, setFixedTotalAmountGraphData] = useState<GraphData[]>([]);

    const [dynamicCountGraphData, setDynamicCountGraphData] = useState<GraphData[]>([]);

    const [fixedCountGraphData, setFixedCountGraphData] = useState<GraphData[]>([]);

	const listingApi = new NFCeListingApi();	

    const graphTypes: PickListItem[] = [{ id: GraphType.QTDE_NOTAS_TEMPO, value: "Quantidade de Notas por Período", codigo: "Quantidade de Notas por Período" }, { id: GraphType.VALOR_TOTAL_TEMPO, value: "Valor (R$) por Período", codigo: "Somatório de Notas por Período" }];

    useEffect(() => {
        updateTotalizers();
        updateGraphs();
		updateNFCeList();
    }, [dynamicStatus, selectedBranch, selectedRootCompany, initDate, finalDate]);

    useEffect(() => {
        updateTotalizers();
        updateGraphs();
		updateNFCeList();
    }, []);
	
	const updateNFCeList = () => {
        if(!hasPermission(PERMISSIONS.DOOTAX.NFCE.GERENCIAR.VISUALIZAR)) {
           return;
        }
        const dashboardFilter = {
            initialDateObj: initDate ,
            finalDateObj: finalDate,
            branchesIds: selectedBranch?.id || null,
            companyId: selectedRootCompany?.id || null
        } as NFCeFilter;

        listingApi.fetchFilteredNFCes(dashboardFilter, 0, 10, 'issueDate', 'DESC').then(data => {
            setNfces(data.content);
        })
    }

    const updateTotalizers = () => {
        const totalizerFilter = {
            initialDate: initDate ? format(initDate, "dd/MM/yyyy") : null,
            finalDate: finalDate ? format(finalDate, "dd/MM/yyyy") : null,
            status: dynamicStatus?.code,
            branchId: selectedBranch?.id || null,
            companyId: selectedRootCompany?.id || null
        } as TotalizerDashboardFilter;

        findTotalByFilter(totalizerFilter).then(total => {
            setTotalizers(total);

            const { all: { totalCount }, authorized: { totalCount: totalAuthorizedCount }, byStatus: { totalCount: totalDynamicStatusCount } } = total;

            const calculatePercentage = (total: number, referenceValue: number): number => (referenceValue / total) * 100;

            const authorizedPercentage = calculatePercentage(totalCount, totalAuthorizedCount);
            setAuthorizedTotalizer({ percent: authorizedPercentage, data: [{ x: 1, y: authorizedPercentage }, { x: 2, y: 100 - authorizedPercentage }] });

            const dynamicStatusPercentage = calculatePercentage(totalCount, totalDynamicStatusCount);
            setDynamicStatusTotalizer({ percent: dynamicStatusPercentage, data: [{ x: 1, y: dynamicStatusPercentage }, { x: 2, y: 100 - dynamicStatusPercentage }] });
        });
    }

    const updateGraphs = () => {
        const periodicity = differenceInDays(finalDate, initDate) > 32 ? "MONTHLY" : "DAILY";
        let totalizerFilter = {
            initialDate: initDate ? format(initDate, "dd/MM/yyyy") : null,
            finalDate: finalDate ? format(finalDate, "dd/MM/yyyy") : null,
            status: dynamicStatus?.code,
            branchId: selectedBranch?.id || null,
            companyId: selectedRootCompany?.id || null,
            periodicity
        } as TotalizerDashboardFilter;

        getCountGraphData(totalizerFilter).then(data => {
            setDynamicCountGraphData(data);
        });
        getTotalAmountGraphData(totalizerFilter).then(data => {
            setDynamicTotalAmountGraphData(data);
        });

        totalizerFilter.status = 'ONLY_AUTHORIZED';
        getCountGraphData(totalizerFilter).then(data => {
            setFixedCountGraphData(data);
        });
        getTotalAmountGraphData(totalizerFilter).then(data => {
            setFixedTotalAmountGraphData(data);
        });
    }

    const downloadGraphAsPdf = () => {
        let svgReference;
        if (selectedGraphType.id === GraphType.QTDE_NOTAS_TEMPO) {
            svgReference = refGraficoQuantidadeNotasTempo;

        } else {
            svgReference = refGraficoSomatorioNotasTempo;
        }

        svg.svgAsPngUri(svgReference, {
            scale: 1,
            encoderOptions: 1,
            backgroundColor: "white"
        }).then((base64SVGURI: string) => {
            const requestData = { title: selectedGraphType.codigo, base64SVGURI };
            exportGraph(requestData);
        });
    };

    const setPeriod = (period: string) => {

        setSelectedPeriod(period);
        let auxInitialDate = new Date();
        let auxFinalDate = new Date();

        if (period === "today") {
        } else if (period === "current-month") {
            auxInitialDate = startOfMonth(new Date());
        } else if (period === "last-month") {
            const lastMonth = sub(new Date(), { months: 1 });
            auxInitialDate = startOfMonth(lastMonth)
            auxFinalDate = lastDayOfMonth(lastMonth);
        } else if (period === "current-year") {
            auxInitialDate = startOfYear(new Date());
        } else if (period === "12-months") {
            auxInitialDate = sub(new Date(), { months: 12 });
        }

        setInitDate(auxInitialDate);
        setFinalDate(auxFinalDate);
    }

    const onSelectedBranch = ([first]: PickListItem[]) => {
        setSelectedBranch(first);
    }
	
	const getFilterStatus = (statusValue: string) => {
        const auxStatus = nfceFilterStatus.filter(s => s.value?.toUpperCase() === statusValue.toUpperCase())[0];
        if (auxStatus) {
            return auxStatus;
        }
    }

    return <PageFrame><StyledFrame isStoreFocused={isStoreFocused}>

        {/* FILTERS */}
        <section className="filters-frame">
            <div className="period-frame">
                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '100%', textAlign: 'initial', zIndex: 5 }}>
                    <Branches showLabel={false} allowDeselection onSelect={onSelectedBranch} showEndMessage={false} />
                </div>
                <>
                    <small style={{ marginRight: '6px' }}>De:</small>
                    <div style={{ zIndex: 6 }}>
                        <DatePicker
                            value={initDate}
                            locale="pt-BR"
                            dayPlaceholder="dd"
                            monthPlaceholder="mm"
                            yearPlaceholder="aaaa"
                            calendarIcon={<i className="bi bi-calendar"></i>}
                            clearIcon={null}
                            onChange={(value: Date, event: ChangeEvent) => {
                                setInitDate(value);
                                setSelectedPeriod('');
                            }}
                        />
                    </div>
                </>
                <>
                    <small style={{ marginRight: '6px', marginLeft: '6px' }}>Até:</small>
                    <div style={{ zIndex: 6 }}>
                        <DatePicker
                            value={finalDate}
                            locale="pt-BR"
                            dayPlaceholder="dd"
                            monthPlaceholder="mm"
                            yearPlaceholder="aaaa"
                            calendarIcon={<i className="bi bi-calendar"></i>}
                            clearIcon={null}
                            onChange={(value: Date, event: ChangeEvent) => {
                                setFinalDate(value);
                                setSelectedPeriod('');
                            }}
                        />
                    </div>
                </>
                {/* ATALHOS PARA SELEÇÃO DE PERIODO */}
                <section className="fixed-periods-frame">
                    <small className={selectedPeriod === "today" ? 'selected' : ''} onClick={() => setPeriod("today")}>Hoje</small>
                    <small className={selectedPeriod === "current-month" ? 'selected' : ''} onClick={() => setPeriod("current-month")}>Este mês</small>
                    <small className={selectedPeriod === "last-month" ? 'selected' : ''} onClick={() => setPeriod("last-month")}>Mês anterior</small>
                    <small className={selectedPeriod === "current-year" ? 'selected' : ''} onClick={() => setPeriod("current-year")}>Este ano</small>
                    <small className={selectedPeriod === "12-months" ? 'selected' : ''} onClick={() => setPeriod("12-months")}>Últimos 12 meses</small>
                </section>
            </div>
        </section>

        {/* MAIN CONTENT */}
        <div className="main-content">

            {/* INFOS */}
            <div className="info-frame">
                <div className="general-info">
                    <p className="label">TOTAL DE DOCUMENTOS EMITIDOS</p>
                    <p className="total-count">{formatToNumber(totalizers.all.totalCount)}</p>

                    <div style={{ display: 'flex', marginTop: 'auto' }}>
                        <small className="value-label">Valor total:</small>
                        <p className="value">{formatToBRL(totalizers.all.totalAmount)}</p>
                    </div>
                </div>

                <div className="separator"></div>

                <div className="status-frame">
                    <div className="status-top-frame">
                        <div className="status fixed-status">
                            <div className="status-info-header">
                                <span className="status-primary-label label">Autorizado</span>
                            </div>
                            <div style={{ display: 'flex', width: '100%' }}>

                                <div className="chart-frame">
                                    <svg viewBox="0 0 400 400" width="100%" height="100%">
                                        <VictoryPie
                                            standalone={false}
                                            animate={{ duration: 1000, easing: 'exp' }}
                                            width={400}
                                            height={400}
                                            data={authorizedTotalizer.data}
                                            innerRadius={120}
                                            cornerRadius={35}
                                            labels={() => null}
                                            style={{
                                                data: {
                                                    fill: ({ datum }) => {
                                                        const color = "var(--color-primary)";//datum.y > 30 ? "green" : "red";
                                                        return datum.x === 1 ? color : "transparent";
                                                    }
                                                }
                                            }}
                                        />
                                        <VictoryAnimation
                                            duration={1000}
                                            data={authorizedTotalizer.data}
                                        >
                                            {(newProps) => {
                                                return (
                                                    <VictoryLabel
                                                        textAnchor="middle" verticalAnchor="middle"
                                                        x={200} y={200}
                                                        text={`${authorizedTotalizer.percent ? authorizedTotalizer.percent.toFixed(2) : 0}%`}
                                                        style={{ fontSize: 90 }}
                                                    />
                                                );
                                            }}
                                        </VictoryAnimation>
                                    </svg>
                                </div>
                                <div className="status-info">

                                    <div style={{ display: 'flex', height: '18px', marginTop: 'auto', marginBottom: '8px' }}>
                                        <small>QUANTIDADE:</small>
                                        <span>{totalizers.authorized.totalCount}</span>
                                    </div>
                                    <div style={{ display: 'flex', height: '18px' }}>
                                        <small>VALOR:</small>
                                        <span>{formatToBRL(totalizers.authorized.totalAmount)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="separator"></div>

                        <div className="status dynamic-status">
                            <div className="status-info-header">
                                <PickList
                                    selected={[{ id: dynamicStatus.id, codigo: dynamicStatus.label, value: dynamicStatus.label }]}
                                    placeholder="Selecione um status"
                                    showSearch={false}
                                    showEndMessage={false}
                                    itemKey={"status"}
                                    handleApplySelection={(item: PickListItem[]) => {
                                        setDynamicStatus(nfceFilterStatus.filter(status => status.label === item[0].codigo)[0])
                                    }}
                                    items={nfceFilterStatus.filter(status => status.label !== 'Todos' && status.label !== 'Emitido').map(status => {
                                        return {
                                            id: status.id,
                                            codigo: status.label,
                                            value: status.label
                                        }
                                    })}
                                ></PickList>
                            </div>
                            <div style={{ display: 'flex', width: '100%' }}>

                                <div className="chart-frame">
                                    <svg viewBox="0 0 400 400" width="100%" height="100%">
                                        <VictoryPie
                                            standalone={false}
                                            animate={{ duration: 1000, easing: 'exp' }}
                                            width={400}
                                            height={400}
                                            data={dynamicStatusTotalizer.data}
                                            innerRadius={120}
                                            cornerRadius={35}
                                            labels={() => null}
                                            style={{
                                                data: {
                                                    fill: ({ datum }) => {
                                                        const color = dynamicStatus.refColor;
                                                        return datum.x === 1 ? color : "transparent";
                                                    }
                                                }
                                            }}
                                        />
                                        <VictoryAnimation
                                            duration={1000}
                                            data={dynamicStatusTotalizer.data}
                                        >
                                            {(newProps) => {
                                                return (
                                                    <VictoryLabel
                                                        textAnchor="middle" verticalAnchor="middle"
                                                        x={200} y={200}
                                                        text={`${dynamicStatusTotalizer.percent ? dynamicStatusTotalizer.percent.toFixed(2) : 0}%`}
                                                        style={{ fontSize: 90 }}
                                                    />
                                                );
                                            }}
                                        </VictoryAnimation>
                                    </svg>
                                </div>
                                <div className="status-info">

                                    <div style={{ display: 'flex', height: '18px', marginTop: 'auto', marginBottom: '8px' }}>
                                        <small>QUANTIDADE:</small>
                                        <span>{totalizers.byStatus.totalCount}</span>
                                    </div>
                                    <div style={{ display: 'flex', height: '18px' }}>
                                        <small>VALOR:</small>
                                        <span>{formatToBRL(totalizers.byStatus.totalAmount)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* GRAFICOS */}
            <div className="graphs" ref={ref}>
                <div className="graph-frame">

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="graph-header">
                            <PickList
                                style={{ width: '50%', maxHeight: '150px' }}
                                selected={[selectedGraphType]}
                                placeholder="Selecione um tipo de gráfico"
                                showSearch={false}
                                showEndMessage={false}
                                itemKey={"graph"}
                                handleApplySelection={(item: PickListItem[]) => {
                                    setSelectedGraphType(item[0])
                                }}
                                items={graphTypes}
                            ></PickList>
                            <small onClick={() => downloadGraphAsPdf()}>exportar</small>

                        </div>
                        {selectedGraphType.id === GraphType.QTDE_NOTAS_TEMPO &&
                            <VictoryChart
                                width={width}
                                height={height}
                                padding={{ top: 40, bottom: 35, left: 50, right: 0 }}
                                theme={VictoryTheme.material}
                                domainPadding={{ x: 20, y: 37 }}
                                containerComponent={<VictoryContainer containerRef={(ref) => setRefGraficoQuantidadeNotasTempo(ref?.firstChild)} />}
                            >

                                <VictoryLine
                                    data={fixedCountGraphData}
                                    style={{ data: { stroke: "var(--color-primary)", strokeWidth: 2 } }}
                                />
                                <VictoryScatter data={fixedCountGraphData}
                                    size={3}
                                    style={{ data: { fill: "var(--color-primary)" } }}
                                    labels={({ datum }) => datum.y}
                                    labelComponent={<VictoryTooltip dy={0} pointerOrientation={"top"} style={{ fill: "white" }} flyoutStyle={{
                                        stroke: "black",
                                        fill: "black"
                                    }} />}
                                />


                                <VictoryLine
                                    data={dynamicCountGraphData}
                                    style={{ data: { stroke: `${dynamicStatus.refColor}`, strokeWidth: 2 } }}
                                    labelComponent={<VictoryTooltip />}
                                />
                                <VictoryScatter data={dynamicCountGraphData}
                                    size={3}
                                    style={{ data: { fill: `${dynamicStatus.refColor}` } }}
                                    labels={({ datum }) => datum.y}
                                    labelComponent={<VictoryTooltip dy={0} pointerOrientation={"top"} style={{ fill: "white" }} flyoutStyle={{
                                        stroke: "black",
                                        fill: "black"
                                    }} />}
                                />
                            </VictoryChart>
                        }
                        {selectedGraphType.id === GraphType.VALOR_TOTAL_TEMPO &&
                            <VictoryChart
                                width={width}
                                height={height}
                                domainPadding={{ x: 20, y: 32 }}
                                padding={{ top: 40, bottom: 35, left: 70, right: 0 }} theme={VictoryTheme.material}
                                containerComponent={<VictoryContainer containerRef={(ref) => setRefGraficoSomatorioNotasTempo(ref?.firstChild)} />}
                            >
                                <VictoryBar
                                    data={fixedTotalAmountGraphData}
                                    barWidth={6}
                                    style={{ data: { fill: "var(--color-primary)" } }}
                                    alignment='start'
                                    labels={({ datum }) => datum.y}
                                    labelComponent={<VictoryTooltip dy={0} pointerOrientation={"top"} style={{ fill: "white" }} flyoutStyle={{
                                        stroke: "black",
                                        fill: "black"
                                    }} />}
                                />

                                <VictoryBar
                                    barWidth={6}
                                    data={dynamicTotalAmountGraphData}
                                    style={{ data: { fill: `${dynamicStatus.refColor}` } }}
                                    alignment='end'
                                    labels={({ datum }) => datum.y}
                                    labelComponent={<VictoryTooltip dy={0} pointerOrientation={"top"} style={{ fill: "white" }} flyoutStyle={{
                                        stroke: "black",
                                        fill: "black"
                                    }} />}
                                />
                            </VictoryChart>
                        }
                    </div>
                </div>

            </div>

            <PermissionControl requiredPermissions={[PERMISSIONS.DOOTAX.NFCE.GERENCIAR.VISUALIZAR]}>
                <section className="bottom-frame">

                    <div className="list-frame">
                        <div style={{ display: 'flex' }}>
                            <span className="label">Últimos Documentos</span>
                            <small style={{ marginRight: '8px', color: 'var(--blue)' }}><u>ver todos</u></small>
                        </div>

                        <div className="list">
                            {nfces.length ?
                                nfces.map(nfce => {
                                    return <div key={nfce.id} className="list-item">
                                        <span style={{ maxWidth: '7%' }} >{nfce.docNum}</span>
                                        <span style={{ maxWidth: '10%' }} >{nfce.issueDate}</span>
                                        <span style={{ maxWidth: '16%' }} >{formatToCPFOrCNPJ((nfce as any).company.cnpj)}</span>
                                        <span style={{ maxWidth: '35%' }} >{nfce.recipientName}</span>
                                        <span style={{ maxWidth: '11%' }} >{nfce.reportStatus && getFilterStatus(nfce.reportStatus)?.label}</span>
                                        <span style={{ maxWidth: '10%' }} >{formatToBRL(nfce.nfAmount || '0')}</span>
                                    </div>
                                }) : <div className="loading">Nenhum resultado encontrado</div>
                            }
                        </div>
                    </div>
                </section>
            </PermissionControl>
        </div>

    </StyledFrame></PageFrame>;
}

export default DashboardPage;