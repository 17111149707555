import PickList, { PickListItem } from '@components/picklist/picklist';
import { forwardRef, Ref, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import AppContext, { CompanyBranch } from '@contexts/app/context';
import { buildBranchDescription, findBranchesByParentId } from '@services/CompanyService';

interface BranchesProps {
    showLabel: boolean;
    onSelect(branches: PickListItem[]): void;
    enableMultiSelection?: boolean;
    allowDeselection?: boolean;
    showEndMessage?: boolean;
}

const Branches = ({ showLabel, onSelect, enableMultiSelection, showEndMessage, allowDeselection } : BranchesProps, ref: Ref<unknown> | undefined) => {

    const PAGE_SIZE = 20;

    const ORDER_BY = "codigoEstabelecimento"

    const ORDER_DIRECTION = "ASC"

    const { state } = useContext(AppContext);

    const { selectedCompany } = state;

    const [selectedBranches, setSelectedBranches] = useState<PickListItem[]>([]);

    const [hasMore, setHasMore] = useState<boolean>(false);

    const [pageIndex, setPageIndex] = useState<number>(0);

    const [branchesList, setBranchesList] = useState<PickListItem[]>([])

    const picklistRef = useRef(null);

    const onApplySelection = (selectedList: PickListItem[]) => {
        let branches: PickListItem[] = [];
        if (selectedList.length) {
            branches = branchesList.filter(branch => selectedList.some(selected => selected.id === branch.id))
        }
        setSelectedBranches(branches)
        onSelect(branches);
    }

    useEffect(() => {
        setSelectedBranches([]);
        setHasMore(false);
        onSelect([]);
        if (picklistRef.current) {
            (picklistRef.current as any).reset();
        }
        if (selectedCompany) {
            findBranchesByParentId(selectedCompany.id, null, 0, PAGE_SIZE, ORDER_BY, ORDER_DIRECTION).then(response => {
                setHasMore(!response.last)
                setPageIndex(0);
                setBranchesList(mapToPickListItem(response.content))
            })
        } else {
            setPageIndex(0);
            setBranchesList([]);
        }
    }, [selectedCompany])

    const onLoadMore = () => {
        if (selectedCompany) {
            findBranchesByParentId(selectedCompany.id, null, pageIndex + 1, PAGE_SIZE, ORDER_BY, ORDER_DIRECTION).then(response => {
                setBranchesList(branchesList.concat(mapToPickListItem(response.content)))
                setPageIndex(pageIndex + 1);
                setHasMore(!response.last)
            })
        }
    }

    const onSearchTextChanged = (searchTerm: string) => {
        if (selectedCompany) {
            findBranchesByParentId(selectedCompany.id, searchTerm, 0, PAGE_SIZE, ORDER_BY, ORDER_DIRECTION).then(response => {
                setBranchesList(mapToPickListItem(response.content))
                setPageIndex(0);
                setHasMore(!response.last)
            })
        }
    }

    useImperativeHandle(ref, () => ({
        reset() {
            setSelectedBranches([])
            if (picklistRef.current) {
                (picklistRef.current as any).reset();
            }
        }
    }), [])

    const mapToPickListItem = (branchesList: CompanyBranch[]): PickListItem[] => {
        return branchesList.map(branch => buildBranchDescription(branch));
    }

    return (
        <>
            {showLabel && <span style={{ marginBottom: '4px' }}>Estabelecimento:</span>}
            <PickList
                items={branchesList}
                selected={selectedBranches ? selectedBranches : []}
                placeholder="Selecione o estabelecimento"
                enableMultiSelection={enableMultiSelection}
                handleApplySelection={onApplySelection}
                handleLoadMore={onLoadMore}
                hasMore={hasMore}
                isAsync
                handleSearchTextChange={onSearchTextChanged}
                ref={picklistRef}
                showEndMessage={showEndMessage}
                allowDeselection={allowDeselection}
                itemKey={"branch"}
            />
        </>
    )
}

export default forwardRef(Branches);