import React from "react";

import { AppContextProvider } from "./app/context";

const GlobalContext: React.FC = ({ children }) => {

    return (
        <>
            <AppContextProvider>{children}</AppContextProvider>
        </>
    );
}

export default GlobalContext;