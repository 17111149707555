import { ClickAwayListener, Tooltip } from "@mui/material";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import DatePicker from "react-date-picker";
import styled from "styled-components";
import { formatToBRL, formatToCNPJ } from 'brazilian-values';
import CurrencyFormat from 'react-currency-format';
import ChipInput from "material-ui-chip-input";
import { toast } from "react-toastify";
import { Chip } from "@material-ui/core";
import { format } from "date-fns/fp";
import { PickListItem } from "components/picklist/picklist";
import Branches from "@components/branches/branches";
import { useSelectedRootCompany } from "@contexts/app/context";
import { SATFilter } from "@models/sat/SATFilter";
import { satFilterStatus, SATFilterStatus } from "@models/sat/SATFilterStatus";

const StyledFrame = styled.div<{ isOpen: boolean }>`
    width: 100%;

    position: relative;
    display: flex;

    .filter-overlay {
        height: ${({ isOpen }) => isOpen ? '450px' : '30px'};
        width: calc(100%);

        border-radius: 8px;
        border: 1px solid var(--grey-light);
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        
        position: absolute;
        overflow: hidden;

        background-color: var(--white);
        z-index: 8;
        
        transition: height .3s ease-in, box-shadow .2s ease-in;
        
        &.is-opened {
            border-color: var(--grey-light);
            
            -webkit-box-shadow: -1px 1px 5px 0px #99999984;
            -moz-box-shadow: -1px 1px 5px 0px #99999984;
            box-shadow: -1px 1px 5px 0px #99999984;
        }
        
        .filter-header {
            height: 40px;

            width: 100%;
            display: flex;
            
            cursor: pointer;
            
            .label {
                line-height: 30px;
                padding-left: 12px;
            }
        
            > i {
                font-size: 1.3rem;
                padding-right: 12px;
                padding-left: 12px;
                line-height: 30px;

                margin-bottom: auto;
                
                color: var(--grey-dark);
                
                transition: transform .3s ease-in;
                
                :hover {
                    color: var(--color-primary);
                }
                
                &.rotate-135 {
                    transform: rotate(135deg);
                }
            }

            .chips-frame {
                max-height: 42px;
                height: fit-content;

                width: 100%;
                
                padding-top: 4px;
                margin-left: 16px;
                margin-bottom: auto;
                
                span {
                    line-height: 30px;
                    padding-left: 8px;
                    color: var(--grey-regular);
                }

                display: flex;

                white-space: nowrap;

                overflow-y: hidden;
                overflow-x: auto;

                .MuiChip-root {
                    max-height: 22px;
                    margin-right: 6px !important;
                    
                    background-color: transparent !important;
                    border: 1px solid #dddddda4 !important;

                    /* background-color: #dddddda4 !important; */
                    
                    border-radius: 15px !important;
                    /* border-radius: 4px !important; */
                    font-size: .9rem !important;

                    .MuiChip-label {
                        color: var(--black) !important;
                        font-size: .7rem;
                        /* font-weight: bold; */
                    }
                }
            }
        }

        .filter-body {
            height: calc(100% - 40px - 32px - 38px); // 100% minus the size of the header and footer (32px + 32px), and minus the padding (16px top + 16px bottom)
            width: calc(100% - 32px);

            display: flex;
            overflow-y: auto;

            padding: 16px;

            .left-frame {
                height: fit-content;
                min-width: fit-content;
                
                display: flex;
                flex-direction: column;
                
                > span {
                    color: var(--grey-regular);
                }

                .checkbox-frame {
                    margin-top: 8px;

                    input {
                        margin-right: 10px;
                    }

                    span {
                        user-select: none;
                    }
                }
            }

            .middle-frame {
                height: 100%;
                min-width: fit-content;
                max-width: 420px;

                display: flex;
                flex-direction: column;

                margin-left: 42px;
                margin-right: 42px;

                > span {
                    color: var(--grey-regular);
                    margin-bottom: 4px;
                }
            }

            .right-frame {
                width: 100%;
                height: fit-content;
            }
        }

        .filter-footer {
            height: 100%;
            border-top: 1px solid var(--grey-light);
            opacity: 1;
            display: flex;
            max-height: 50px;

            .separator {
                border-left: 1px solid var(--grey-light);
            }

            button {
                color: var(--grey-dark);
            
                text-transform: uppercase;
                font-size: .7rem;
                font-weight: bold;
                letter-spacing: .3px;
                line-height: 38px;
                background-color: transparent;
                
                transition: color .15s ease-in;
                border: none;
                cursor: pointer;

                font-family: 'TitilliumWeb';

                :hover {
                    color: var(--color-primary);
                }

                > span {
                    text-transform: uppercase;
                    font-size: .7rem;
                    font-weight: bold;
                    letter-spacing: .3px;
                }
            }
            
            
        }
    }
`;


interface FilterProps {
    model: SATFilter
    onFiltersChange: (updatedFilter: SATFilter) => void
    reset: Function;
}

const Filter = (props: FilterProps) => {

    const [isOpen, setIsOpen] = useState(false);
    const [activeFilterModel, setActiveFilterModel] = useState<SATFilter>(props.model);
    const [auxFilterModel, setAuxFilterModel] = useState<SATFilter>(props.model);

    const [docNumbers, setDocNumbers] = useState<string[]>([]);
    const [keys, setKeys] = useState<string[]>([]);
    const [ids, setIds] = useState<string[]>([]);

    const [statusList, setStatusList] = useState(satFilterStatus);

    const selectedRootCompany = useSelectedRootCompany();

    const branchesRef = useRef(null);

    useEffect(() => {
        props.onFiltersChange(activeFilterModel);
    }, [activeFilterModel]);

    useEffect(() => {
        !!docNumbers && setAuxFilterModel({ ...auxFilterModel, numDoc: docNumbers.join(',') })
    }, [docNumbers]);

    useEffect(() => {
        !!keys && setAuxFilterModel({ ...auxFilterModel, chaveSat: keys.join(',') })
    }, [keys]);

    useEffect(() => {
        !!ids && setAuxFilterModel({ ...auxFilterModel, ids: ids.join(',') })
    }, [ids]);

    useEffect(() => {
        setActiveFilterModel(
            {
                ...auxFilterModel,
                companyId: selectedRootCompany ? selectedRootCompany.id : null,
                branchesIds: null,
                branchesCodes: null
            }
        );
    }, [selectedRootCompany]);

    useEffect(() => {

        if (statusList) {

            let auxStatus = statusList.filter(st => st.code && st.active).map(st => {
                return st.code;
            }).join(",")

            setAuxFilterModel({ ...auxFilterModel, status: auxStatus });
        }
    }, [statusList]);

    const handleSelectStatus = (selectedStatus: SATFilterStatus) => {

        if (selectedStatus.value === null) {
            setAuxFilterModel({
                ...auxFilterModel,
                statusLabel: !selectedStatus.active ? 'Todos' : '',
                status: ''
            });

            setStatusList(statusList.filter(st => st.visible).map(status => { return { ...status, active: !selectedStatus.active } }));
        } else {
            let newStatus = new Map<string, string>();

            // Updates status state
            setStatusList(statusList.filter(st => st.visible)
                .map(status => {

                    // Saving status labels and values
                    if (status.code === selectedStatus.code) {
                        if (!selectedStatus.active) {
                            status.code && newStatus.set(status.code, status.label);
                        }
                    } else {
                        status.code && status.active && newStatus.set(status.code, status.label);
                    }

                    if (!selectedStatus.active && statusList.filter(st => st.active).length === 8) {
                        newStatus.clear();
                        newStatus.set('', 'Todos');
                        return { ...status, active: true };
                    }

                    if (status.value === null) {
                        return { ...status, active: false };
                    } else {
                        return { ...status, active: (status.code === selectedStatus.code) ? !status.active : status.active }
                    }
                }));

            // Update aux filter
            setAuxFilterModel({
                ...auxFilterModel,
                status: selectedStatus.code ? Array.from(newStatus.keys()).join(',') : '',
                statusLabel: selectedStatus.label ? Array.from(newStatus.values()).join(',') : 'Todos'
            });
        }
    }

    const handleClickOutsideFilter = (event: Event) => {
        setIsOpen(false);
        event.stopPropagation();
    }

    const handleApplyFilters = () => {
        setActiveFilterModel(auxFilterModel);
        setIsOpen(false);
    }

    const handleOnBeforeAddKey = (key: string) => {
        if (key.length && key.length !== 44) {
            toast.warning(`Chaves CFe SAT devem conter 44 caracteres. Total de caracteres desta chave: ${key.length}`);
            return false;
        }

        return true;
    }

    /**
     * Returns formated keys and values to be used in the filters chips layout
     * @returns an array of key-pair objects
     */
    const getFiltersChips = () => {

        const chips: Array<{ key: string, value: string }> = [];

        !!activeFilterModel.statusLabel && chips.push({ key: "Status", value: activeFilterModel.statusLabel });
        !!activeFilterModel.dataInicialEmissao && chips.push({ key: "De", value: format("dd/MM/yy", new Date(activeFilterModel.dataInicialEmissao)) });
        !!activeFilterModel.dataFinalEmissao && chips.push({ key: "Até", value: format("dd/MM/yy", new Date(activeFilterModel.dataFinalEmissao)) });
        !!activeFilterModel.vlrMinNf && chips.push({ key: "Valor mín.", value: formatToBRL(activeFilterModel.vlrMinNf) });
        !!activeFilterModel.vlrMaxNf && chips.push({ key: "Valor máx.", value: formatToBRL(activeFilterModel.vlrMaxNf) });
        !!activeFilterModel.docDestinatario && chips.push({ key: "Doc. Dest.", value: activeFilterModel.docDestinatario });
        !!activeFilterModel.serie && chips.push({ key: "Série", value: activeFilterModel.serie?.toString() });
        !!activeFilterModel.branchesIds && !!activeFilterModel.branchesCodes && chips.push({ key: "Estabelecimentos", value: activeFilterModel.branchesCodes });
        !!activeFilterModel.numDoc && chips.push({ key: "Num. Doc.", value: activeFilterModel.numDoc });
        !!activeFilterModel.chaveSat && chips.push({ key: "Chave", value: activeFilterModel.chaveSat });
        !!activeFilterModel.ids && chips.push({ key: "Id da transação", value: activeFilterModel.ids });

        return chips;
    }

    const onSelectedCompany = (branches: PickListItem[]) => {
        auxFilterModel.branchesIds = branches.length ? branches.map(branch => branch.id).join(',') : null
        auxFilterModel.branchesCodes = branches.length ? branches.map(branch => branch.codigo).join(',') : null
    }

    const onChangeMinValue = (value) => {
        auxFilterModel.vlrMinNf = (value ? Number.parseFloat(value) : null)
    }

    const onChangeMaxValue = (value) => {
        auxFilterModel.vlrMaxNf = (value ? Number.parseFloat(value) : null)
    }

    const handleAddDocNumber = (docNumber: string) => {
        setDocNumbers(Array.from(new Set(docNumbers.concat(docNumber.split(/[ ,;]+/).filter(value => !!value && value.trim().length > 0)))))
    }

    const handleAddId = (id: string) => {
        setIds(Array.from(new Set(ids.concat(id.split(/[ ,;]+/).filter(value => !!value && value.trim().length > 0)))))
    }

    const reset = () => {
        setDocNumbers([])
        setKeys([])
        setIds([])
        setStatusList(satFilterStatus)
        setAuxFilterModel(new SATFilter())
        setActiveFilterModel(new SATFilter())
        if(branchesRef.current) {
            (branchesRef.current as any).reset();
        }
    }

    return <ClickAwayListener onClickAway={(event) => { isOpen && handleClickOutsideFilter(event) }}>
        <StyledFrame isOpen={isOpen}>
            <div className={'filter-overlay '.concat(isOpen ? 'is-opened' : '')}>
                {/* HEADER */}
                <section className="filter-header" onClick={() => setIsOpen(!isOpen)}>
                    <span className="label">Filtros:</span>

                    <div className="chips-frame">
                        {
                            getFiltersChips().map(val => {
                                return <Chip key={val.key} label={`${val.key}:  ${val.value}`} />
                            })
                        }
                    </div>

                    <i className={`bi bi-plus ${isOpen && 'rotate-135'}`} onClick={(event) => { setIsOpen(!isOpen); event.stopPropagation() }} ></i>
                </section>

                {/* BODY */}
                <section className="filter-body">

                    <div className="left-frame">
                        {/* STATUS SAT */}
                        <span>Status SAT:</span>
                        {
                            statusList.map(status => status.visible && <div className="checkbox-frame" key={status.label}>
                                <input id="checkboxAll"
                                    type="checkbox"
                                    onChange={() => handleSelectStatus(status)}
                                    checked={status.active} />
                                <span>{status.label}</span>
                            </div>)
                        }
                    </div>

                    <div className="middle-frame">
                        {/* DATA DE EMISSAO */}
                        <span>Data de emissão:</span>
                        <div style={{ display: 'flex', height: 'fit-content' }}>
                            <DatePicker
                                value={auxFilterModel.initialDateObj}
                                locale="pt-BR"
                                dayPlaceholder="dd"
                                monthPlaceholder="mm"
                                yearPlaceholder="aaaa"
                                calendarIcon={<i className="bi bi-calendar"></i>}
                                clearIcon={<i className="bi bi-trash"></i>}
                                onChange={(value: Date, event: ChangeEvent) => setAuxFilterModel({ ...auxFilterModel, initialDateObj: value })}
                            />

                            <small style={{ margin: 'auto 12px' }}>até</small>

                            <DatePicker
                                value={auxFilterModel.finalDateObj}
                                locale="pt-BR"
                                dayPlaceholder="dd"
                                monthPlaceholder="mm"
                                yearPlaceholder="aaaa"
                                calendarIcon={<i className="bi bi-calendar"></i>}
                                clearIcon={<i className="bi bi-trash"></i>}
                                onChange={(value: Date, event: ChangeEvent) => setAuxFilterModel({ ...auxFilterModel, finalDateObj: value })}
                            />
                        </div>

                        {/* VALOR MINIMO E MAXIMO */}
                        <div style={{ marginTop: '20px', color: 'var(--grey-regular)', display: 'flex' }} >

                            <div style={{ color: 'var(--grey-regular)', display: 'flex', flexDirection: 'column', marginRight: '12px' }} >
                                <span style={{ marginBottom: '4px' }}>Valor mínimo:</span>
                                <CurrencyFormat
                                    style={{ height: '32px', width: '150px', border: '1px solid var(--grey-light)', borderRadius: '6px' }}
                                    value={auxFilterModel?.vlrMinNf ? auxFilterModel?.vlrMinNf : ''}
                                    displayType="input"
                                    placeholder="R$0,00"
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    onValueChange={(values) => onChangeMinValue(values.value) }
                                    prefix="R$"
                                />
                            </div>

                            <div style={{ color: 'var(--grey-regular)', display: 'flex', flexDirection: 'column' }} >
                                <span style={{ marginBottom: '4px' }}>Valor máximo:</span>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <CurrencyFormat
                                        style={{ height: '32px', width: '150px', border: '1px solid var(--grey-light)', borderRadius: '6px' }}
                                        value={auxFilterModel?.vlrMaxNf ? auxFilterModel?.vlrMaxNf : ''}
                                        displayType="input"
                                        placeholder="R$0,00"
                                        decimalSeparator=","
                                        thousandSeparator="."
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        onValueChange={(values) => onChangeMaxValue(values.value) }
                                        prefix="R$"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* CNPJ's */}
                        <div style={{ marginTop: '20px', color: 'var(--grey-regular)', display: 'flex' }} >

                            <div style={{ color: 'var(--grey-regular)', display: 'flex', flexDirection: 'column', marginRight: '12px' }} >
                                <span style={{ marginBottom: '4px' }}>CPF/CNPJ do Destinatário:</span>
                                <input type="text"
                                    placeholder="xx.xxx.xxx/xxxx-xx"
                                    maxLength={18}
                                    value={formatToCNPJ(auxFilterModel?.docDestinatario ? auxFilterModel?.docDestinatario : '')}
                                    onChange={(event) => setAuxFilterModel({ ...auxFilterModel, docDestinatario: event.target.value.replace(/\D/g, "") })}
                                />
                            </div>

                            <div style={{ color: 'var(--grey-regular)', display: 'flex', flexDirection: 'column' }} >
                                <span style={{ marginBottom: '4px' }}>Série SAT:</span>
                                <input type="number"
                                    style={{ width: '150px' }}
                                    value={auxFilterModel?.serie ? auxFilterModel.serie : ''}
                                    onChange={(event) => setAuxFilterModel({ ...auxFilterModel, serie: event.target.value ? parseInt(event.target.value) : null })}/>
                            </div>
                        </div>
                    </div>

                    <div className="right-frame">
                        <div style={{ width: '100%', color: 'var(--grey-regular)', display: 'flex', flexDirection: 'column' }} >
                            <Branches showLabel onSelect={onSelectedCompany} enableMultiSelection showEndMessage={false} allowDeselection ref={branchesRef} />
                        </div>
                        {/* SERIE */}
                        <div style={{ color: 'var(--grey-regular)', display: 'flex', flexDirection: 'column', marginTop: '20px' }} >

                        </div>

                        {/* NUMERO DOC */}
                        <div style={{ width: '100%', color: 'var(--grey-regular)', display: 'flex', flexDirection: 'column' }} >
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '8px' }}>
                                <span style={{ marginBottom: '4px' }}>Número do documento:</span>
                                <Tooltip title="• Múltiplos números de documento podem ser inseridos separados por vírgula, ponto-e-vírgula ou espaço">
                                    <i className="bi bi-info-circle" />
                                </Tooltip>
                            </div>
                            <ChipInput
                                value={docNumbers}
                                disableUnderline={true}
                                newChipKeys={[' ', 'Enter']}
                                onAdd={(docNumber) => handleAddDocNumber(docNumber)}
                                onDelete={(docNumber, index) => setDocNumbers(docNumbers.filter(number => number !== docNumber))}
                            />
                        </div>

                        {/* CHAVE SAT */}
                        <div style={{ width: '100%', color: 'var(--grey-regular)', display: 'flex', flexDirection: 'column', marginTop: '20px' }} >
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '8px' }}>
                                <span style={{ marginBottom: '4px' }}>Chave SAT:</span>
                                <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>• Chaves devem conter 44 caracteres<br /> • Múltiplas chaves podem ser inseridas separadas por vírgula, ponto-e-vírgula ou espaço</span>}>
                                    <i className="bi bi-info-circle" />
                                </Tooltip>
                            </div>
                            <ChipInput
                                value={keys}
                                disableUnderline={true}
                                newChipKeys={[' ', 'Enter']}
                                onBeforeAdd={handleOnBeforeAddKey}
                                onAdd={(key) => setKeys(keys.concat(key.split(/[ ,;]+/)))}
                                onDelete={(key, index) => setKeys(keys.filter(k => k !== key))}
                            />
                        </div>

                        {/* IDS */}
                        <div style={{ width: '100%', color: 'var(--grey-regular)', display: 'flex', flexDirection: 'column', marginTop: '20px' }} >
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '8px' }}>
                                <span style={{ marginBottom: '4px' }}>Id da transação:</span>
                                <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>• Múltiplos ids podem ser inseridos separados por vírgula, ponto-e-vírgula ou espaço</span>}>
                                    <i className="bi bi-info-circle" />
                                </Tooltip>
                            </div>
                            <ChipInput
                                value={ids}
                                disableUnderline={true}
                                newChipKeys={[' ', 'Enter']}
                                onAdd={(id) => handleAddId(id)}
                                onDelete={(id, index) => setIds(ids.filter(number => number !== id))}
                            />
                        </div>
                    </div>
                </section>

                {/* FOOTER */}
                <section className="filter-footer">
                    <button style={{
                        width: '100%',
                        textAlign: 'center',
                        userSelect: 'none',
                        maxHeight: '70%'
                    }}
                        onClick={() => reset()}
                    >Limpar</button>
                    <div className="separator"></div>
                    <button style={{
                        width: '100%',
                        height: '100%',
                        textAlign: 'center',
                        userSelect: 'none',
                        maxHeight: '70%'
                    }}
                        onClick={() => handleApplyFilters()}
                    >Aplicar</button>
                </section>
            </div>
        </StyledFrame>
    </ClickAwayListener>;
}

export default Filter;