interface ConfigurationValues {
  local: EnvironmentProperties;
  prod: EnvironmentProperties;
}

interface EnvironmentProperties {
  apiURL: string;
  ssoURL: string;
}

const CONFIGURATION_VALUES: ConfigurationValues = {
  local: {
    apiURL: 'https://127.0.0.1:8090/api',
    ssoURL: 'https://127.0.0.1:8090/saml/login?idp='
  },
  prod: {
    apiURL: '__BACKEND_BASE_URL__/api',
    ssoURL: '__BACKEND_BASE_URL__/saml/login?idp='
  }
};

export const getValue = (variableName: keyof EnvironmentProperties): string | undefined => {
  try {
    return getConfigs()[variableName];
  } catch (err) {
    console.error(err);
    throw new Error('Erro ao carregar as configurações.');
  }
}

export const getConfigs = (): EnvironmentProperties => {
  try {
    const environment = (process.env.REACT_APP_ENVIRONMENT || 'local') as keyof ConfigurationValues;
    return CONFIGURATION_VALUES[environment];
  } catch (err) {
    console.error(err);
    throw new Error('Erro ao carregar as configurações.');
  }
}