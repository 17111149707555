import { Popover, Tooltip } from "@mui/material";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import { PermissionControlOptional } from "@components/permission/PermissionControl";
import { SAT } from "@models/sat/SAT";
import { satStatus } from "@models/sat/SATStatus";

const StyledTable = styled.div<{ listLength: number }>`
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: calc(100vh - 140px);

    overflow: hidden;

    position: relative;

    font-size: .85rem;
    color: #000;

    padding-top: 12px;
    padding-bottom: calc(12px + 10px); //12px from padding value plus 10px for tbody translation

    .header-frame {
        width: calc(100% - 12px);
        height: 30px;
        
        padding: 0 12px;

        display: flex;
        justify-content: space-between;
        
        position: relative;

        input {
            margin-right: 8px;
        }
        
        .header-item-frame {
            height: 100%;
            width: 100%;

            display: flex;

            margin-left: 8px;

            :not(:first-of-type) {
                margin-left: 20px;
            }

            ${({ listLength }) => {
        return listLength > 15 ? `
                            :last-child {
                                margin-right: 12px;
                            }` : '';
    }}

            color: var(--color-darkest);

            transition: opacity .2s ease-in;

            :hover {
                cursor: pointer;
                opacity: 1;
            }

            > i {
                margin: auto 0;
                margin-left: 6px;
                margin-right: 4px;
                font-size: .75rem;
                color: var(--color-primary);
            }

            > span {
                margin: auto 0;
                font-weight: bold;
                white-space: nowrap;
            }
        }
        
        .info-columns-frame {
            display: flex;
            width: calc(100% - 202px);

            overflow: hidden;
            margin-right: auto;

            background-color: #ff00006a;
        }

        .group-selection-btn {
            display: flex;
            align-items: center;
            
            margin-left: -6px;
            padding: 0 0 0 6px;
            
            border: 1px solid var(--grey-light);
            border-radius: 6px;

            cursor: pointer;

            background-color: var(--white);

            :active {
                background-color: var(--grey-lightest);
            }
            
            :focus-visible {
                outline: none;
            }

            i {
                margin-right: 8px;
                font-size: .65rem;
            }
        }
    }

    .list-frame {
        height: calc(100% - 12px);
        max-height: calc(100% - 16px);
        width: calc(100% - 2px); // 100% minus the border width

        margin-top: 6px;

        border: 1px solid var(--grey-light);
        border-radius: 8px;

        background-color: var(--white);

        overflow-y: auto;

        position: relative;

        .row-frame {
            min-height: 30px;

            padding: 2px 4px 2px 12px;

            align-items: center;

            :hover {
                background-color: #edffec;
                cursor: pointer;
            }

            .row-item {
                width: auto;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                color: var(--grey-medium);
                
                :not(:first-child) {
                    margin-left: 22px;
                }
                
                :first-child {
                    margin-left: 4px;
                }
            }
            
            input {
                margin-right: 8px;
            }
        }

        .action-frame {
            display: flex;
            height: 100%;
            width: fit-content;

            background-color: transparent;
            border: none;
            margin: 0;
            margin-right: 4px;
            padding: 0;

            &.action-disabled {

                > i {
                    color: var(--grey-light);

                    :hover {
                        color: var(--grey-light) !important;
                        cursor: default !important;
                    }
                }
            }

            :hover {
                cursor: pointer;
            }

            :focus-visible {
                outline: none;
            }
        }

        .actions-frame {
            width: 100%;
            min-width: 60px;
            
            margin-left: 28px;
            
            display: flex;
        }

        .loading, .end-message {
            height: 40px;
            display: grid;
            place-items: center;
            color: var(--grey-regular);
        }
    }
`;

export interface TableAction {
    html: ReactElement,
    tooltipText: string,
    disabled: (tooltipText: string, statusLabel: string) => boolean,
    callback?: Function,
    requiredPermissions?: string[]
}

export interface Column {
    header: string,
    accessor: string,
    orderAccessor?: string,
    minWidth: number,
    isActive: boolean,
    customView?: ReactElement | Function
}

export interface OrderInfo {
    columnOrdered: Column;
    direction: "ASC" | "DESC";
}

interface TableProps {
    data: Array<any>,
    columns: Array<Column>,
    orderInfo?: OrderInfo,
    actionsConfig?: {
        actions: TableAction[],
        minWidth: number
    },
    disableSelection?: boolean;
    onSortByColumn?: (col: Column) => void,
    onSelectionChange?: Function,
    handleFetchMore?: Function,
    hasMore?: boolean,
    batchActions?: {
        label?: ReactNode | HTMLElement | string,
        actionCallback(ids: number[]): void,
        isDisabled?: boolean
    }[]
}

const Table = (props: TableProps) => {

    const [data, setData] = useState(props.data);

    const [columns, setColumns] = useState(props.columns);

    const [allSelected, setAllSelected] = useState(false);

    useEffect(() => {
        setColumns(props.columns);
        setData(props.data);
    }, [props.columns, props.data])

    /**
     * Gets the correct arrow icon to be used on the sorted column layout
     * @param column 
     * @returns the html code specific to the arrow desired
     */
    const getSortArrow = (column: Column) => {
        if (props.orderInfo?.columnOrdered && column.accessor === props.orderInfo?.columnOrdered.accessor) {
            return <i className={`bi bi-caret-${(props.orderInfo?.direction === "ASC") ? 'up' : 'down'}-fill`}></i>
        }
    }

    /**
     * Sets the list's column to be sorted by and the sort direction
     * @param column column clicked by the user
     */
    const handleSetSortDirection = (column: Column) => {

        // Component state control
        setColumns(columns.map(col => {
            if (col.accessor === column.accessor) {
                !!props.onSortByColumn && props.onSortByColumn(col);

                return col;
            } else {
                return {
                    accessor: col.accessor,
                    header: col.header,
                    minWidth: col.minWidth,
                    isActive: col.isActive,
                    customView: col.customView
                }
            }
        }));
    }

    const handleToggleAll = (checked: boolean) => {
        setAllSelected(!allSelected);
        if (checked) {
            const auxData = data.map(item => { return { ...item, isSelected: item.disableSelection ? false : true } });
            setData(auxData);
            props.onSelectionChange && props.onSelectionChange(auxData);
        } else {
            setData(data.map(item => { return { ...item, isSelected: false } }));
            props.onSelectionChange && props.onSelectionChange([]);
        }
    }

    const handleRowSelection = (row: any, index: number) => {
        const changedData = data.map((item, i) => {
            if (index === i) {
                return { ...item, isSelected: item.disableSelection ? item.isSelected : !item.isSelected }
            }

            return item;
        })

        props.onSelectionChange && props.onSelectionChange(changedData.filter(item => item.isSelected));
        setData(changedData);
    }

    const [actionsEl, setActionsEl] = useState<HTMLButtonElement | null>(null);

    const handleActionsBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if ((event.target as any).type === 'checkbox') {
            return;
        }
        setActionsEl(event.currentTarget);
    };

    const handleClose = () => {
        setActionsEl(null);
    };

    const defineColValue = (colValue) => {
        if (typeof colValue === 'number') {
            return colValue || '0';
        }
        return colValue || 'N/A';
    }

    const open = Boolean(actionsEl);
    const id = open ? 'simple-popover' : undefined;

    const groupSelectionComponent = <>
        <button className="group-selection-btn"
            aria-describedby={id}
            onClick={(event) => handleActionsBtnClick(event)}>
            <input type="checkbox" checked={allSelected} onChange={(event) => handleToggleAll(event.target.checked)} />
            <i className="bi bi-caret-down-fill" />
        </button>
        <Popover
            id={id}
            open={open}
            anchorEl={actionsEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}>
            {props.batchActions && props.batchActions.map((action, index) => (
                <p className={action.isDisabled ? 'menu-item disabled' : 'menu-item'} key={index} onClick={() => action.actionCallback(data.filter(item => item.isSelected).map(({ id }) => (id as number)))}>{action.label}</p>
            ))}
        </Popover>
    </>;

    // -------------------------------------
    // RETURN
    // -------------------------------------
    return <StyledTable listLength={props.data.length}>

        {/* HEADER */}
        <section className="header-frame">
            {
                !props.disableSelection && groupSelectionComponent
            }
            {
                props.actionsConfig?.actions ? <div className="header-item-frame"
                    style={{
                        minWidth: props.actionsConfig.minWidth,
                        width: props.actionsConfig.minWidth
                    }}>
                    <span>Ações</span>
                </div> : ''
            }
            {
                columns.map((column, index) => {
                    return column.isActive && <div key={index} className="header-item-frame" style={{ minWidth: column.minWidth }} onClick={() => handleSetSortDirection(column)}>
                        <span>{column.header}</span>
                        {getSortArrow(column)}
                    </div>
                })
            }
        </section>

        {/* LIST BODY */}
        <section id="infinite-scroll-frame-table" className="list-frame" style={{ display: 'flex', flexDirection: 'column' }}>
            <InfiniteScroll
                dataLength={data.length}
                next={() => props.handleFetchMore && props.handleFetchMore()}
                hasMore={props.hasMore ? props.hasMore : false}
                scrollThreshold="40px"
                scrollableTarget="infinite-scroll-frame-table"
                loader={
                    !!data.length && <div className="loading">
                        Buscando mais itens...
                        {/* <ReactLoading type={"spinningBubbles"} color={"#01454233"} height={50} width={50} /> */}
                    </div>
                }
                endMessage={
                    !!data.length && <div className="end-message" style={{ pointerEvents: 'none' }}>
                        Fim dos resultados
                    </div>
                }
            >
                {
                    // Adding every row
                    data.length ? data.map((row, index) => {
                        return <div
                            key={index}
                            className="row-frame"
                            style={{ display: 'flex', backgroundColor: `${index % 2 > 0 ? 'var(--grey-lighter)' : 'white'}` }}
                            onClick={() => handleRowSelection(row, index)}>
                            {
                                // Adding the row's checkbox
                                !props.disableSelection && <input type="checkbox" disabled={row.disableSelection ? true : false} checked={(row.isSelected === undefined) ? false : row.isSelected ? true : false} onChange={() => handleRowSelection(row, index)} />
                            }
                            {
                                // Adding actions to the each row
                                props.actionsConfig ? <div className="actions-frame" style={{
                                    minWidth: props.actionsConfig.minWidth,
                                    width: props.actionsConfig.minWidth
                                }}>
                                    {
                                        props.actionsConfig.actions.map((action, index) => {

                                            let label = satStatus.filter(st => st.value?.includes((row as SAT).status || ""))[0]?.label;
                                            let button = <button
                                                disabled={action.disabled(action.tooltipText, label)}
                                                className={`action-frame ${action.disabled(action.tooltipText, label) && "action-disabled"}`}
                                                onClick={(event) => { action.callback && action.callback(event, row); event.preventDefault(); event.stopPropagation(); }}
                                                key={index}
                                            >
                                                {action.html}
                                            </button>;

                                            return <PermissionControlOptional key={index} requiredPermissions={action.requiredPermissions}>{!action.disabled(action.tooltipText, label) ? <Tooltip title={action.tooltipText} placement="top" key={index}>
                                                {button}
                                            </Tooltip> : button}</PermissionControlOptional>;
                                        })
                                    }
                                </div> : <></>
                            }
                            {
                                // Adding every column to each row
                                columns.map(col => {
                                    if (col.isActive) {
                                        if (col.customView) {
                                            if (typeof col.customView === 'function') {
                                                return <div key={col.accessor} className="row-item" style={{ minWidth: col.minWidth, width: '100%' }}>{(col.customView && col.customView(row)) || (row[col.accessor] || "N/A")}</div>;
                                            }
                                            return col.customView;
                                        } else {
                                            return <div key={col.accessor} className="row-item" style={{ minWidth: col.minWidth, width: '100%' }}>{defineColValue(row[col.accessor])}</div>;
                                        }
                                    }
                                    return null;
                                })
                            }
                        </div>
                    }) : <div className="loading">Nenhum resultado encontrado</div>
                }
            </InfiniteScroll>
        </section>
    </StyledTable>;
}

export default Table;