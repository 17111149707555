import { Company, CompanyBranch } from "./context";

export interface Action {
    type: string,
    payload: any
};
interface AppState {
    accessToken: string,
    selectedCompany: {
        id: number,
        codigoEmpresa: string,
        codigoEstabelecimento: string,
        cnpj: string,
        razaoSocial: string
    },
    branchesList: CompanyBranch[]
    rootCompaniesList: Company[]
}

const reducer = (state: AppState, action: Action): AppState => {

    switch (action.type) {
        case "UPDATE_TOKEN":
            return {
                ...state,
                accessToken: action.payload
            };

        case "SET_SELECTED_COMPANY":
            return {
                ...state,
                selectedCompany: action.payload
            };

        case "SET_ROOT_COMPANIES":
            return {
                ...state,
                rootCompaniesList: action.payload
            }

        default:
            throw new Error();
    }

};

export default reducer;