import { sub } from "date-fns";

export class NFeFilter {
    id: string = '';
    status: string = '';
    statusLabel: string = 'Todos';
    dataInicialEmissao: string = '';
    dataFinalEmissao: string = '';
    empresaId: number | null = null;
    numDoc: string = "";
    docDestinatario: string = "";
    vlrMinNf: number | null = null;
    vlrMaxNf: number | null = null;
    serie: string = "";
    chaveNf: string = "";
    branchesIds: string | null = null;
    branchesCodes: string | null = null;
    companyId: number | null = null;
    ids: string = "";
    inutilizada: boolean | null = null;

    initialDateObj?: Date = sub(new Date(), { months: 1 });
    finalDateObj?: Date = new Date();
}