import React from 'react';
import DootaxLogo from "../../utils/images/marca_dootax_fundoclaro.svg";
import { StyledFrame } from './styles';

interface SplashPageProps {
    loading?: boolean;
    message?: string;
}

const SplashPage: React.FC<SplashPageProps> = ({ loading = true, message = null }) => {

    return <StyledFrame>
        <section className="app-frame">
            <div className="app-header">
                <img src={DootaxLogo} />
            </div>

            <div className="app-loader-container">
                {message && (<p>{message}</p>)}
                {loading && (<div className="loader"></div>)}
            </div>
        </section>

        <div className="background-gradient"></div>
    </StyledFrame>;
}

export default SplashPage;