import React, { createContext, useState, useEffect, useContext } from 'react';
import { AuthInfo, authenticate, saveAuthInfo, getAuthInfo, logout, shouldAuthenticateAgain, authenticateOneClick } from '@services/AuthenticationService';
import { getTenantLoginInfo, TenantInfo } from '@services/TenantService';

import api, { ApplicationError, refreshTokenSSO } from '../api/Api';
import { AxiosError } from 'axios';
import { decodeToken } from 'react-jwt';

interface AuthContextData {
  authInfo: AuthInfo | null;
  authenticated: boolean;
  loading: boolean;
  error?: string;
  tenantInfo: TenantInfo | null;
  updateTenantInfo(tenantAlias: string): void;
  oneClickSignIn(token: String, tenantAlias: string): Promise<void>;
  signIn(tenantAlias: string, username: string, password: string): Promise<void>;
  signOut(): void;
  signInSSO(): Promise<AuthInfo>;
}

const AuthContext = createContext<AuthContextData>({ loading: true, authenticated: false } as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [authInfo, setAuthInfo] = useState<AuthInfo | null>(null);
  const [tenantInfo, setTenantInfo] = useState<TenantInfo | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();

  useEffect(() => {
    const storedAuthInfo = getAuthInfo();

    if (!!storedAuthInfo) {
      getTenantLoginInfo(storedAuthInfo.tenantAlias)
        .then(info => {
          setTenantInfo(info);

          if (!shouldAuthenticateAgain(storedAuthInfo)) {
            setAuthInfo(storedAuthInfo);
            api.defaults.headers.common['Authorization'] = `Bearer ${storedAuthInfo.token}`;
          }

          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  const updateTenantInfo = (tenantAlias: string): void => {
    setLoading(true);
    setError(undefined);

    getTenantLoginInfo(tenantAlias)
      .then(info => {
        setTenantInfo(info);
        setLoading(false);
      }).catch(error => {
        const { detail } = (error as AxiosError).response?.data as ApplicationError;
        setError(detail);
        setLoading(false);
      });
  }

  const signIn = async (tenantAlias: string, username: string, password: string): Promise<void> => {
    const response = await authenticate(tenantAlias, username, password);
    handleAuthInfo(response);
  }

  const oneClickSignIn = async (token: string, tenantAlias: string): Promise<void> => {
    const response = await authenticateOneClick(token, tenantAlias);
    handleAuthInfo(response);
  }

  const signOut = (): void => {
    logout();
  }

  const signInSSO = async (): Promise<AuthInfo> => {
    const authInfoRequest = await refreshTokenSSO();
    const authInfo = authInfoRequest.data;
    handleAuthInfo(authInfo);
    updateTenantInfo(authInfo.tenantAlias)
    return authInfo;
  }

  const handleAuthInfo = (authInfo: AuthInfo) => {
    const decodedToken = decodeToken(authInfo.token) as any;
    authInfo.permissions = decodedToken.permissions;
    setAuthInfo(authInfo);
    saveAuthInfo(authInfo);
  }


  return (
    <AuthContext.Provider value={{ authInfo, authenticated: !!authInfo, signIn, oneClickSignIn, signOut, tenantInfo, loading, updateTenantInfo, error, signInSSO }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): AuthContextData => {
  const context = useContext(AuthContext);

  return context;
}