import api from '../api/Api';

import { NFCeInutilizacao } from '@models/nfce/NFCeInutilizacao';
import { PageResponse } from '@models/PageResponse';
import { NFCeInutilizacaoFilter } from '@models/nfce/NFCeInutilizacaoFilter';
import { Column } from '@components/table/table';
import fileDownload from 'js-file-download';

export const findByFilter = async (filter: NFCeInutilizacaoFilter, { direction: orderDirection, columnOrdered: { accessor: orderBy } }: SortInfo, page: number = 0): Promise<PageResponse<NFCeInutilizacao>> => {
  try {
    return api.get<PageResponse<NFCeInutilizacao>>('/nfce/inutilizacao', { params: { ...filter, page, sort: `${orderBy},${orderDirection}` } }).then(({ data }) => data);
  } catch (err) {
    throw new Error("Ocorreu um erro ao tentar buscar a listagem de inutilizações.");
  }
}

export const inutilize = async (request: InutilizarNumeracaoRequest): Promise<InutilizarNumeracaoResponse> => {
  try {
    return api.post<InutilizarNumeracaoResponse>('/nfce/inutilizacao', request).then(({ data }) => data);
  } catch (err) {
    throw new Error("Ocorreu um erro inutilizar a numeração.");
  }
}

export interface SortInfo {
  columnOrdered: Column;
  direction: string;
}

export interface InutilizarNumeracaoRequest {
  companyId: number;
  year: number;
  series: number;
  initialNum: number;
  finalNum: number;
  justification: string;
}

export interface InutilizarNumeracaoResponse {
  status: string;
  message: string;
}

export const exportNFCes = async (filters: NFCeInutilizacaoFilter) => {
  try {
      const res = await api.get('/nfce/inutilizacao/export', { responseType: 'blob', params: filters });

      fileDownload(res.data, res.headers.filename)

  } catch (err) {
      console.log("Failed to export NFCes. ", err);
      return null;
  }
};