import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DootaxLogo from "../../utils/images/marca_dootax_fundoclaro.svg";
import { StyledFrame } from './styles'
import { useAuth } from '@contexts/auth';
import { CircularProgress } from "@mui/material";
import { useQuery } from "@hooks/useQuery";

const OneClickLoginPage = () => {
    const history = useHistory();
    const query = useQuery();
    const { tenant: tenantAlias } = useParams<{ tenant: string }>();
    const { oneClickSignIn, tenantInfo, updateTenantInfo, error } = useAuth();
    const [signingIn, setSigningIn] = useState<boolean>(false);

    useEffect(() => {
        const oneClickLoginToken = query.get("token");

        if (!oneClickLoginToken) {
            toast.error("É necessário informar um token válido.");
            return;
        }

        if (tenantInfo?.tenantAlias !== tenantAlias && !error) {
            updateTenantInfo(tenantAlias);
        } else {
            if (!tenantInfo || !tenantInfo.tenantAlias) {
                toast.error("É necessário informar um cliente válido.");
                return;
            }

            setSigningIn(true);

            oneClickSignIn(oneClickLoginToken, tenantAlias)
                .then(() => {
                    setSigningIn(false);
                    history.replace(`/${tenantAlias}/home`);
                })
                .catch((err) => {
                    setSigningIn(false);
                    toast.error(err.message);
                });
        }
    }, [tenantAlias]);

    return <StyledFrame>
        <section className="login-frame">
            <div className="login-header">
                <img src={DootaxLogo} alt="Logo da Dootax" />
            </div>

            <div className="login-body">
                {!!error && (<p style={{ textAlign: 'center' }}>{error}</p>)}
                {!error && (
                    <>
                        <div className="label-frame">
                            <h3 className="heading"><strong>Entrar</strong></h3>
                            <small>({tenantInfo?.tenantName})</small>
                        </div>

                        {signingIn && <CircularProgress size={14} style={{ 'color': '#19ea81' }} />}
                    </>)}
            </div>
        </section>

        <div className="background-gradient"></div>
    </StyledFrame>;
}

export default OneClickLoginPage;