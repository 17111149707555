export interface NFCeFilterStatus {
    label: string,
    value: string | null,
    refColor: string,
    code: string | null,
    active: boolean,
    visible: boolean,
    id: number
}

export const nfceFilterStatus: NFCeFilterStatus[] = [
    {
        label: 'Todos',
        value: null,
        refColor: '#000',
        code: null,
        active: true,
        visible: true,
        id: 1
    },
    {
        label: 'Em Processamento', 
        value: 'EM_PROCESSAMENTO', 
        refColor: '#3149d1',
        code: 'EM_PROCESSAMENTO',
        active: true,
        visible: true,
        id: 2
    },
    {
        label: 'Falha na emissão',
        value: 'FALHA_EMISSAO',
        refColor: '#f00d0d',
        code: 'FALHA_EMISSAO',
        active: true,
        visible: true,
        id: 3
    },
    {
        label: 'NFCe Substituída',
        value: 'NFCE_SUBSTITUIDA',
        refColor: '#f78d1b',
        code: 'NFCE_SUBSTITUIDA',
        active: true,
        visible: true,
		id: 4
    },
    {
        label: 'Emitido', 
        value: 'EMITIDO', 
        refColor: '#41c718',
        code: 'EMITIDO',
        active: true,
        visible: true,
        id: 5
    },
	{
        label: 'Cancelado', 
        value: 'CANCELADO', 
        refColor: '#f78d1b',
        code: 'CANCELADO',
        active: true,
        visible: true,
        id: 6
    }
];