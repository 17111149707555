import { useEffect, useRef, useState, KeyboardEvent } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DootaxLogo from "../../utils/images/marca_dootax_fundoclaro.svg";
import { StyledFrame } from './styles'
import { useAuth } from '@contexts/auth';
import { CircularProgress } from "@mui/material";
import { getConfigs } from "@config/environment";

const LoginPage = () => {
    const usernameInputRef = useRef(null);
    const passwordInputRef = useRef(null);
    const history = useHistory();
    const { tenant: tenantAlias } = useParams<{tenant: string}>();
    const { signIn, tenantInfo, updateTenantInfo, error } = useAuth();
    const [signingIn, setSigningIn] = useState<boolean>(false);
    const { ssoURL } = getConfigs();

    useEffect(() => {
        if (tenantInfo?.tenantAlias !== tenantAlias && !error) {
            updateTenantInfo(tenantAlias);
        }
    }, [tenantAlias]);

    const handleLoginAction = () => {
        const username = usernameInputRef.current ? (usernameInputRef.current as any).value : "";
        const password = passwordInputRef.current ? (passwordInputRef.current as any).value : "";

        if (!username) { toast.error("Preencha o campo usuário."); return };
        if (!password) { toast.error("Preencha o campo senha."); return };

        if (!tenantInfo || !tenantInfo.tenantAlias) {
            toast.error("É necessário informar um cliente válido.");
            return;
        }

        setSigningIn(true);

        signIn(tenantInfo.tenantAlias, username, password)
            .then(() => {
                setSigningIn(false);
                history.replace(`/${tenantAlias}/home`);
            })
            .catch((err) => {
                setSigningIn(false);
                toast.error(err.message);
            });
    }

    const handleOnKeyPress = (event: KeyboardEvent) => {
        if (event.key === "Enter") {
            handleLoginAction();
        }
    }

    const handleSso = () => {
        window.open(`${ssoURL}${tenantInfo?.idp}&tenant=${tenantInfo?.tenantAlias}`, "_self");
    }

    return <StyledFrame>
        <section className="login-frame">
            <div className="login-header">
                <img src={DootaxLogo} />
            </div>

            <div className="login-body">
                {!!error && (<p style={{ textAlign: 'center' }}>{error}</p>)}
                {!error && (
                    <>
                        <div className="label-frame">
                            <h3 className="heading"><strong>Entrar</strong></h3>
                            <small>({tenantInfo?.tenantName})</small>
                        </div>

                        <input className="input-text" ref={usernameInputRef} type="text" placeholder="USUÁRIO" onKeyPress={handleOnKeyPress}/>
                        <input className="input-text" ref={passwordInputRef} type="password" placeholder="SENHA" onKeyPress={handleOnKeyPress}/>

                        {tenantInfo?.idp && ( <div className="sso-container"><button className="sso-button" onClick={handleSso}>Single Sign-On</button></div>)}

                        <button disabled={signingIn} className="login-button" onClick={handleLoginAction}>
                            {signingIn ? (<CircularProgress size={14} style={{ 'color': '#19ea81' }} />) : 'Entrar'}
                        </button>
                    </>)}
            </div>
        </section>

        <div className="background-gradient"></div>
    </StyledFrame>;
}

export default LoginPage;