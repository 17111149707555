import { CircularProgress, Popover, Tooltip } from "@mui/material";
import SATListingApi from "api/SATListingApi";
import { formatToBRL, formatToCPFOrCNPJ } from "brazilian-values";
import Filter from "components/filter/satFilter";
import Modal from "components/modal/modal";
import Table, { Column, OrderInfo, TableAction } from "components/table/satTable";
import { SATFilter } from "model/sat/SATFilter";
import { satFilterStatus } from "model/sat/SATFilterStatus";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useRef } from "react";
import PageFrame from "components/page-frame/pageFrame";
import { getItem, setItem } from '@services/LocalStorageService';
import PDFViewer from "@components/pdf/pdfViewer";
import HistoryDetails from "@components/historyDetails/historyDetails";
import fileDownload from "js-file-download";
import { useAuth } from '@contexts/auth';
import { PERMISSIONS } from "@utils/permissions";
import { SAT } from "@models/sat/SAT";

const StyledFrame = styled.div`
    width: 100%;
    height: 100%;

    position: relative;

    display: flex;
    flex-direction: column;

    .centered {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .body {
        width: 100%;
        height: 100%;
        max-height: 100%;

        display: flex;
        flex-direction: column;
        position: relative;

        .customize-columns-toggle {
            height: 32px;
            width: fit-content;
            
            padding: 0 8px;

            margin-left: auto;
            
            border: 1px solid var(--grey-light);
            border-radius: 8px;
            
            display: flex; 
            align-items: center; 
            justify-content: center;
            
            user-select: none;
            font-family: 'TitilliumWeb';
            
            cursor: pointer;

            background-color: var(--white);

            :active {
                background-color: var(--grey-lightest);
            }

            :focus-visible {
                outline: none;
            }

            > span {
                text-transform: uppercase;
                font-size: .7rem;
                font-weight: bold;
                letter-spacing: .3px;
            }
        }
    }
    
    .correcao-frame {
        display: flex;
        flex-direction: column;

        > span {
            margin-bottom: 12px;
        }

        > textarea {
            min-width: 550px;
            min-height: 70px;
        }
    }

    .hist-item-frame {
        height: fit-content;
        display: flex;
        align-items: top;

        .data-text {
            white-space: nowrap;
            margin-right: 16px;
        } 
        
        .status-indicator {
            height: 6px;
            width: 6px;
            border-radius: 50%;
            margin-top: 6px;
            margin-left: 8px;
            margin-right: 8px;
            margin-bottom: 6px;
            border: 3px solid var(--color-success);
        }

        .status-link-bar {
            width: 3px;
            min-height: 100%;
            background-color: var(--color-success);
            border-radius: 6px;
            margin-top: 7px;
            margin-left: 12px;
            margin-right: 12px;
        }
    }

    .div-table-cell {
        display: table-cell;
        vertical-align: top;
        padding-left: 10px;
        padding-bottom: 10px;
        padding-top: 15px;
    }

    .line-separator {
        display: table-cell;
        border-left: none;
        border-top: var(--grey-light);
    }
`;

const ModalFrame = styled.div`
        top: 6px;
        left: -14px;
        width: 100%;
        position: absolute;
        transform: translateY(-96px);
        z-index: 1000;
`;

const PAGE_SIZE = 30;

interface PageAndSort {
    sort: OrderInfo;
    page: number;
}

const getFilterStatus = (statusValue: string) => {
    const auxStatus = satFilterStatus.filter(s => s.value?.toUpperCase() === statusValue.toUpperCase())[0];
    if (auxStatus) {
        return auxStatus;
    }
}

/**
    * Builds the status layout for each individual SAT item on the list with the apropriate color
    * @param satItem represents the SAT being render on screen
    * @returns a custom JSX element for a SAT's status
    */
const getStatusLayout = (satItem: SAT) => {
	
    if (satItem.reportStatus) {
		let status = getFilterStatus(satItem.reportStatus);

		return <div style={{ display: 'flex', alignItems: 'center' }}>
			<div style={{
				borderRadius: '50%',
				width: '6px',
				height: '6px',
				marginRight: '6px',
				backgroundColor: status?.refColor
			}}></div>
			<span>{status?.label}</span>
		</div>;
	}

    return <div>{satItem.status}</div>;
}

const ListPage = () => {

    /**
         * @param satItem SAT to get the recipient's name from
         * @returns A custom view including a tooltip for recipients names in the list
         */
    const recipientNameToolTip = (satItem: SAT) => <Tooltip title={satItem.recipientName || "N/A"} placement="top">
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{satItem.recipientName}</div>
    </Tooltip>

    const [columns, setColumns] = useState<Column[]>([
        { header: 'CNPJ Emitente', accessor: 'company.cnpj', isActive: true, minWidth: 125, customView: (value: any) => value.issuerCnpj },
        { header: 'ID Transação', accessor: 'transactionId', isActive: false, minWidth: 210 },
        { header: 'Data de emissão', accessor: 'issueDate', isActive: true, minWidth: 130 },
        { header: 'Nº Documento', accessor: 'docNum', isActive: true, minWidth: 80 },
        { header: 'Série SAT', accessor: 'series', orderAccessor: "serialNumber", isActive: true, minWidth: 70 },
        { header: 'Valor', accessor: 'nfAmount', orderAccessor: "cfAmount" ,isActive: true, minWidth: 85 },
        { header: 'Status', accessor: 'reportStatus', orderAccessor: "status", isActive: true, minWidth: 140, customView: getStatusLayout },
        { header: 'Chave de Acesso', accessor: 'accessKey', orderAccessor: "accessKey", isActive: false, minWidth: 340 },
        { header: 'Nome Destinatário', accessor: 'recipientName', isActive: true, minWidth: 160, customView: recipientNameToolTip },
        { header: 'Doc. Destinatário', accessor: 'recipientTaxId', isActive: true, minWidth: 125 },
    ]);

    const listingApi = new SATListingApi();

    const initialRender = useRef(true);

    const [hasMore, setHasMore] = useState(true);

    const [filters, setFilters] = useState(new SATFilter());

    const [data, setData] = useState<SAT[]>([]);

    const [pageAndSort, setPageAndSort] = useState<PageAndSort>({ sort: { columnOrdered: columns[2], direction: "DESC" }, page: 0 })

    const [totalAmountSATs, setTotalAmountSATs] = useState(0);

    const [selectedSATs, setSelectedSATs] = useState<SAT[]>([]);

    const [activeSAT, setActiveSAT] = useState<SAT>();

    const [downloadOptionsOpen, setDownloadOptionsOpen] = useState(false);

    const [visualizarExtratoOpen, setVisualizarExtratoOpen] = useState(false);

    const [satHistoryDialogOpen, setSatHistoryDialogOpen] = useState(false);

    const [loadingDownload] = useState<boolean>(false);
    const [loadingHistory, setLoadingHistory] = useState<boolean>(false);
    const [exportingBills, setExportingBills] = useState<boolean>(false);
    const [downloadingXml, setDownloadingXml] = useState<boolean>(false);

    const { authInfo } = useAuth();
    const [pdf, setPdf] = useState<any>();
    const [pdfFilename, setPdfFilename] = useState<any>();

    const satActiveColumn: string = `sat-columns-active-${authInfo?.username}`

    const validDownloadStatuses: string[] = ['AUTORIZADO', 'CANCELADO'];

    const actions = useMemo<TableAction[]>(() => [
        {
            html: <i className="bi bi-download table-action action-positive"></i>,
            callback: (event: Event, sat: SAT) => { return handleDownloadClick(event, sat); },

            disabled: (actionTooltipText: string, statusLabel: string) => disableAction(actionTooltipText, statusLabel),
            tooltipText: 'Baixar arquivos',
            requiredPermissions: [PERMISSIONS.DOOTAX.NFCE.GERENCIAR.VISUALIZAR]
        },
        {
            html: <i className="bi bi-clock-history table-action action-positive" />,
            callback: (event: Event, sat: SAT) => handleShowSATHistory(event, sat),
            disabled: (actionTooltipText: string, statusLabel: string) => disableAction(actionTooltipText, statusLabel),
            tooltipText: 'Visualizar histórico',
            requiredPermissions: [PERMISSIONS.DOOTAX.NFCE.GERENCIAR.VISUALIZAR]
        }
    ], []);

    useEffect(() => {
        const savedColumnsConfig = getItem(satActiveColumn);
        if (savedColumnsConfig) {
            setColumns(columns.map(col => ({ ...col, isActive: savedColumnsConfig.includes(col.accessor) })));
        } else {
            setItem(satActiveColumn, columns.map(col => col.accessor).join(','));
        }
    }, []);

    const setFiltersAndResetPageNum = (updatedFilter: SATFilter) => {
        setFilters(updatedFilter);
        setPageAndSort({ ...pageAndSort, page: 0 });
    }

    const executeSearch = ({ concatResults }: { concatResults: boolean }) => {
        const { sort: sortInfo, page: pageIndex } = pageAndSort;

        listingApi.fetchFilteredSATs(filters, pageIndex, PAGE_SIZE, sortInfo.columnOrdered.orderAccessor || sortInfo.columnOrdered.accessor, sortInfo.direction).then(({ content, totalElements, last }) => {
            setTotalAmountSATs(totalElements);
            setHasMore(!last);

            const newData = content.map(nfce => ({
                ...nfce,
                companyId: (nfce as any).company.id,
                issuerCnpj: formatToCPFOrCNPJ((nfce as any).company.cnpj),
                recipientTaxId: formatToCPFOrCNPJ(nfce.recipientTaxId || ''),
                nfAmount: formatToBRL(nfce.nfAmount || '0'),
                isSelected: false,
                disableSelection: !validDownloadStatuses.includes(nfce.status!)
            }));

            setData(concatResults ? [...data, ...newData] : newData);
        });
    }

    const handleFilter = useCallback(() => executeSearch({ concatResults: false }), [filters, pageAndSort]);

    useEffect(() => {

        if (initialRender.current) {
            initialRender.current = false;
        } else {
            executeSearch({ concatResults: pageAndSort.page !== 0 });
        }
    }, [pageAndSort]);

    useEffect(() => {
        selectedSATs.length && setData(data.map(item => {
            return {
                ...item,
                isSelected: (selectedSATs.find(i => (i as any).id === item.id) !== undefined)
            }
        }));
    }, [selectedSATs]);

    /**
     * Checks if an action should be disabled for a specific NFCe based on it's status
     * @param tooltipText 
     * @param statusLabel 
     * @returns true if the action should be enabled for a specific NFCe
     */
    const disableAction = (tooltipText: string, statusLabel: string) => {
        switch (tooltipText.toLowerCase()) {

            case "baixar arquivos":
                return statusLabel.toLowerCase() !== "autorizado";

            default:
                return false;
        }
    }

    const handleExportNFCes = () => {
        setExportingBills(true);

        listingApi.exportSATs(filters)
            .then(() => toast.success('CFe SAT\'s exportados com sucesso!'))
            .finally(() => setExportingBills(false));
    }

    const handlePrint = (pdfBytes: Blob) => {
        var pdfUrl = URL.createObjectURL(pdfBytes);
        var printwWindow = window.open(pdfUrl);
        printwWindow!.print();
    }

    const handleDownloadClick = (event: Event, sat: SAT) => {
        setActiveSAT(sat);
        setDownloadOptionsOpen(true);
        event.stopPropagation();
    }

    const handleVisualizarExtratoClick = (sat: SAT) => {
        setActiveSAT(sat);

        listingApi.downloadExtrato(sat).then(res => {
            if (res) {
                setPdf(res.data)
                setPdfFilename(res.headers.filename);
            } else {
                setPdf(undefined)
                setPdfFilename(undefined);
            }
        });

        setVisualizarExtratoOpen(true);
        setDownloadOptionsOpen(false);
    }

    const handleShowSATHistory = (event: Event, sat: SAT) => {
        if (!sat || !sat.id) {
            toast.error('É necessário selecionar um CFe SAT para visualizar seu histórico.');
            return;
        }

        setSatHistoryDialogOpen(true);
        setLoadingHistory(true);

        listingApi.fetchSATHistory(sat.id)
            .then(res => {
                setLoadingHistory(false);
                setActiveSAT({ ...sat, history: res.data });
            }).catch(() => setLoadingHistory(false));

        event.stopPropagation();
    }

    const handleToggleColumn = (column: Column) => {
        const savedColumnsConfig = getItem(satActiveColumn)?.split(',');

        if (!savedColumnsConfig || !savedColumnsConfig.length) {
            setItem(satActiveColumn, column.accessor);
        } else {
            if (savedColumnsConfig.includes(column.accessor)) {
                savedColumnsConfig.splice(savedColumnsConfig.indexOf(column.accessor), 1);
            } else {
                savedColumnsConfig.unshift(column.accessor);
            }

            setItem(satActiveColumn, savedColumnsConfig ? savedColumnsConfig.join(',') : '');
        }
    }

    /**
     * Shows/hides a table column selected by the user
     * @param column selected/unselected column
     */
    const shouldActivateColumn = (column: Column) => {
        setColumns(columns.map(col => {
            if (column.accessor === col.accessor) {
                col.isActive = !column.isActive
                handleToggleColumn(col);
            }
            return { ...col };
        }))
    }

    const [actionsEl, setActionsEl] = useState<HTMLButtonElement | null>(null);

    const handleActionsBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setActionsEl(event.currentTarget);
    };

    const handleClose = () => {
        setActionsEl(null);
    };

    const open = Boolean(actionsEl);
    const id = open ? 'simple-popover' : undefined;

    const customizeColumnsBtn = <>
        <button
            className="customize-columns-toggle"
            aria-describedby={id}
            onClick={(event) => handleActionsBtnClick(event)}>
            <span>Personalizar colunas</span>
            <i className="bi bi-caret-down-fill" style={{ fontSize: '.75rem', marginLeft: '8px' }} />
        </button>
        <Popover
            id={id}
            open={open}
            anchorEl={actionsEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}>
            <div className='customize-columns-menu'>
                <div className="menu-header">
                    <span>Selecione as colunas que deseja exibir na lista:</span>
                </div>
                <div className="menu-items-frame">
                    {
                        columns.slice().sort((a, b) => {
                            if (a.header === b.header) return 0;
                            if (a.header > b.header) return 1;
                            if (a.header < b.header) return -1;
                            return 0;
                        }).map((col, index) => {
                            return <div key={index} style={{ width: 'fit-content', display: 'flex', padding: '10px', alignItems: 'center' }}>
                                <input type="checkbox"
                                    checked={col.isActive}
                                    onChange={() => shouldActivateColumn(col)}
                                />
                                <span>{col.header}</span>
                            </div>
                        })
                    }
                </div>
            </div>
        </Popover>
    </>;



    const handleSortByColumn = (col: Column) => {
        const { sort: sortInfo } = pageAndSort;

        setPageAndSort({
            page: 0,
            sort: {
                columnOrdered: col,
                direction: (col.accessor !== sortInfo.columnOrdered.accessor) || sortInfo.direction === "DESC" ? "ASC" : "DESC"
            }
        });
    }

    const handleDownloadXml = (sat: SAT) => {
        if (!sat) {
            toast.error('É necessário selecionar um CFe SAT para realizar o download do XML.');
            return;
        }

        setDownloadingXml(true);

        listingApi.downloadXml(sat).then(res => {
            toast.info(res)

            if (res) {
                if (res.status === 204) {
                    toast.info("Não foi encontrado nenhum XML para este CFe SAT.");
                }
            }
        }).finally(() => setDownloadingXml(false));
    }

    const handleDownloadXmlBatch = (ids: number[]) => {
        if (!ids || !ids.length) {
            toast.warning('É necessário selecionar um CFe SAT para realizar o download do XML.');
            return;
        }

        listingApi.downloadXmlBatch(ids).then(res => {
            if (!res || !res.data || res.data.size === 0 || res.status !== 200) {
                toast.info("Não foi encontrado nenhum XML válido para os documentos selecionados.");
            } else {
                fileDownload(res.data, res.headers.filename)
            }
        });
    }

    const handleDownloadExtratoBatch = (ids: number[]) => {
        if (!ids || !ids.length) {
            toast.warning('É necessário selecionar um CFe SAT para realizar o download do extrato.');
            return;
        }

        listingApi.downloadExtratoBatch(ids).then(res => {
            if (!res || !res.data || res.data.size === 0 || res.status !== 200) {
                toast.info("Não foi possível gerar nenhum extrato para os CFe SAT selecionados.");
            } else {
                fileDownload(res.data, res.headers.filename)
            }
        });
    }

    const handleDownloadExtratoMerged = (ids: number[]) => {
        if (!ids || !ids.length) {
            toast.warning('É necessário selecionar um CFe SAT para realizar a impressão do extrato.');
            return;
        }

        listingApi.downloadExtratoMerged(ids).then(res => {
            if (!res || !res.data || res.data.size === 0 || res.status !== 200) {
                toast.info("Não foi possível gerar nenhum extrato para os CFe SAT selecionados.");
            } else {
                handlePrint(res?.data);
            }
        });
    }

    // ----------------------------
    // RETURN
    // ----------------------------
    return <PageFrame><StyledFrame>
        <section className="body">
            <div style={{ width: '100%', display: "flex", marginBottom: '12px' }}>
                <Filter model={filters} onFiltersChange={(updatedFilter: SATFilter) => setFiltersAndResetPageNum(updatedFilter)} reset={() => setFiltersAndResetPageNum(new SATFilter())} />
                <button
                    className="customize-columns-toggle"
                    onClick={() => setPageAndSort({ ...pageAndSort, page: 0 })}
                    style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
                    <i className="bi bi-arrow-clockwise" />
                </button>

                <button disabled={exportingBills} className="icon-button action-button centered" style={{ marginLeft: '20px', width: '188px' }} onClick={() => handleExportNFCes()}>
                    {exportingBills ? (
                        <CircularProgress size={14} style={{ 'color': '#FFFFFF' }} />
                    ) : (
                        <>
                            <i className="bi bi-file-earmark-spreadsheet"></i>
                            <span>Exportar notas</span>
                        </>
                    )}
                </button>
            </div>
            <div style={{ display: 'flex', marginLeft: '6px', alignItems: 'center' }}>
                <span><b style={{ marginLeft: '4px', marginRight: '4px' }}>{totalAmountSATs}</b> notas encontradas.</span>
                <div style={{ height: '25px', width: '1px', backgroundColor: 'var(--grey-light)', margin: '0 8px', opacity: '.5' }}></div>
                <span><b style={{ marginLeft: '4px', marginRight: '4px' }}>{selectedSATs.length}</b> notas selecionadas.</span>
                {customizeColumnsBtn}
            </div>
            <Table data={data}
                columns={columns}
                orderInfo={pageAndSort.sort}
                onSortByColumn={(col: Column) => handleSortByColumn(col)}
                actionsConfig={{ actions: actions, minWidth: 60 }}
                disableSelection={false}
                onSelectionChange={(sats: any) => { setSelectedSATs(sats.filter((nf: any) => nf.isSelected)) }}
                handleFetchMore={() => setPageAndSort({ ...pageAndSort, page: (pageAndSort.page + 1) })}
                hasMore={hasMore}
                batchActions={[
                    {
                        label: 'Baixar XML',
                        actionCallback: handleDownloadXmlBatch,
                        isDisabled: selectedSATs.length === 0
                    },
                    {
                        label: 'Baixar Extrato',
                        actionCallback: handleDownloadExtratoBatch,
                        isDisabled: selectedSATs.length === 0
                    },
                    {
                        label: 'Imprimir Extrato',
                        actionCallback: handleDownloadExtratoMerged,
                        isDisabled: selectedSATs.length === 0
                    }
                ]}
            />
        </section>

        {/* DIALOG - DOWNLOAD ARQUIVOS SAT */}
        {
            !!activeSAT && !!downloadOptionsOpen && <ModalFrame>
                <Modal
                    headerTitle="Baixar arquivos"
                    closeOnClickAway={true}
                    handleClose={() => setDownloadOptionsOpen(false)}
                    loading={loadingDownload}
                >
                    <span style={{ width: '50%' }}>Selecione a opção desejada:</span>

                    <div style={{ width: '100%', display: 'flex', marginTop: '14px', justifyContent: 'space-around' }}>
                        <button disabled={downloadingXml} className="icon-button centered" style={{ marginRight: '12px' }}
                            onClick={() => handleDownloadXml(activeSAT)}
                        >
                            {downloadingXml ? (<CircularProgress size={14} style={{ 'color': '#19ea81' }} />) : 'Baixar XML'}
                        </button>
                        <button
                            className="icon-button centered"
                            onClick={() => handleVisualizarExtratoClick(activeSAT)}
                        >
                            Visualizar Extrato
                        </button>
                    </div>
                </Modal>
            </ModalFrame>
        }

        {/* DIALOG - VISUALIZAR EXTRATO */}

        {visualizarExtratoOpen && (
            <ModalFrame>
                <Modal
                    headerTitle="Visualizar Extrato"
                    closeOnClickAway={true}
                    handleClose={() => {
                        setVisualizarExtratoOpen(false);
                        setPdf(undefined);
                        setPdfFilename(undefined);
                    }}
                    headerActions={[
                        {
                            icon: (<i title="Imprimir" className="bi bi-printer" style={{ fontSize: '1rem' }} />),
                            actionCallback: () => handlePrint(pdf)
                        },
                        {
                            icon: (<i title="Download" className="bi bi-download" style={{ fontSize: '1rem' }} />),
                            actionCallback: () => fileDownload(pdf, pdfFilename)
                        }
                    ]}
                    loading={false}>
                    <PDFViewer pdf={pdf} />
                </Modal>
            </ModalFrame>
        )}

        {/* DIALOG - HISTÓRICO SAT */}
        {
            satHistoryDialogOpen && <ModalFrame>
                <Modal
                    headerTitle="Histórico da SAT"
                    closeOnClickAway={false}
                    handleClose={() => setSatHistoryDialogOpen(false)}
                    loading={loadingHistory}
                >
                    <div style={{ display: 'table-table', border: `1px solid ${'var(--grey-light)'}`, borderRadius: '10px' }}>
                        <div style={{ display: 'table-row', fontWeight: 'bold', textAlign: "center" }}>
                            <div style={{ width: '150px', display: 'table-cell', padding: '10px' }}>Data</div>
                            <div style={{ display: 'table-cell' }}>Status</div>
                            <div style={{ minWidth: '100px', display: 'table-cell' }}>Usuário</div>
                        </div>
                        <div style={{ display: 'table-row' }}>
                            <hr className="line-separator" />
                            <hr className="line-separator" />
                            <hr className="line-separator" />
                        </div>
                        {
                            !!activeSAT && !!activeSAT.history && activeSAT.history.map((histItem, index) => <div key={index} style={{ width: '100%', display: 'table-row', padding: '5px' }} >
                                <div className="div-table-cell" style={{ width: '150px' }}>
                                    {histItem.createdAt}
                                </div>
                                <div className="div-table-cell">
                                    <div className="hist-item-frame">
                                        <div>
                                            <Tooltip title={histItem.status}
                                                placement='top' >
                                                <div className="status-indicator" />
                                            </Tooltip>
                                            {
                                                (!!activeSAT.history && index < activeSAT.history.length - 1) ?
                                                    <p className="status-link-bar" ></p>
                                                    : ''
                                            }
                                        </div>
                                        <div style={{ paddingLeft: '15px' }}>
                                            <span style={{
                                                color: `${(index === 0 && histItem.status) ? 'var(--color-success)' : ''}`,
                                                fontSize: '100%',
                                                fontWeight: `${(index === 0 && histItem.status) ? 'bold' : ''}`
                                            }}>{histItem.message}</span>
                                            {histItem.details && !!histItem.details.length && (<HistoryDetails items={histItem.details} />)}
                                        </div>
                                    </div>
                                </div>
                                <div className="div-table-cell" style={{ paddingRight: '10px', textAlign: 'center' }}>
                                    {histItem.username}
                                </div>
                            </div>)
                        }
                    </div>
                </Modal>
            </ModalFrame>
        }

    </StyledFrame></PageFrame>;
}

export default ListPage;